import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RESPONSE, EitUrl } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-addcorporate',
  templateUrl: './addcorporate.component.html',
  styleUrls: ['./addcorporate.component.scss']
})
export class AddcorporateComponent implements OnInit {

  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  associazioni : Array<any> = [];
  searchForm: FormGroup;
  addForm: FormGroup;
  info_company: any = {}

  constructor(private modalService: NgbModal, private api: ApiService, private toastService: ToastrService) { 
    this.searchForm = new FormGroup({
      LIKE: new FormControl(""),
      PAGE: new FormControl(0)
    })

    this.addForm = new FormGroup({
      ID: new FormControl(0),
      CODE: new FormControl ("", Validators.required)
    })
  }

  ngOnInit(): void {
  }

  @Input() init(){
    this.fill()
  }

  dismiss(status?: string){
    this.onDismiss.emit({status: (status ? status : RESPONSE.JPS_ERROR)})
    this.modalService.dismissAll()
  }

  applyFilter(){
    this.searchForm.patchValue({PAGE: 0});
    this.associazioni = []
    this.fill();
  }

  fill(){
    this.api.postj_T("user/settings/getcompanies", this.searchForm.value).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.associazioni = this.associazioni.concat(res[RESPONSE.JP_RESULT])
      }
    });
  }

  next(){
    this.searchForm.patchValue({PAGE: this.searchForm.controls.PAGE.value + 1});
    this.fill()
  }

  getPath(partner: any){
    return EitUrl.ASSETS_URL + partner.media?.filename + (partner.media_id && partner.media?.path.length > 0 ? '.' + partner.media?.ext : "")
  }

  removeJoin(id_company: number){
    this.api.postj_T("user/settings/removecompany", {ID: id_company}, "").subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.applyFilter();
      }
    })
  }

  select(record: any){
    console.log('select')
    if(!this.info_company || this.info_company.id != record.id_company){
      this.api.postj_T("user/settings/infocompany", {ID: record.id_company}, "").subscribe(res => {
        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
          this.info_company = res[RESPONSE.JP_RESULT];
          this.info_company.joined = record.joined;
          this.addForm.patchValue({ID: this.info_company.id, CODE: ""})
        }
      })
    }
  }

  add(){
    if(this.addForm.valid){
      this.api.postj_T("user/settings/addcompany", this.addForm.value).subscribe(res => {
        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
          this.dismiss(RESPONSE.JPS_OK)
        }
        else this.toastService.error("Codice di affiliazione errato!")
      });
    }
    else this.toastService.error("Inserisci il codice di affiliazione!")
  }
}
