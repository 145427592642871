<div *ngIf="!loader && cartData && cartData.order">
    <div class="product-form-box checkout-details p-4 text-center cart-box" style="max-height: 100vh !important">
        <div class="text-center m-0 box-title">
            <h4 class="m-0 text-uppercase eit-text-color-light">Il tuo ordine da Asporto</h4>
        </div>
        <a class="btn btn-outline my-3 text-center" (click)="opendatetimemodal()">
            <span *ngIf="cartData.order.tipooraconsegna==0 && cartData.order.order_date != -2">Ritiro previsto per le
                {{cartData.order.order_date}}</span>
            <span *ngIf="cartData.order.tipooraconsegna==0 && cartData.order.tipomodoconsegna == 1"> del
                {{cartData.order.realbookdate}}</span>
            <span *ngIf="cartData.order.tipooraconsegna==0 && cartData.order.order_date == -2">Non disponibile
                ora</span>
            <span *ngIf="cartData.order.tipooraconsegna==1 && cartData.order.fasciaoraria != -2">Ritiro previsto nella
                fascia {{cartData.order.fasciaoraria}}</span>
            <span *ngIf="cartData.order.tipooraconsegna==1 && cartData.order.tipomodoconsegna == 1"> del
                {{cartData.order.realbookdate}}</span>
            <span *ngIf="cartData.order.tipooraconsegna==1 && cartData.order.order_date == -2">Non disponibile
                ora</span>
        </a>

        <div *ngIf="cartData.order.items && cartData.order.items.length == 0" class="text-center m-4 p-4">
            <h6 class="eit-text-color-light">Il tuo carrello &egrave; vuoto</h6>
        </div>
        <div style="max-height: 50vh !important; overflow: auto; display: flex; flex-direction: column;">
            <ng-container *ngFor="let elCartArray of (cartData.order.items | array)">

                <ng-container *ngFor="let elCart of elCartArray">

                    <div class="row media cart-product  mr-3 ml-3 justify-content-center">
                        <div class="col-1">
                            <button *ngIf="editable" type="button"
                                class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus"
                                (click)="removeToCartSingle(elCart)">
                                <!--<i class="ti-angle-right"></i>-->
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-dash-circle-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z" />
                                </svg>
                            </button>
                        </div>


                        <!--<img *ngIf="item.media_id > 0" class="img-fluid w-auto" [defaultImage]="'assets/images/product/placeholder.jpg'"
                            [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small" alt="">-->

                        <div class="col-5 media-body align-self-center">
                            <h6 class="text-start eit-text-color-light">{{ elCart.name }}</h6>

                            <p class="text-start eit-text-color-light"
                                *ngIf="elCart.details && elCart.details.length==0 && elCart.extradetails && elCart.extradetails.length==0 && elCart.subitems && elCart.subitems.length==0">
                                {{ elCart.descr }}
                            </p>
                            <p class="text-start eit-text-color-light" *ngFor="let detail of elCart.details">
                                + {{ detail.name }}
                            </p>
                            <p class="text-start eit-text-color-light" *ngFor="let extradetail of elCart.extradetails">
                                + {{ extradetail.name }}
                            </p>
                            <ng-container *ngFor="let subitem of elCart.subitems">
                                <p class="text-start eit-text-color-light" *ngIf="subitem.name">+ {{ subitem.name }}</p>
                                <ng-container *ngIf="!subitem.name">
                                    <p class="text-start eit-text-color-light" *ngFor="let subit of subitem">
                                        + {{ subit.name }}
                                    </p>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col-3">
                            <h6 class="align-self-top cart-price text-end eit-text-color-light">{{ elCart.qty }} x {{
                                elCart.endprice }} &euro;
                            </h6>
                        </div>

                        <div class="col-2">
                            <button *ngIf="editable" type="button"
                                class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus"
                                (click)="addToCartSingle(elCart)">
                                <!--<i class="ti-angle-right"></i>-->
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                                </svg>
                            </button>
                        </div>

                    </div>

                </ng-container>
            </ng-container>
        </div>
       <div class="border-product mr-3 ml-3"></div>
        <div class="d-flex justify-content-between media mr-3 ml-3" *ngIf="cartData.order.euro_coupon>0.01">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Coupon</h6>
            </div>
            <h6 class="align-self-top text-end product-title">-{{(cartData.order.euro_coupon).toFixed(2)}} &euro;</h6>
        </div>

        <div class="d-flex justify-content-between media mr-3 ml-3" *ngIf="cartData.order.euro_promo==0 && cartData.order.messaggiopromo != null">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">{{cartData.order.messaggiopromo}}</h6>
            </div>
        </div>

        <div class="d-flex justify-content-between media mr-3 ml-3" *ngIf="cartData.order.euro_promo>0.01">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Promo</h6>
            </div>
            <h6 class="align-self-top text-end product-title">-{{(cartData.order.euro_promo).toFixed(2)}} &euro;</h6>
        </div>

        <div class="d-flex justify-content-between media mr-3 ml-3" *ngIf="cartData.order.credit>0.01">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Credito</h6>
            </div>
            <h6 class="align-self-top text-end product-title">-{{(cartData.order.credit).toFixed(2)}} &euro;</h6>
        </div>

        <div class="d-flex justify-content-between media mr-3 ml-3"
            *ngIf="cartData.order.euro_extradelivery>0.001 && cartData.order.type_order == typeOrder.REST_DOMICILIO">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Sovrapprezzo  <img class="img-fluid w-auto c-pointer" style="max-height: 18px;" src="https://assets.eatintime.it/imgs/info-small.png" alt="" (click)="extrainfoclick()"></h6>
            </div>
<!--
            <button type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                </svg>
            </button>
-->
            <h6 class="align-self-top text-end product-title">{{(cartData.order.euro_extradelivery).toFixed(2)}} &euro;</h6>
        </div>

        <div class="d-flex justify-content-between media mr-3 ml-3" *ngIf="cartData.order.type_order == typeOrder.REST_DOMICILIO">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Consegna</h6>
            </div>
            <h6 class="align-self-top text-end product-title">{{(cartData.order.euro_delivery).toFixed(2)}} &euro;</h6>
        </div>

        <div class="d-flex justify-content-between media mr-3 ml-3" *ngIf="cartData.order.type_order == typeOrder.REST_DOMICILIO">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Totale</h6>
            </div>

            <h6 class="align-self-top text-end product-title">
                {{cartData.order.euro_total.toFixed(2)}} &euro;</h6>

        </div>

        <div class="d-flex justify-content-between media mr-3 ml-3" *ngIf="cartData.order.type_order == typeOrder.REST_ASPORTO">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Totale</h6>
            </div>

            <h6 class="align-self-top text-end product-title">{{(cartData.order.euro_total).toFixed(2)}} &euro;</h6>

        </div>

        <a class="btn btn-solid btn-outline text-center" *ngIf="cartData != null && cartData.order.euro_total > 0"
            [ngClass]="{'disabledLink': cartData.order.items.length == 0}" (click)="goToCheckout()">{{goLabel}}</a>

    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>