<div class="row sticky d-flex flex-row flex-nowrap" [class.fixed]="stick && sticky" *ngIf="list && list.categories">
  <div class="col-sm-12 col-md-8 no-gutters align-items-center row-menu-cat">
    <ngx-horizontal-scroll-menu [items]="list.categories" ></ngx-horizontal-scroll-menu>
  </div>
 
  <div class="col-md-4 d-sm-none d-md-block" style="background: transparent; height: 0px;">    
    
    <app-calendar-box [editable]="true" [loader]="false">
    </app-calendar-box>
    <br>
    <app-cartbook-box [editable]="true" [loader]="false">
    </app-cartbook-box>
    
  </div>
</div>

<div class="row">
  <div class="col-lg-8" *ngIf="list && list.categories">
    <div class="product-right product-description-box" *ngFor="let cat of list.categories">
      <h2 id="{{cat.id}}" class="eit-act-cat-title text-center">{{cat.name}}</h2>
      <p class="eit-act-cat-descr text-center">{{cat.descr}}</p>
      <ng-container *ngFor="let item of cat.items">

        <div class="media border-product" *ngIf="item.attivo==true && null == item.varianti" (click)="addToCart(item)">

          <img *ngIf="item.media_id > 0" class="img-fluid w-auto"
            [defaultImage]="'assets/images/product/placeholder.jpg'"
            [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small" alt="">

          <div class="media-body align-self-center">

            <h6 class="product-title">{{ item.name }}</h6>

            <p> <small>{{ shortDescr(item) }}</small>{{ longDescr(item) }}{{ ingredients(item) }}</p>
            <p>
              {{ item.price }} &euro;
            </p>
          </div>
          <button type="button" class="btn quantity-right-plus align-self-center btn-menu-cart" data-type="plus">
            <!--<i class="ti-angle-right"></i>-->
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
            </svg>
          </button>
        </div>



        <ng-container *ngIf="
            item.attivo==true && 
            cartData != null &&
            cartData.order != null &&
            cartData.order.items != null &&
            cartData.order.items[item.id] != null
          ">
          <div class="media border-product menu-cart-item" *ngFor="let elCart of cartData.order.items[item.id]">
            <button type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus" (click)="removeToCartSingle(elCart)">
              <!--<i class="ti-angle-right"></i>-->
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-dash-circle-fill" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z" />
              </svg>
            </button>

            <!--<img *ngIf="item.media_id > 0" class="img-fluid w-auto" [defaultImage]="'assets/images/product/placeholder.jpg'"
          [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small" alt="">-->

            <div class="media-body align-self-top">
              <h6>{{ elCart.name }}</h6>

              <p
                *ngIf="elCart.details && elCart.details.length==0 && elCart.extradetails && elCart.extradetails.length==0 && elCart.subitems && elCart.subitems.length==0">
                {{ elCart.descr  }}
              </p>
              <p item-title *ngFor="let detail of elCart.details">
                + {{ detail.name }}
              </p>
              <p item-title *ngFor="let extradetail of elCart.extradetails">
                + {{ extradetail.name }}
              </p>
              <ng-container *ngFor="let subitem of elCart.subitems">
                <p item-title *ngIf="subitem.name">+ {{ subitem.name }}</p>
                <ng-container *ngIf="!subitem.name">
                  <p item-title *ngFor="let subit of subitem">
                    + {{ subit.name }}
                  </p>
                </ng-container>
              </ng-container>


            </div>
            <h6 class="align-self-top">{{ elCart.qty }} x {{ elCart.endprice }} &euro;</h6>

            <button type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus" (click)="addToCartSingle(elCart)">
              <!--<i class="ti-angle-right"></i>-->
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
              </svg>
            </button>

          </div>
        </ng-container>


        <ng-container *ngIf="item.attivo==true && null != item.varianti">
          <ng-container *ngFor="let variante of item.varianti">

            <div class="media border-product" (click)="addToCart(item, variante)">

              <img *ngIf="item.media_id > 0" class="img-fluid w-auto"
                [defaultImage]="'assets/images/product/placeholder.jpg'"
                [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small" alt="">

              <div class="media-body align-self-center">

                <h6 class="product-title">{{ item.name }} - {{ variante.name }}</h6>

                <p> <small>{{ shortDescr(item) }}</small>{{ longDescr(item) }}{{ ingredients(item) }}</p>
                <h4>
                  {{ variante.price }} &euro;
                </h4>
              </div>
              <button type="button" class="btn quantity-right-plus align-self-center btn-menu-cart" data-type="plus">
                <!--<i class="ti-angle-right"></i>-->
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                </svg>
              </button>
            </div>

            <ng-container *ngIf="
            item.attivo==true && 
            cartData != null &&
            cartData.order != null &&
            cartData.order.items != null &&
            cartData.order.items[variante.id] != null
          ">
              <div class="media border-product"
                *ngFor="let elCart of cartData.order.items[variante.id]">

                <button type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus" (click)="removeToCartSingle(elCart)">
                  <!--<i class="ti-angle-right"></i>-->
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-dash-circle-fill" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z" />
                  </svg>
                </button>

                <!--<img *ngIf="item.media_id > 0" class="img-fluid w-auto"
                  [defaultImage]="'assets/images/product/placeholder.jpg'"
                  [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small" alt="">-->

                  <div class="media-body align-self-top">
                    <h6>{{ elCart.name }}</h6>
      
                    <p
                      *ngIf="elCart.details && elCart.details.length==0 && elCart.extradetails && elCart.extradetails.length==0 && elCart.subitems && elCart.subitems.length==0">
                      {{ elCart.descr  }}
                    </p>
                    <p item-title *ngFor="let detail of elCart.details">
                      + {{ detail.name }}
                    </p>
                    <p item-title *ngFor="let extradetail of elCart.extradetails">
                      + {{ extradetail.name }}
                    </p>
                    <ng-container *ngFor="let subitem of elCart.subitems">
                      <p item-title *ngIf="subitem.name">+ {{ subitem.name }}</p>
                      <ng-container *ngIf="!subitem.name">
                        <p item-title *ngFor="let subit of subitem">
                          + {{ subit.name }}
                        </p>
                      </ng-container>
                    </ng-container>
      
      
                  </div>
                  <h6 class="align-self-top">{{ elCart.qty }} x {{ elCart.endprice }} &euro;</h6>

                  <button type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus" (click)="addToCartSingle(elCart)">
                    <!--<i class="ti-angle-right"></i>-->
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                    </svg>
                  </button>

                </div>
              
            </ng-container>
          </ng-container>
        </ng-container>


      </ng-container>
    </div>
  </div>
</div>
<app-custom-modal #customModal></app-custom-modal>