<app-header-one [class]="'header-gym'"></app-header-one>
<section>
    <div class="title">
        <h1 class="text-center no-margin margin-top text-size-md text-color-accent-fit
          text-uppercase etika-title fw-normal mb-5">GAINERS</h1>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-xl-4 gainer-col" *ngFor="let item of _gainer">
                <div class="card" (click)="onGoToGainer(item.id)">
                    <div class="text-center card-header">
                      <img class="frame" src="assets/images/cornice-news.png" [ngStyle]="{'background-image': 'url(' + getHeader(item) + ')'}">
                      <img class="bg-img" *ngIf="item.media" [src]="item.media.realpath">
                      <h4 class="text-color-accent-fit">
                        <span>GAINER</span>
                      </h4>
                    </div>
                    <div class="card-content text-center">
                      <button class="btn btn-solid my-3">Scopri di pi&uacute;</button>
                      <!-- <p class="text-left">{{item.name}}</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-two [class]="'bg-img-gym bg-size'"></app-footer-two>