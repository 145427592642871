import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import { RESPONSE } from '../../../shared/services/env.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppService } from '../../../shared/services/app.service';

@Component({
  selector: 'app-register-box',
  templateUrl: './register-box.component.html',
  styleUrls: ['./register-box.component.scss']
})
export class RegisterBoxComponent {
  regForm: FormGroup;
  appData: any = null;

  constructor(private api: ApiService, private toastService: ToastrService, private router: Router, private appService: AppService) { 
    this.appService.appObs.subscribe(x => { 
      this.appData = x;
    })
    this.regForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required, Validators.minLength(6)]),
      cpassword: new FormControl("", [Validators.required, Validators.minLength(6)]),
      terms: new FormControl(false, [Validators.required, Validators.requiredTrue]),
      privacy: new FormControl(false, [Validators.required, Validators.requiredTrue]),
      newsletter: new FormControl(false),
      profiling: new FormControl(false),
    })
  }

  ngOnInit(): void {
    console.log("reg")
  }

  register(){
    this.regForm.patchValue({cpassword: this.regForm.controls['password'].value})
    if(!this.appData || !this.appData.link_terms || !this.appData.link_terms.length) this.regForm.controls['terms'].setValue(true);
    if(!this.appData || !this.appData.link_privacy || !this.appData.link_privacy.length) this.regForm.controls['privacy'].setValue(true);
    if(this.validate()){
      this.api.register(this.regForm.value, "").subscribe(res => {
        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {          
          this.router.navigate(['pages/registration-complete'])
        }
        else{
          this.toastService.error(this.msgError(res[RESPONSE.JP_MSG]), "Attenzione! Controlla i dati inseriti!")          
        }
      }, err =>{
        this.toastService.error(this.msgError(err), "Attenzione! Controlla i dati inseriti!") 
      })
    }
  }

  msgError (err: any){
   return "<ul>" + (err.email != null ? "<li>" + err.email[0] + "</li>" : "") + (err.name != null ? "<li>" + err.name[0] + "</li>" : "") +
                   (err.lastname != null ? "<li>" + err.lastname[0] + "</li>" : "") + (err.phone != null ? "<li>" + err.phone[0] + "</li>" : "") +  
                   (err.password != null ? "<li>" + err.password[0] + "</li>" : "") + "</ul>";                 
  }

  validate(){
    if(!this.regForm.valid){
      let rErr = "Mancano i seguenti campi: "
      let err1 = (!this.regForm.controls['name'].valid && this.regForm.controls['name'].getError('required')) || 
                 (!this.regForm.controls['lastname'].valid && this.regForm.controls['lastname'].getError('required')) ||
                 (!this.regForm.controls['phone'].valid && this.regForm.controls['phone'].getError('required')) ||
                 (!this.regForm.controls['email'].valid && this.regForm.controls['email'].getError('required')) ||
                 (!this.regForm.controls['password'].valid && this.regForm.controls['password'].getError('required')) ||
                 (!this.regForm.controls['terms'].valid) || (!this.regForm.controls['privacy'].valid);

      if(!this.regForm.controls['name'].valid && this.regForm.controls['name'].getError('required')) rErr += "<br> Nome";

      if(!this.regForm.controls['lastname'].valid && this.regForm.controls['lastname'].getError('required')) rErr += "<br> Cognome";

      if(!this.regForm.controls['phone'].valid && this.regForm.controls['phone'].getError('required')) rErr += "<br> Telefono";

      if(!this.regForm.controls['email'].valid && this.regForm.controls['email'].getError('required')) rErr += "<br> Email"
      else if(!this.regForm.controls['email'].valid && this.regForm.controls['email'].getError('email')) this.toastService.error("Email non valida!")

      if(!this.regForm.controls['password'].valid && this.regForm.controls['password'].getError('required')) rErr += "<br> Password"
      else if(!this.regForm.controls['password'].valid && this.regForm.controls['password'].getError('minlength')) this.toastService.error("Password non valida!")

      if(!this.regForm.controls['terms'].valid && this.regForm.controls['terms'].getError('required')) rErr += "<br> Devi leggere ed accettare le Condizioni generali di uso";
      else if(!this.regForm.controls['terms'].valid && this.regForm.controls['terms'].getError('requiredtrue')) rErr += "<br> Devi leggere ed accettare le Condizioni generali di uso";

      if(!this.regForm.controls['privacy'].valid && this.regForm.controls['privacy'].getError('required')) rErr += "<br> Devi leggere ed accettare l'Informativa privacy";
      else if(!this.regForm.controls['privacy'].valid && this.regForm.controls['privacy'].getError('requiredtrue')) rErr += "<br> Devi leggere ed accettare l'Informativa privacy";

      if (err1) this.toastService.error(rErr)
      return false;
    }
    return true; 
  }


  login()
  {
    this.router.navigate(['login'])    
  }
}
