
<section class="login-page text-center section-b-space pt-0 margin-header">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                

                <div class="card mt-5 login-card">
                    <div class="p-0 card-body text-center">
                        <!--<button class="btn btn-solid btn-facebook mb-3" (click)="fbLogin()">Entra con Facebook</button>
                        <p class="eit-text-color-violet">oppure</p>-->
                        <form [formGroup]="loginForm" class="login-form" (ngSubmit)="login()">
                            <div class="form-group  my-5">
                                <input type="text" class="form-control" id="email" placeholder="Email" required
                                    formControlName="email" name="email">
                            </div>
                            <div class="form-group my-5">
                                <input type="password" class="form-control" id="review" placeholder="Password" required
                                    formControlName="password" name="password">
                            </div>
                            <p class="mx-1 my-5">Hai dimenticato la password? <a (click)="recovery()">RECUPELALA</a></p>
                            <button class="btn btn-solid btn-block px-5 py-3" type="submit">Accedi</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>