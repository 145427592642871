import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppInfo, EitUrl, RESPONSE } from './env.service';
import { AuthService } from './auth.service';

import * as momentTz from  'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  token: any = null;

  constructor(private http: HttpClient, private auth: AuthService) {
    this.token = null;

    this.auth.currentUser.subscribe(state => {
      this.token = null;
      if (state) {
        this.token = state.access_token
      }
    });
  }


  // HttpClient API get() method => Fetch employees list
  post(url: string, body: any, reqOpts?: any): Observable<any> {
    if(body === "") body = {};
    body.idApp = AppInfo.APP_ID;
    return this.http.post<any>(url, body, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  postj(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    if(body === "") body = {};
    body.idApp = AppInfo.APP_ID;
    var url = EitUrl.apiUrl + '/' + endpoint;
    return this.http.post<any>(url, body, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  /* postj_A(endpoint: string, body: any, reqOpts?: any): Observable<any> {
     var url = EitUrl.apiUrl + '/' + endpoint;
 
 
     return this.http_a.post<any>(url, body, reqOpts)
       .pipe(
         map(data => data)
       )
   }
 */
  postj_(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    var url = EitUrl.apiUrl + '/' + endpoint;
    var headers = { 'Content-Type': 'application/json'};
    if(body === "") body = {};
    body.idApp = AppInfo.APP_ID;
    return this.http.post<any>(url, body, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  postj_T(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    var url = EitUrl.apiUrl + '/' + endpoint;

    var headers = { 'Content-Type': 'application/json', 'Authorization': '' };
    var b = '';

    if (this.token) {
      var bearer: string = 'Bearer ' + this.token;
      headers.Authorization = bearer;
      b = 'access-token=' + this.token;
      url = url + '?' + b;
    }
    if(body === "") body = {};
    body.idApp = AppInfo.APP_ID;

    return this.http.post<any>(url, body, reqOpts)
      .pipe(
        map(data => data)
      )
  }


  // HttpClient API get() method => Fetch employees list
  request(credentials: any, reqOpts?: any): Observable<any> {
    if(credentials === "") credentials = {};
    credentials.idApp = AppInfo.APP_ID;
    return this.http.post<any>(EitUrl.apiUrl + '/user/recovery/request', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  // HttpClient API get() method => Fetch employees list
  register(credentials: any, reqOpts?: any): Observable<any> {
    if(credentials === "") credentials = {};
    credentials.idApp = AppInfo.APP_ID;
    return this.http.post<any>(EitUrl.apiUrl + '/user/registration/register', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  // HttpClient API get() method => Fetch employees list
  reset(credentials: any, reqOpts?: any): Observable<any> {
    if(credentials === "") credentials = {};
    credentials.idApp = AppInfo.APP_ID;
    return this.http.post<any>(EitUrl.apiUrl + '/user/recovery/reset', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  // HttpClient API get() method => Fetch employees list
  checkCode(credentials: any, reqOpts?: any): Observable<any> {
    if(credentials === "") credentials = {};
    credentials.idApp = AppInfo.APP_ID;
    return this.http.post<any>(EitUrl.apiUrl + '/user/recovery/checkcode', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  getCookiePolicy(){
    return this.http.get<any>("https://www.iubenda.com/api/privacy-policy/15186040/cookie-policy", {})
      .pipe(
        map(data => data)
      )

  }

  
  getEcInfo () {
    return this.http.post<any>(EitUrl.apiUrl + '/ecommerce/getinfo', "", null).pipe(map(data => data))    
  }

  
  // HttpClient API get() method => Fetch employees list
  getInfoApp(reqOpts?: any): Observable<any> { 
    let credentials = {idApp: AppInfo.APP_ID};    
    return this.http.post<any>(EitUrl.apiUrl + '/apps/getinfoapp', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  loadPartner(cat_id, page, like) {
    //this.loadingService.show("Caricamento in corso...");
    var data: any = {};
    data.ID_CAT = cat_id;
    data.PAGE = page;
    data.LIKE = like;

    return new Promise((resolve, reject) => {
      this.postj_T("restaurant/list", data, "").subscribe(data => {
        if (
          data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT] && data[RESPONSE.JP_RESULT].length
        ) {

          resolve(data[RESPONSE.JP_RESULT]);

        }
        else {
          resolve([]);
        }
        //this.loadingService.hide();
      }, (err) => {
        console.log(err);
        //this.loadingService.hide();
        reject(err);
      })
    });
  }

  
  loadJobs(cat_id, page, like) {
    //this.loadingService.show("Caricamento in corso...");
    var data: any = {};
    data.ID_CAT = cat_id;
    data.PAGE = page;
    data.LIKE = like;

    return new Promise((resolve, reject) => {
      this.postj_T("job/jobs", data, "").subscribe(data => {
        if (
          data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT] && data[RESPONSE.JP_RESULT].length
        ) {

          resolve(data[RESPONSE.JP_RESULT]);

        }
        else {
          resolve([]);
        }
        //this.loadingService.hide();
      }, (err) => {
        console.log(err);
        //this.loadingService.hide();
        reject(err);
      })
    });
  }

  loadNft(page: number, recType?: any) {
    return new Promise((resolve, reject) => {
      let params: any = {PAGE: page, PAGESIZE: 10}
      if(recType != undefined) params.RECORD = recType;
      this.postj_T("shop/listnft", params, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT] && data[RESPONSE.JP_RESULT].length) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  loadNews(/* page: number, recType?: any */) {
    return new Promise((resolve, reject) => {
      //let params: any = {PAGE: page, PAGESIZE: 10}
      //if(recType != undefined) params.RECORD = recType;
      this.postj_T("news/records", {/* params */}, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT] && data[RESPONSE.JP_RESULT].length) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  getNews(id: number, recType?: any) {
    return new Promise((resolve, reject) => {
      let params: any = {ID: id}
      if(recType != undefined) params.RECORD = recType;
      this.postj_T("news/record", params, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  loadJobshistory(page) {
    //this.loadingService.show("Caricamento in corso...");
    var data: any = {};
    data.PAGE = page;
  

    return new Promise((resolve, reject) => {
      this.postj_T("job/jobshistory", data, "").subscribe(data => {
        if (
          data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT] && data[RESPONSE.JP_RESULT].length
        ) {

          resolve(data[RESPONSE.JP_RESULT]);

        }
        else {
          resolve([]);
        }
        //this.loadingService.hide();
      }, (err) => {
        console.log(err);
        //this.loadingService.hide();
        reject(err);
      })
    });
  }

  loadNfthistory(page: number, recType?: any) {
    return new Promise((resolve, reject) => {
      let params: any = {PAGE: page, PAGESIZE: 10}
      this.postj_T("nft/nfthistory", params, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT] && data[RESPONSE.JP_RESULT].length) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  loadOrdershistory(page: number, recType?: any) {
    return new Promise((resolve, reject) => {
      let params: any = {PAGE: page, PAGESIZE: 10}
      this.postj_T("order/ordershistory", params, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT] && data[RESPONSE.JP_RESULT].length) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  getNft(id: number, recType?: any) {
    return new Promise((resolve, reject) => {
      let params: any = {id: id}
      if(recType != undefined) params.RECORD = recType;
      this.postj_T("nft/record", params, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  getProduct(id: number, recType?: any) {
    return new Promise((resolve, reject) => {
      let params: any = {id: id}
      if(recType != undefined) params.RECORD = recType;
      this.postj_T("shop/product", params, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  loadGainers(page: number, recType?: any) {
    return new Promise((resolve, reject) => {
      let params: any = {PAGE: page, PAGESIZE: 10}
      if(recType != undefined) params.RECORD = recType;
      this.postj_T("shop/listgainers", params, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT] && data[RESPONSE.JP_RESULT].length) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  getGainer(id: number, recType?: any) {
    return new Promise((resolve, reject) => {
      let params: any = {id: id}
      if(recType != undefined) params.RECORD = recType;
      this.postj_T("gainer/record", params, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  loadCategories() {
    return new Promise((resolve, reject) => {
      this.postj_T("shop/listcategories", {}, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT] && data[RESPONSE.JP_RESULT].length) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  loadProduct(cat_id, page, like) {
    //this.loadingService.show("Caricamento in corso...");
    var data: any = {};
    data.IDCAT = cat_id;
    data.PAGE = page;
    data.LIKE = like;

    return new Promise((resolve, reject) => {
      this.postj_T("shop/list", data, "").subscribe(data => {
        if (
          data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT] && data[RESPONSE.JP_RESULT].length
        ) {

          resolve(data[RESPONSE.JP_RESULT]);

        }
        else {
          resolve([]);
        }
        //this.loadingService.hide();
      }, (err) => {
        console.log(err);
        //this.loadingService.hide();
        reject(err);
      })
    });
  }

  updateJob(id, value, enddate) {
    //this.loadingService.show("Caricamento in corso...");
    var data: any = {};
    data.ID = id;
    data.VALUE = value;
    data.TO =  momentTz.tz(enddate,"Europe/Rome").format('YYYY-MM-DD HH:mm:ss');

    return new Promise((resolve, reject) => {
      this.postj("job/updatejob", data, "").subscribe(data => {
        if (
          data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]
        ) {

          resolve(data[RESPONSE.JP_RESULT]);

        }
        else {
          resolve([]);
        }
        //this.loadingService.hide();
      }, (err) => {
        console.log(err);
        //this.loadingService.hide();
        reject(err);
      })
    });
  }

  
  updateJobDistance( value, enddate) {
    //this.loadingService.show("Caricamento in corso...");
    var data: any = {};
    data.VALUE = value;
    data.TO =  momentTz.tz(enddate,"Europe/Rome").format('YYYY-MM-DD HH:mm:ss');

    return new Promise((resolve, reject) => {
      this.postj("job/updatejobdistance", data, "").subscribe(data => {
        if (
          data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]
        ) {

          resolve(data[RESPONSE.JP_RESULT]);

        }
        else {
          resolve([]);
        }
        //this.loadingService.hide();
      }, (err) => {
        console.log(err);
        //this.loadingService.hide();
        reject(err);
      })
    });
  }

  claim(id) {
    //this.loadingService.show("Caricamento in corso...");
    var data: any = {};
    data.ID = id;

    return new Promise((resolve, reject) => {
      this.postj("job/claim", data, "").subscribe(data => {
        if (
          data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK
        ) {

          resolve(data);

        }
        else {
          reject(0);
        }
        //this.loadingService.hide();
      }, (err) => {
        console.log(err);
        //this.loadingService.hide();
        reject(err);
      })
    });
  }
  
  getOrderId(id_order) {
    return new Promise((resolve, reject) => {
      let params: any = {id: id_order}
      this.postj("order/createrecord", params, "").subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]) {
          resolve(data[RESPONSE.JP_RESULT]);
        }
        else {
          resolve(null);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  updateInfo(credentials: any) {
    console.log(credentials);

    return new Promise((resolve, reject) => {
      this.postj_T('user/security/updateinfo', credentials).subscribe(data => {
        resolve(data);
        console.log(data);
      }, (err) => {

        reject('Controlla i dati inseriti!');
        console.log(err);

        ;
      });
    });
  }

}
