//import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
//import { Connectivity } from '../connectivity-service/connectivity-service';

export let JP_STATUS = "status";
export let JP_RESULT = "result";
export let JP_RURL = "url";
export let JP_MSG = "msg";
export let JP_HTML = "html";

export let JPS_OK = "ok";
export let JPS_ERROR = "error";
export let JPS_REDIRECT = "redirect";
export let JPS_VIEW = "view";

export let ANDROID_VERSION = "2.0.19";
export let ANDROID_BUILD = "33";

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCou3YOVgLwQ85XMCTw7JRO1vzJNag1WNY",
    authDomain: "driveit-154811.firebaseapp.com",
    databaseURL: "https://driveit-154811.firebaseio.com",
    projectId: "driveit-154811",
    storageBucket: "driveit-154811.appspot.com",
    messagingSenderId: "556866775414",
    appId: "1:556866775414:web:53218b1b159abcd2"
  }
 };

export enum TypeOrder {
  EIT_DOMICILIO = 0,
  REST_DOMICILIO = 1,
  EIT_ASPORTO = 2,
  REST_ASPORTO = 3,
  EIT_TAVOLO = 4,
	REST_TAVOLO = 5,
  NFT = 6,
	MARKET = 7
}

export enum TypePayment
{
	MONEY = 1,
	PAYPAL = 2,
	CARD = 3,
	TICKET = 4,
	EATPOINTS = 5,
	CREDIT = 6,
  SATISPAY=7,
  INLIRE=8,
  GIFTCARD=9

	/* ID Table */
}

export enum StatusOrder {
  UNDEF = -1,
  CREATED = 0,
  PAID = 1,
  CLOSED = 2,
	ERROR = 3,
	ACCEPTED = 4,
	REJECTED = 5,
	NO_RESPONSE = 6,
	ACCEPTED_NOTIFIED = 7,
	REJECTED_NOTIFIED = 8,
	NO_RESPONSE_NOTIFIED = 9,
	REFUND = 10,
	PREORDERED = 11,
	PREACCEPTED = 12,
  STANDBY = 13,	
}

export enum RestOrdersStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  CLOSED_DAY = 2,
  OPEN_DAY = 3
}

export enum RestTablesStatus {
  NON_ATTIVO = 0,
	ATTIVO = 1,
	SOSPESO = 2
}

export enum StatusRide {
  UNDEF = -1,
  CREATED = 0,
  SENT = 1,
  ACCEPTED = 2,
	COLLECTING = 3,
	COLLECTED = 4,
	DELIVERED = 5,
	INTERRUPTED = 6,
	CLOSED = 7
}

export enum EtTipoCustom {
  CHECKLIST = 1,
	MENU = 2,
	RADIOLIST = 3
}

export enum ET_BookStatus {
  CREATED = 0,
	CONFIRMED = 1,
	CANCELLED_BY_USER = 2,
	CANCELLED_BY_RESTAURANT = 3,
	MISSED_PRESENTATION = 4,
	ARRIVED = 5,
	BILL = 6,
	SETTLED = 7,
	FINISHED = 8
}

export enum ET_CreditTransStatus {
	CREATED = 0,
	PAID = 1,
	COMPLETED = 2,
	ERROR = 3,
	REFUNDED = 4
}

export enum ET_TipoConsegna {
	INGIORNATA = 0,
	PRENOTAZIONE = 1
}

export enum ET_OpzioniCoupon {
  NOTYPEOPTIONS = 0,
	ONLYCARDS = 1
}

export enum ET_TipoOraConsegna {
	ORARIO = 0,
	FASCIAORARIA = 1
}

export enum ET_RestaurantServiceTypeEnabled ///prima ET_ServiceType
{
	 UNDEF = - 1,
	 AWAY = 0,
	 DELIVERY = 1,
	 ALL = 2,
} 

export enum ProfileTabs {
	Account = 0,
	Recensioni = 1,
	Info = 2,
	Affiliazioni = 3,
	Prenotazioni = 4,
	Password = 5,
	Logout = 6,
	Messaggi = 7,
}

//Release


export enum Version {
  vrs = "A59",
  
}

export enum DB {
  LOCATION = "location",
  USER = "userData",
  SETTINGS = "settings",
  TOKEN = "token",
}

export enum TCombo {
  COMBO = 0,
  DATASOURCE = 1
}

export enum TModule {
  EatInTime = 1
}
export enum TEit {
    GENDER = 1,
    CITY = 2,
    INGREDIENT = 3,
    DELIVERY_TIME = 4,
    CUCINA = 5,
    RESTAURANT_STATUS = 6,
    ORDER_STATUS = 7,
    USER_STATUS = 8,
    PAY_METHODS = 9,
    ZIPCODE = 10,
    TOWN = 11,
    MENUITEM_TYPE = 12,
    CUSTOM_TYPE = 13,
    DAY_WEEK = 14,
    INGREDIENTS_CUSTOM = 15,
    COUPON_TYPE = 16,
    COUPON_STATUS = 17,
    COUPON_TYPE_CALC = 18,
    INVOICE_STATUS = 19,
    ACCOUNT_STATUS = 20,
    CUSTOMSDB = 21,
    CATEGORYMENU = 22,
    RESTAURANTS = 23,
    FEATURED_PROMO = 24,
    REFUNDS_PROMO = 25,
    CUSTOMDETAILSDB = 26,
    MENUITEMREST = 27,
    TYPE_OPERATIONS = 28,
    DELIVERY_TIME_MINUTES = 29,
    COUPON_TYPE_OPTIONS = 30,
    RESTAURANT_OPTIONS = 31,
    INVOICE_TYPEOPERATIONS = 32,
    INVOICE_OPERATIONS = 33,
    RIDE_STATUS = 34,
    CALL_STATUS = 35,
    ORDERS = 36,
    ORDERS_BY_REST = 37,
    USERS = 38,
    DRIVER_STATUS = 39,
    TIME_HOUR = 40,
    TIME_MINUTES = 41,
    ACCOUNT_DRIVER_STATUS = 42,
    STATUS_EVENT = 43,
    SHIFT_TYPE = 44,
    DELIVERY_TIME_SLOTS = 45,
    PAYMENTS_TYPE = 46,
    ORDER_TYPE = 47,
    PARTNERS = 48,
    REST_SERVICE_TYPE = 49,
    PROMO_VALIDITY = 50,
    USER_TYPE = 51,
    FRANCHISEE_OP = 52,
    FRANCHISEE_LIST = 53,
    TIPO_MEZZO = 54,
    ZIPCODE_BY_CITY = 58,
    RESTAURANTS_BY_CITY = 59,
    YEARS_DESC = 60,
    RIDES_AVAILABLE = 61,
    JOBS_TYPES = 62,
    ACTIVECITY = 63,
    SLAVE_STATUS = 64,
    //65...67
    STATUS_BOOKS = 68,
    PARTNERS_BY_CITY = 69,
    RESTAURANTS_BY_FRANCHISEE = 70,
    //71...72
    CREDITTRANS_STATUS = 73,
    CREDITTRANS_TYPE = 74,
    BOOKINGSFLAT_TYPE = 75,
    TABLESSERVICE_STATUS = 76,
    COMPANIES = 77,
    RESTAURANTS_BY_SUPPORT = 79,
    CUSTOM_TYPE_V2 = 80,
    REVIEWS_TIPOVISITA = 81,
    REVIEWS_STANDARDLIST = 82,
    REVIEWS_PREZZILOCALE = 83,
    PROMO_TYPE_CALC = 84,
    //85
    STATUS_BONIFICO= 86,
    TIPOVERSO_BONIFICO = 87,
    TIPOINTESTATARIO_BONIFICO = 88,
    INVOICES_LIST = 89,
    //90
    FAST_MESSAGES = 91,
    //92
    TIPOGESTIONALE= 93,
    TIPOSOGGETTO = 94,
    //95..96
    TYPESETTINGDETAILS = 97,
    TICKET_STATUS = 98,
    TICKET_TYPE = 99,
    DOCUMENT_TYPE = 100,
    TIPO_CONTRATTO = 101,
    MENUTEMPLATE_TYPE = 102,
    DRIVERINVOICES_LIST = 103,
    STATUSCANONI = 104,
    TIPOCANONI = 105,
    RECORD_STATUS = 106,
    MENUPAGE = 107,
    MENUCATEGORY = 108,
    MENUITEM = 109,
    STATUS_ZONEDETAIL = 120,
    MEDIA_TYPE = 121,
}

//Debug
//export enum EitUrl {

  //apiUrl = "https://apimobile19.eatintime.it/v2/apimobile/web/v1"

//}

/*
  Generated class for the ConfigAppProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class ConfigApp {

  public printer: any;

  public RESULT_OK = 1;
  public RESULT_CANCEL = 0;
  
  public JP_STATUS = "status";
  public JP_RESULT = "result";
  public JP_RURL = "url";
  public JP_MSG = "msg";
  public JP_HTML = "html";

  public JPS_OK = "ok";
  public JPS_ERROR = "error";
  public JPS_REDIRECT = "redirect";
  public JPS_VIEW = "view";

  public JPIO_READ = 0;
  public JPIO_WRITE = 1;

  public PREF_EMAIL = "prefEmail";
  public PREF_PW = "prefPassword";
  public PREF_PRINTER_BT = "prefBluetoothPrinter";
  public PREF_FCM = "fcm";
  public PREF_DEVICENAME = "devicename";
  public PREF_NOTREFUND = "notrefund";
  public PREF_SHOWCUSTOMER = "showcustomer";
  public PREF_CASSAINTERNA = "cassainterna";
  public PREF_HELPDESK = "helpdesk";
  public PREF_DELFIXED = "delfixed";
  public PREF_DELFIXEDSEL = "delfixedsel";
  public PREF_DELINCL = "delincl";

  //public PREF_CLIENTIDGEST = "clientidgest";
  //public PREF_CLIENTKEYGEST = "clientkeygest";

  /*public static String PROP_PRINTER_BT = "ETT_PRINTER_BT";
  public static String PROP_EMAIL = "ETT_EMAIL";
  public static String PROP_PW = "ETT_PW";*/

  public DRIVER_CALL_MIN = 30;
  public DRIVER_EXTIMATE_DEFAULT = 15;

  /**/
  componentForm: any = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    country: 'long_name',
    postal_code: 'short_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'short_name',
    administrative_area_level_3: 'short_name'
  };

  _models: any = new Array();
  _modelsCb: any = new Array();

  constructor(private api: ApiService) { }


  Combo = function (module, type) {
      var result = this._modelsCb["0:" + module + ":" + type];
      result = result == null ? new Array() : result;
      return result;
  }
  /*
   * type = controller:conn
   */
  LoadModels(typeArray, forced, async, functionSuccess)  {
      var result = new Array();
      var loadArray = new Array();
      if (async == null) {
          async = false;
      }
      if (forced != null && forced == true) {
          /*for (var type in typeArray) {
              var tipominuscolo = typeArray[type].toLowerCase();*/
          var index = 0;
          for (index = 0; index < typeArray.length; ++index) {
              var type = typeArray[index];
              var tipominuscolo = type.toLowerCase();
              var controller = tipominuscolo.charAt(0).toUpperCase() + tipominuscolo.slice(1);
              loadArray.push(controller);
          }
      }
      else {
          var index = 0;
          for (index = 0; index < typeArray.length; ++index) {
              var type = typeArray[index];
              // typeArray.forEach(function(type) {
              //var tipominuscolo = typeArray[type].toLowerCase();
              var tipominuscolo = type.toLowerCase();
              var controller = tipominuscolo.charAt(0).toUpperCase() + tipominuscolo.slice(1);
              if (this._models[controller] == null) {
                  loadArray.push(controller);
              }
              else {
                  result[controller] = this._models[controller];
              }
          }
      }
      // if (loadArray.length > 0) {
      var httpData: any = {};
      httpData.combo = loadArray;
      return this.api.postj_('utils/loadcomboj', httpData).pipe(map(data => {
        if (data[this.JP_STATUS] == this.JPS_OK && data[this.JP_RESULT]) {
          // var result  = new Array();
          for (var cb = 0; cb < data[this.JP_RESULT].length; cb++) {
            for (let key in data[this.JP_RESULT][cb]) {
              let value = data[this.JP_RESULT][cb][key];
              // Use `key` and `value`
              if (value) {
                result[key] = value;
                switch (cb) {
                  case TCombo.COMBO: this._modelsCb[key] = result[key]; break;
                  case TCombo.DATASOURCE: this._models[key] = result[key]; break;
                }
              }
            }
          }
          return data;
        }
      }));


      // }

      //return result;
  }

/*

  constructor(public connectivityService: Connectivity) {
    console.log('Hello ConfigAppProvider Provider');
  }

  isOnline()
  {
    return this.connectivityService.isOnline();
  }

  getAddressFromPlace(place)
  {
    var result = "";

    if(place)
    {
      place.description = "";
      if(place.street_address && place.street_address.length>0)
      {
        place.description = place.street_address;

        if(place.num && place.num.length>0)
        {
          place.description = place.description+', '+place.num;
        }
        if(place.zipcode && place.zipcode.length>0)
        {
          place.description = place.description+', '+place.zipcode;
        }
      }

      if(place.locality && place.locality.length>0)
      {
        if(place.description.length>0)
        {
          place.description = place.description + ', ';
        }
        place.description = place.description + place.locality;
      }

      result = place.description;
    }

    return result;
  }

  selectPlace(geodecoder, place, placeSelected) : Promise<any> {
    return new Promise((resolve) => {
   
      geodecoder.geocode({ 'placeId': placeSelected.place_id }, (results, status) => {
        if (status === 'OK' && results[0]) {

          var locationTmp = results[0];
          this.setLocationInfo(place, locationTmp);
          resolve(place);
          

        }
      })
    });
  
  }

  selectLatLng(geodecoder, place, latitude,logintude) : Promise<any> {
    return new Promise((resolve) => {
    var latlng = {lat: latitude, lng: logintude};

    geodecoder.geocode({ 'location': latlng }, (results, status) => {
      if (status === 'OK' && results[0]) {

        var locationTmp = results[0];
        this.setLocationInfo(place, locationTmp);
        resolve(place);
      }
    })
  });
  }

  selectAddress(geodecoder, place, addressSelected) {

    geodecoder.geocode({ 'address': addressSelected }, (results, status) => {
      if (status === 'OK' && results[0]) {

        var locationTmp = results[0];
        this.setLocationInfo(place, locationTmp);

      }
    })
  }

  setLocationInfoFromNative(place, locationSelected, lat, long) {
    if(locationSelected)
    {
      place.zipcode = "";
      if(locationSelected.postalCode && locationSelected.postalCode.length>0)
      {
        place.zipcode = locationSelected.postalCode;
      }
      place.num = "";
      if(locationSelected.subThoroughfare && locationSelected.subThoroughfare.length>0)
      {
        place.num = locationSelected.subThoroughfare;
      }
      place.street_address = "";
      if(locationSelected.thoroughfare && locationSelected.thoroughfare.length>0)
      {
        place.street_address = locationSelected.thoroughfare;
      }
      place.locality = "";
      if(locationSelected.locality && locationSelected.locality.length>0)
      {
        place.locality = locationSelected.locality;
      }
      place.sublocality = "";
      if(locationSelected.sublocality && locationSelected.sublocality.length>0)
      {
        place.sublocality = locationSelected.subLocality;
      }
      place.regione = "";
      if(locationSelected.administrativeArea && locationSelected.administrativeArea.length>0)
      {
        place.regione = locationSelected.administrativeArea;
      }
      place.nazione = "";
      if(locationSelected.countryName && locationSelected.countryName.length>0)
      {
        place.nazione = locationSelected.countryName;
      }
         
      place.nomelocale = "";
      place.latitude = lat;
      place.longitude = long;
      place.nazione = "";
      place.admin_area_3 = ""; 

      place.description = "";
      if(locationSelected.thoroughfare.length>0)
      {
        place.description = locationSelected.thoroughfare;

        if(locationSelected.subThoroughfare.length>0)
        {
          place.description = place.description+', '+place.num;
        }
        if(locationSelected.postalCode.length>0)
        {
          place.description = place.description+', '+place.zipcode;
        }
      }

      if(locationSelected.locality.length>0)
      {
        if(place.description.length>0)
        {
          place.description = place.description + ', ';
        }
        place.description = place.description + locationSelected.locality;
      }

    }
  }

  setLocationInfo(place, locationSelected) {
    place.zipcode = "";
    place.num = "";
    place.nomelocale = "";
    place.latitude = 0;
    place.longitude = 0;
    place.street_address = "";
    place.locality = "";
    place.sublocality = "";
    place.regione = "";
    place.nazione = "";
    place.admin_area_3 = ""; 
    place.description = "";

    var tipiPlaces = ['bakery', 'bar', 'cafe',
      'establishment', 'food', 'liquor_store',
      'meal_delivery', 'meal_takeaway',
      'pharmacy', 'restaurant', 'point_of_interest', 'store'];

    // Optional
    var countryCode = 'IT';

    var result_type = locationSelected.types[0]; // "Street_address"
    if (result_type) {

      place.description = locationSelected.formatted_address;

      if (result_type == "administrative_area_level_3"
        || result_type == "locality") {
        // ho inserito il nome della città o del comune

        for (var i = 0; i < locationSelected.address_components.length; i++) {
          var addressType = locationSelected.address_components[i].types[0];
          if (this.componentForm[addressType]) {
            var val = locationSelected.address_components[i][this.componentForm[addressType]];
            if (addressType === "administrative_area_level_3"
              || addressType === "locality") {
              place.locality = val;
            } else if (addressType === "administrative_area_level_2") {
              place.sublocality = val;
            } else if (addressType === "administrative_area_level_1") {
              place.regione = val;
            } else if (addressType === "country") {
              place.nazione = val;
            }
          }
        }// end ciclo address_components


      } else if ((result_type == "route")
        || (result_type == "street_address")
        || (result_type == "premise")
      ) {
        // Ho inserito il nome esatto della via

        for (var i = 0; i < locationSelected.address_components.length; i++) {
          var addressType = locationSelected.address_components[i].types[0];
          if (this.componentForm[addressType]) {
            var val = locationSelected.address_components[i][this.componentForm[addressType]];
            if (addressType === "street_number") {
              place.num = val;

            } else if (addressType === "postal_code") {
              place.zipcode = val;
              if ((locationSelected.geometry)
                && (locationSelected.geometry.location)) {
                place.latitude = locationSelected.geometry.location.lat();
                place.longitude = locationSelected.geometry.location.lng();
              }
            } else if (addressType === "street_address"
              || addressType === "route") {
              place.street_address = val;
            } else if (addressType === "locality") {
              place.locality = val;
            } else if (addressType === "sublocality"
              || addressType === "administrative_area_level_2") {
              place.sublocality = val;
            } else if (addressType === "administrative_area_level_3") {
              place.admin_area_3 = val;
            }
          }
        }

      } else {
        // potrei aver inserito il nome del
        // locale (da verificare se è¨
        // nell'elenco dei tipi riconosciuti)!
        //if ($scope.contains($scope.tipiPlaces, result_type)) {

        // ho riconosciuto il tipo di locale
        // come compatibile e ne estrapolo
        // tutte le informazioni

        if (result_type != 'point_of_interest') {
          place.nomelocale = locationSelected.name;
        }

        var tmp = locationSelected.formatted_address;
        if (tmp != undefined) {
          var infoindirizzo = tmp.split(',');
          if (infoindirizzo.length > 0) {
            place.street_address = infoindirizzo[0];
          }
        }

        if (locationSelected.address_components != undefined) {
          for (var i = 0; i < locationSelected.address_components.length; i++) {
            var addressType = locationSelected.address_components[i].types[0];
            if (this.componentForm[addressType]) {
              var val = locationSelected.address_components[i][this.componentForm[addressType]];
              var refresh = false;

              if (addressType === "street_number") {
                place.num = val;
              } else if ((addressType === "street_address")
                || (addressType === "route")) {
                place.street_address = val;
              } else if (addressType === "locality") {
                place.locality = val;
              } else if (addressType === "administrative_area_level_3") {
                place.admin_area_3 = val;
              } else if (addressType === "sublocality"
                || addressType === "administrative_area_level_2") {
                place.sublocality = val;
              } else if (addressType === "administrative_area_level_1") {
                place.regione = val;
              } else if (addressType === "country") {
                place.nazione = val;
              } else if (addressType === "postal_code") {
                place.zipcode = val;
                refresh = true;
                if ((locationSelected.geometry)
                  && (locationSelected.geometry.location)) {
                  place.latitude = locationSelected.geometry.location
                    .lat();
                  place.longitude = locationSelected.geometry.location
                    .lng();
                }
              }
            }

          }


        }
      }
      
      if (place.latitude == 0 && place.longitude == 0 && (locationSelected.geometry) && (locationSelected.geometry.location)) {
        place.latitude = locationSelected.geometry.location.lat();
        place.longitude = locationSelected.geometry.location.lng();
      }
    }

  };

  checkLocationCallDriver(geocoder, place) {

    return new Promise((resolve, reject) => {

      if (place) {

        place.address = place.address.replace(/'/g, ' '); //elimino tutti gli apici, sostituendoli con spazi...
        geocoder.geocode({ 'address': place.address }, (results, status) => {
          if (status === 'OK' && results[0]) {

            var locationTmp = results[0];

            if (locationTmp.address_components) {
              for (var i = 0; i < locationTmp.address_components.length; i++) {
                var addressType = locationTmp.address_components[i].types[0];
                if (this.componentForm[addressType]) {
                  var val = locationTmp.address_components[i][this.componentForm[addressType]];

                  if (addressType === "postal_code") {
                    if ((place.zipcode.length && place.zipcode == val) || place.zipcode.length == 0) {
                      place.zipcode = val;
                      if ((locationTmp.geometry) && (locationTmp.geometry.location)) {
                        place.latitude = locationTmp.geometry.location.lat();
                        place.longitude = locationTmp.geometry.location.lng();
                      }
                    }
                  }
                }
              }
            }
          }

          if (place.num.length && place.zipcode.length) {

            if (place.locality === '') {
              place.locality = place.admin_area_3;
            }
            resolve("");
          }
          else {
            reject("Inserire un indirizzo di consegna!");
          }


        })

      } else {

        reject("Inserire un indirizzo di consegna!");
      }

    });
  }*/

}
