import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { TypeOrder } from 'src/app/shared/services/config-app';
import { DB, RESPONSE } from 'src/app/shared/services/env.service';

@Component({
  selector: 'app-shop-checkpay',
  templateUrl: './shop-checkpay.component.html',
  styleUrls: ['./shop-checkpay.component.scss']
})
export class ShopCheckpayComponent {

  accountForm: FormGroup;
  paymentElement: any = null;
  stripe: any = null;
  elements: any = null;
  error: any = "";

  item: any;
  idorder: any = -1;
  itemUser: any = null;
  dataPay: any = {};
  selectedPayType: number = -1;

  constructor(private api: ApiService, private toastService: ToastrService, private router: Router) {
    let nft = localStorage.getItem(DB.PRODUCT);
    if (nft) this.item = JSON.parse(nft);
    console.log(this.item);
    let idorder = localStorage.getItem(DB.ORDER_ID);
    if (idorder) this.idorder = idorder;
    console.log("ID ORDINE: " + idorder);
    this.initForms();
  }

  ngOnInit() {
    console.log('OnInit shopCheckpay');
    // this.addressPage = true;
    this.fillUser();

    // this.loading.show();
    // this.load = true;
    // setTimeout(() => {
    //   console.log('Done');
    //   this.load = false;
    //   this.loading.hide();
    // }, 3000);
    // this.error = "";
    // this.paymentElement = null;
    // this.create();
  }


  initForms() {
    this.accountForm = new FormGroup({
      phone: new FormControl("", [Validators.required, Validators.pattern("[0-9]+")]),
      address: new FormControl("", Validators.required),
      num: new FormControl("", Validators.required),
      townstr: new FormControl("", Validators.required),
      zip: new FormControl("", [Validators.required, Validators.maxLength(5), Validators.minLength(5), Validators.pattern("[0-9]+")]),
      citofono: new FormControl("", Validators.required),
      // interno: new FormControl(""),
      note: new FormControl("")
    })
  }

  async fillUser() {

    this.itemUser = {};
    this.itemUser.name = "";
    this.itemUser.lastname = "";
    this.itemUser.phone = "";
    this.itemUser.address = "";
    this.itemUser.num = "";
    this.itemUser.townstr = "";
    this.itemUser.zip = "";
    this.itemUser.citofono = "";
    this.itemUser.note = "";

    this.api.postj_T("user/settings/infoaccount", {}).subscribe(res => {
      console.log(res);
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        // this.itemUser = res[RESPONSE.JP_RESULT];
      }
      else {
        console.log(res);
      }
    }, (err) => {
      console.log(err);
    })
  }

  checkAddr() {
    if (this.validate()) {
      //this.addressPage = !this.addressPage;
      this.updateOrderLocation();
      // this.toastService.show("indirizzo valido");
      this.router.navigate(['/cardpay'], { queryParams: { order_id: this.idorder, item: JSON.stringify(this.item), type: TypeOrder.MARKET } });

    }
  }

  updateOrderLocation() {
    let params = {
      "idorder": this.idorder,
      "name": this.itemUser.name,
      "lastname": this.itemUser.lastname,
      "phone": this.itemUser.phone,
      "address": this.itemUser.address,
      "num": this.itemUser.num,
      "townstr": this.itemUser.townstr,
      "zip": this.itemUser.zip,
      "citofono": this.itemUser.citofono,
      "note": this.itemUser.note
    };

    this.api.postj_T("order/updatelocationorder", params).subscribe(res => {
      console.log(res);
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {

      }
      else {
        console.log(res);
      }
    }, (err) => {
      console.log(err);
    })
  }

  validate() {
    if (!this.accountForm.valid) {
      let rErr = "Mancano i seguenti campi: "
      let err1 = (!this.accountForm.controls['phone'].valid && this.accountForm.controls['phone'].getError('required')) ||
        (!this.accountForm.controls['address'].valid && this.accountForm.controls['address'].getError('required')) ||
        (!this.accountForm.controls['num'].valid && this.accountForm.controls['num'].getError('required')) ||
        (!this.accountForm.controls['townstr'].valid && this.accountForm.controls['townstr'].getError('required')) ||
        (!this.accountForm.controls['zip'].valid && this.accountForm.controls['zip'].getError('required')) ||
        (!this.accountForm.controls['citofono'].valid && this.accountForm.controls['citofono'].getError('required'));

      if (!this.accountForm.controls['phone'].valid && this.accountForm.controls['phone'].getError('required')) rErr += "<br> Telefono";
      else if (!this.accountForm.controls['phone'].valid && this.accountForm.controls['phone'].getError('pattern')) this.toastService.error("Numero di telefono non valido!")

      if (!this.accountForm.controls['address'].valid && this.accountForm.controls['address'].getError('required')) rErr += "<br> Indirizzo";

      if (!this.accountForm.controls['num'].valid && this.accountForm.controls['num'].getError('required')) rErr += "<br> Numero";

      if (!this.accountForm.controls['townstr'].valid && this.accountForm.controls['townstr'].getError('required')) rErr += "<br> Città";

      if (!this.accountForm.controls['zip'].valid && this.accountForm.controls['zip'].getError('required')) rErr += "<br> CAP";
      else if (!this.accountForm.controls['zip'].valid && this.accountForm.controls['zip'].getError('pattern')) this.toastService.error("CAP non valido!");
      else if (!this.accountForm.controls['zip'].valid && (this.accountForm.controls['zip'].getError('minlength') || this.accountForm.controls['zip'].getError('maxlength'))) this.toastService.error("Il CAP deve essere formato da 5 numeri")

      if (!this.accountForm.controls['citofono'].valid && this.accountForm.controls['citofono'].getError('required')) rErr += "<br> Citofono";

      if (err1) this.toastService.error(rErr)
      return false;
    }
    return true;
  }

  inputHasError(input: string, error: string) {
    return this.accountForm.get(input).invalid && (this.accountForm.get(input).dirty || this.accountForm.get(input).touched) && this.accountForm.get(input).hasError(error)
  }

}
