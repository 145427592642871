import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { ProfileTabs } from './config-app';
import { AppInfo } from './env.service';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	class?: string;
	query?: any;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	profileTabs: any = ProfileTabs;

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMSLEFT: Menu[] = [
		{
			title: 'About', type: 'link', path: '/'
		},
		{
			title: 'Shop', type: 'link', path: '/shop'
		}
	]

	MENUITEMS: Menu[] = [
		{
			title: 'Entra', type: 'link', path: '/login', image: ''
		},
	];


	MENUITEMS_LOGGED: Menu[] = [
		// {
		// 	title: 'Torna al sito', type: 'extLink', path: AppInfo.backUrl, image: "", class: "backBtn"
		// },
		{
			title: 'Account', type: 'link', path: '/profile', image: "assets/images/account.png"
		},
		// {
		// 	title: 'Ordini', type: 'link', path: '/account', image: "assets/images/ordini.png", query: { tab: ProfileTabs.Info }
		// },
		/*
		{
			title: 'Prenotazioni', type: 'link', path: '/account', image: "assets/images/prenotazioni.png", query: { tab: ProfileTabs.Prenotazioni }
		},*/
		{
			title: 'Password', type: 'link', path: '/cambia-password', image: "assets/images/password.png"
		},
		{
			title: 'Esci', type: 'link', path: '/logout', image: "assets/images/logout.png"
		},
	];

	MENUITEMSRIGHT: Menu[] = [
		{
			title: 'Gainer', type: 'link', path: '/gainers-list'
		},
		{
			title: 'News', type: 'link', path: '/news-list'
		}
	]

	LEFTMENUITEMS: Menu[] = [
		{
			title: 'About', type: 'link', path: '/'
		},
		{
			title: 'Shop', type: 'link', path: '/shop'
		},
		{
			title: 'Gainer', type: 'link', path: '/gainers-list'
		},
		{
			title: 'News', type: 'link', path: '/news-list'
		}
	];

	LEFTMENUITEMS_LOGGED: Menu[] = [
		{
			title: 'About', type: 'link', path: '/'
		},
		{
			title: 'Shop', type: 'link', path: '/shop'
		},
		{
			title: 'Gainer', type: 'link', path: '/gainers-list'
		},
		{
			title: 'News', type: 'link', path: '/news-list'
		}
	];

	FOOTERMENUITEMS: Menu[] = [
		{
			title: 'Home', type: 'link', path: '/'
		},
		{
			title: 'About', type: 'link', path: '/'
		},
		{
			title: 'Shop', type: 'link', path: '/shop'
		},
		{
			title: 'Account', type: 'link', path: '/'
		},
		{
			title: 'FAQ', type: 'link', path: '/faq'
		},
	]

	LEGALNOTICEMENUITEMS: Menu[] = [
		{
			title: 'Termini e condizioni d\'uso', type: 'link', path: '/terms'
		},
		{
			title: 'Informativa sulla privacy', type: 'link', path: '/privacy'
		},
		{
			title: 'Informativa sui cookie', type: 'link', path: '/cookie-policy'
		},
		{
			title: 'Limited Use Disclosure', type: 'link', path: '/google'
		}
	]


	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	itemsLeft = new BehaviorSubject<Menu[]>(this.MENUITEMSLEFT);
	itemsRight = new BehaviorSubject<Menu[]>(this.MENUITEMSRIGHT);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);
	footerMenuItems = new BehaviorSubject<Menu[]>(this.FOOTERMENUITEMS);
	legalNoticeMenuItems = new BehaviorSubject<Menu[]>(this.LEGALNOTICEMENUITEMS);


	constructor(public auth: AuthService) {
		this.items.next(this.MENUITEMS);
		this.itemsLeft.next(this.MENUITEMSLEFT);
		this.itemsRight.next(this.MENUITEMSRIGHT);
		this.leftMenuItems.next(this.LEFTMENUITEMS);
		this.footerMenuItems.next(this.FOOTERMENUITEMS);
		this.legalNoticeMenuItems.next(this.LEGALNOTICEMENUITEMS);

		this.auth.currentUser.subscribe(x => {

			if (x) {
				this.items.next(this.MENUITEMS_LOGGED);
				this.leftMenuItems.next(this.LEFTMENUITEMS_LOGGED);
			}
			else {
				this.items.next(this.MENUITEMS);
				this.itemsLeft.next(this.MENUITEMSLEFT);
				this.itemsRight.next(this.MENUITEMSRIGHT);
				this.leftMenuItems.next(this.LEFTMENUITEMS);
				this.footerMenuItems.next(this.FOOTERMENUITEMS);
				this.legalNoticeMenuItems.next(this.LEGALNOTICEMENUITEMS);
			}

		});

	}

}
