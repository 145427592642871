import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filters-modal',
  templateUrl: './filters-modal.component.html',
  styleUrls: ['./filters-modal.component.scss']
})
export class FiltersModalComponent implements OnInit {

  @Input() filters: any = {promo: [], payment: []}
  @Input() pays: Array<any> = []
  @Input() sortingType: number = -1
  @Output() addFilter: EventEmitter<any> = new EventEmitter<any>()
  sorting: Array<any> = [{id: 0, title: "Distanza", checked: false}, {id: 1, title: "Valutazione", checked: false}]
  promos: Array<any> = [{id: 0, title: "Promo", img: "assets/images/promo_filter.png", active: false}, {id: 1, title: "Consegna gratuita", img: "assets/images/cons-grat.png", active: false}]

  constructor(private modalService: NgbModal) { 
    console.log("filters")
  }

  ngOnInit(): void {
  }

  init(){
    this.filters.promo.forEach((p: number) => {
      this.promos[p].active = true;
    });
    this.filters.payment.forEach((p: number) => {
      for (let i = 0; i < this.pays.length; i++) {
        if(this.pays[i].id == p){
          this.pays[i].active = true;
          break;
        }
      }
    });
    if(this.sortingType >= 0){
      this.sorting[this.sortingType].checked = true;
    }
  }

  updateFilter(type: number, filter: any){
    console.log("filter")
    this.addFilter.emit({type: type, value: filter})
  }

  updateSorting(sort: any){
    this.sorting.forEach(srt => {
      if(sort.id != srt.id) srt.checked = false;
    });
    this.addFilter.emit({type: 2, value: sort})
  }

  isChecked(sort: any){ 
    return sort.checked;
  }

  dismiss(){
    this.modalService.dismissAll()
  }
}
