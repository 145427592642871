import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HorizontalScrollCatComponent } from './horizontal-scroll-cat.component';
import { ScrollMenuDirective } from './scroll-menu.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';


@NgModule({
  declarations: [ScrollMenuDirective, HorizontalScrollCatComponent],
  imports: [
    CommonModule, RouterModule, CarouselModule,
  ],
  exports: [HorizontalScrollCatComponent],
})
export class HorizontalScrollCatModule { }
