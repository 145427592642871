import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
//import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { DB, RESPONSE, RESULT } from './env.service';
//import { LoadingService } from './loading-service';
import { ApiService } from './api.service';
import { EitPlace } from "../../shared/classes/eitplace"

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private locSubject: BehaviorSubject<any>;
  private del_R_Subject: BehaviorSubject<[]>;
  private ta_R_Subject: BehaviorSubject<[]>;
  private tables_R_Subject: BehaviorSubject<[]>;
  public location: Observable<any>;
  public rest_delivery: Observable<[]>;
  public rest_ta: Observable<[]>;
  public rest_tables: Observable<[]>;

  componentForm: any = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    country: 'long_name',
    postal_code: 'short_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'short_name',
    administrative_area_level_3: 'short_name'
  };

  constructor(/*private nativeStorage: NativeStorage, private loadingService: LoadingService,*/ private api: ApiService) {

    var location = new EitPlace();
    location.initData();


    this.locSubject = new BehaviorSubject<any>(location);
    this.del_R_Subject = new BehaviorSubject<[]>([]);
    this.ta_R_Subject = new BehaviorSubject<[]>([]);
    this.tables_R_Subject = new BehaviorSubject<[]>([]);



    this.location = this.locSubject.asObservable();
    this.rest_delivery = this.del_R_Subject.asObservable();
    this.rest_ta = this.ta_R_Subject.asObservable();
    this.rest_tables = this.tables_R_Subject.asObservable();

  }

  public nextLocation(value) {
    this.locSubject.next(value);
    localStorage.setItem(DB.LOCATION, JSON.stringify(value));
  }

  resetDelivery_R() {
    this.del_R_Subject.next([]);
  }

  deliveryNext(value)
  {
    this.del_R_Subject.next(value);
  }

  loadDelivery_R(silent, category=0) {
    if (!silent) {
    //  this.loadingService.show();
    }
    var loc = this.locSubject.value;
    loc.iswww = 1;
    loc.categoryact = category;
    return this.api.postj_T("restaurant/rdelivery", loc, "").subscribe(data => {
      if (
        data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
        data[RESPONSE.JP_RESULT]
      ) {
        if (data[RESPONSE.JP_RESULT].length > 0) {
          this.del_R_Subject.next(data[RESPONSE.JP_RESULT]);
        }else{
          this.del_R_Subject.next(null);
        }
      }
     // this.loadingService.hide();
    }, (err) => {
      console.log(err);
      //this.loadingService.hide();
      this.del_R_Subject.next(null);
    })
  }

  loadDelivery_Rslug(slug,silent) {
    if (!silent) {
    //  this.loadingService.show();
    }
    
    return this.api.postj_T("restaurant/slugdomicilio", { 'slug': slug }, "");
  }

  tablesNext(value)
  {
    this.tables_R_Subject.next(value);
  }

  loadTables_R(silent) {
    console.log("load Tables R");
    if (!silent) {
    //  this.loadingService.show();
    }
    var loc = this.locSubject.value;
    loc.iswww = 1;
    return this.api.postj_T("restaurant/rtables", loc, "").subscribe(data => {
      if (
        data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
        data[RESPONSE.JP_RESULT]
      ) {
        if (data[RESPONSE.JP_RESULT].length > 0) {
          this.tables_R_Subject.next(data[RESPONSE.JP_RESULT]);
        }
      }
     // this.loadingService.hide();
    }, (err) => {
      console.log(err);
      //this.loadingService.hide();
    })
  }

  takeawayNext(value)
  {
    this.ta_R_Subject.next(value);
  }

  loadTakeAway_R(silent, category=0) {
    
    if (!silent) {
      //  this.loadingService.show();
      }
      var loc = this.locSubject.value;
      loc.iswww = 1;
      loc.categoryact = category;
      return this.api.postj_T("restaurant/rtakeaway", loc, "").subscribe(data => {
        if (
          data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          data[RESPONSE.JP_RESULT]
        ) {
          if (data[RESPONSE.JP_RESULT].length > 0) {
            this.ta_R_Subject.next(data[RESPONSE.JP_RESULT]);
          }
        }
       // this.loadingService.hide();
      }, (err) => {
        console.log(err);
        //this.loadingService.hide();
      })
  }

  selectPlace(geodecoder, place, placeSelected): Promise<any> {
    return new Promise((resolve) => {
      geodecoder.geocode({ 'placeId': placeSelected.place_id }, (results, status) => {
        if (status === 'OK' && results[0]) {
          var locationTmp = results[0];

          this.setLocationInfo(place, locationTmp);

          if (place.zipcode == ""){
            this.getZipFromLatLong(locationTmp, geodecoder, place);            
          }

          //this.nextLocation(place);

          if (place.locality === "") {
            place.locality = place.admin_area_3;
          }

          setTimeout(() => { resolve(place); }, 1000);
          
        }
      })
    })
  }

  setLocationInfoFromNative(place, locationSelected, lat, long) {
    if (locationSelected) {
      place.zipcode = "";
      if (locationSelected.postalCode && locationSelected.postalCode.length > 0) {
        place.zipcode = locationSelected.postalCode;
      }
      place.num = "";
      if (locationSelected.subThoroughfare && locationSelected.subThoroughfare.length > 0) {
        place.num = locationSelected.subThoroughfare;
      }
      place.street_address = "";
      if (locationSelected.thoroughfare && locationSelected.thoroughfare.length > 0) {
        place.street_address = locationSelected.thoroughfare;
      }
      place.locality = "";
      if (locationSelected.locality && locationSelected.locality.length > 0) {
        place.locality = locationSelected.locality;
      }
      place.sublocality = "";
      if (locationSelected.sublocality && locationSelected.sublocality.length > 0) {
        place.sublocality = locationSelected.subLocality;
      }
      place.regione = "";
      if (locationSelected.administrativeArea && locationSelected.administrativeArea.length > 0) {
        place.regione = locationSelected.administrativeArea;
      }
      place.nazione = "";
      if (locationSelected.countryName && locationSelected.countryName.length > 0) {
        place.nazione = locationSelected.countryName;
      }

      place.nomelocale = "";
      place.latitude = lat;
      place.longitude = long;
      place.nazione = "";
      place.admin_area_3 = "";

      place.description = "";
      if (locationSelected.thoroughfare && locationSelected.thoroughfare.length > 0) {
        place.description = locationSelected.thoroughfare;

        if (locationSelected.subThoroughfare.length > 0) {
          place.description = place.description + ', ' + place.num;
        }
        if (locationSelected.postalCode.length > 0) {
          place.description = place.description + ', ' + place.zipcode;
        }
      }
      else if (locationSelected.formatted_address) {
        place.description = locationSelected.formatted_address;

      }

      if (locationSelected.locality && locationSelected.locality.length > 0) {
        if (place.description.length > 0) {
          place.description = place.description + ', ';
        }
        place.description = place.description + locationSelected.locality;
      }

    }
  }

  setLocationInfo(place, locationSelected) {


    console.log("In Set location info");

    place.zipcode = "";
    place.num = "";
    place.numForced = "";
    place.nomelocale = "";
    place.latitude = 0;
    place.longitude = 0;
    place.street_address = "";
    place.locality = "";
    place.sublocality = "";
    place.regione = "";
    place.nazione = "";
    place.admin_area_3 = "";
    place.description = "";

    var tipiPlaces = ['bakery', 'bar', 'cafe',
      'establishment', 'food', 'liquor_store',
      'meal_delivery', 'meal_takeaway',
      'pharmacy', 'restaurant', 'point_of_interest', 'store'];

    // Optional
    var countryCode = 'IT';

    var result_type = locationSelected.types[0]; // "Street_address"
    if (result_type) {

      place.description = locationSelected.formatted_address;

      if (result_type == "administrative_area_level_3"
        || result_type == "locality") {
        // ho inserito il nome della città o del comune

        for (var i = 0; i < locationSelected.address_components.length; i++) {
          var addressType = locationSelected.address_components[i].types[0];
          if (this.componentForm[addressType]) {
            var val = locationSelected.address_components[i][this.componentForm[addressType]];
            if (addressType === "administrative_area_level_3"
              || addressType === "locality") {
              place.locality = val;
            } else if (addressType === "administrative_area_level_2") {
              place.sublocality = val;
            } else if (addressType === "administrative_area_level_1") {
              place.regione = val;
            } else if (addressType === "country") {
              place.nazione = val;
            }
          }
        }// end ciclo address_components


      } else if ((result_type == "route")
        || (result_type == "street_address")
        || (result_type == "premise")
      ) {
        // Ho inserito il nome esatto della via

        for (var i = 0; i < locationSelected.address_components.length; i++) {
          var addressType = locationSelected.address_components[i].types[0];
          if (this.componentForm[addressType]) {
            var val = locationSelected.address_components[i][this.componentForm[addressType]];
            if (addressType === "street_number") {
              place.num = val;

            } else if (addressType === "postal_code") {
              place.zipcode = val;
              if ((locationSelected.geometry)
                && (locationSelected.geometry.location)) {
                place.latitude = locationSelected.geometry.location.lat();
                place.longitude = locationSelected.geometry.location.lng();
              }
            } else if (addressType === "street_address"
              || addressType === "route") {
              place.street_address = val;
              if ((locationSelected.geometry)
                && (locationSelected.geometry.location)) {
                place.latitude = locationSelected.geometry.location.lat();
                place.longitude = locationSelected.geometry.location.lng();
              }
            } else if (addressType === "locality") {
              place.locality = val;
            } else if (addressType === "sublocality"
              || addressType === "administrative_area_level_2") {
              place.sublocality = val;
            } else if (addressType === "administrative_area_level_3") {
              place.admin_area_3 = val;
            }
          }
        }

      } else {
        // potrei aver inserito il nome del
        // locale (da verificare se è¨
        // nell'elenco dei tipi riconosciuti)!
        //if ($scope.contains($scope.tipiPlaces, result_type)) {

        // ho riconosciuto il tipo di locale
        // come compatibile e ne estrapolo
        // tutte le informazioni

        if (result_type != 'point_of_interest') {
          place.nomelocale = locationSelected.name;
        }

        var tmp = locationSelected.formatted_address;
        if (tmp != undefined) {
          var infoindirizzo = tmp.split(',');
          if (infoindirizzo.length > 0) {
            place.street_address = infoindirizzo[0];
          }
        }

        if (locationSelected.address_components != undefined) {
          for (var i = 0; i < locationSelected.address_components.length; i++) {
            var addressType = locationSelected.address_components[i].types[0];
            if (this.componentForm[addressType]) {
              var val = locationSelected.address_components[i][this.componentForm[addressType]];
              var refresh = false;

              if (addressType === "street_number") {
                place.num = val;
              } else if ((addressType === "street_address")
                || (addressType === "route")) {
                place.street_address = val;
              } else if (addressType === "locality") {
                place.locality = val;
              } else if (addressType === "administrative_area_level_3") {
                place.admin_area_3 = val;
              } else if (addressType === "sublocality"
                || addressType === "administrative_area_level_2") {
                place.sublocality = val;
              } else if (addressType === "administrative_area_level_1") {
                place.regione = val;
              } else if (addressType === "country") {
                place.nazione = val;
              } else if (addressType === "postal_code") {
                place.zipcode = val;
                refresh = true;
                if ((locationSelected.geometry)
                  && (locationSelected.geometry.location)) {
                  place.latitude = locationSelected.geometry.location
                    .lat();
                  place.longitude = locationSelected.geometry.location
                    .lng();
                }
              }
            }

          }


        }/* else {
            // non ho inserito nulla di
            // decifrato...
            //$scope
            // .popupMsg("Inserire una città  o una via!");
            }*/
      }
    }

  }

  getAddressFromPlace(place: any) {

    let result = "";

    if (place) {

      place.description = "";

      if (place.street_address && place.street_address.length > 0) {
        place.description = place.street_address;
        if (place.num && place.num.length > 0) place.description += ', ' + place.num;
        if (place.zipcode && place.zipcode.length > 0) place.description += ', ' + place.zipcode;
      }

      if (place.locality && place.locality.length > 0) {
        if (place.description.length > 0) place.description += ', ';
        place.description += place.locality;
      }

      result = place.description;
    }

    return result;
  }

  selectAddress(geodecoder: any, place: any, addressSelected: any, save: any) {
    geodecoder.geocode({ 'address': addressSelected }, (results: Array<any>, status: any) => {
      if (status === 'OK' && results[0]) {
        var locationTmp = results[0];
        this.setLocationInfo(place, locationTmp);

        if (place.zipcode == ""){
          this.getZipFromLatLong(locationTmp, geodecoder, place);            
        }

        if(save)
        {
          this.nextLocation(place);
        }
      }
    })
  }


  isDeliveryOk(location) {

    var loc = location;
    var result = RESULT.RESULT_ERROR_ADDRESS;
    if (!location) {
      loc = this.locSubject.value;
    }

    if (loc.num && loc.num.length) {

    }
    else if (loc.numForced && loc.numForced.length) {
      loc.num = loc.numForced;
    }

    if (
      loc.num &&
      loc.zipcode &&
      loc.num.length &&
      loc.zipcode.length
    ) {

      result = RESULT.RESULT_OK;
    } else if (
      loc.street_address &&
      loc.street_address.length
    ) {
      result = RESULT.RESULT_ERROR_CIVICO;
    } else {
      result = RESULT.RESULT_ERROR_ADDRESS;
    }

    return result;
  }

  isTakeAwayOk(location) {

    var loc = location;
    var result = RESULT.RESULT_ERROR_ADDRESS;
    if (!location) {
      loc = this.locSubject.value;
    }

    if ((loc.zipcode && loc.zipcode != '-1') || (loc.locality && loc.locality != '-1')) {

      result = RESULT.RESULT_OK;

    } else {
      result = RESULT.RESULT_ERROR_ADDRESS;
    }


    return result;
  }

  isTablesOk(location) {

    var loc = location;
    var result = RESULT.RESULT_ERROR_ADDRESS;
    if (!location) {
      loc = this.locSubject.value;
    }

    if (
      (loc.latitude &&
       loc.longitude &&
       loc.latitude.length &&
       loc.longitude.length ) ||
      (loc.locality &&
        loc.locality.length) ||
      (loc.nomelocale &&
        loc.nomelocale.length)
    ) {

      result = RESULT.RESULT_OK;
    } else {
      result = RESULT.RESULT_ERROR_ADDRESS;
    }

    return result;
  }

  goTables(location) {

    var loc = location;
    var result = RESULT.RESULT_ERROR_ADDRESS;
    if (!location) {
      loc = this.locSubject.value;
    }

    if (
      (loc.zipcode &&
        loc.zipcode.length) ||
      (loc.locality &&
        loc.locality.length) ||
      (loc.nomelocale &&
        loc.nomelocale.length)
    ) {
      result = RESULT.RESULT_OK;
    } else {
      result = RESULT.RESULT_ERROR_ADDRESS;
    }

    return RESULT;
  }

  checkAddressOnly(location: any, id_rest: any){
    console.log("Check address only");
    let data = location;
    data.rest_id = id_rest;
    return this.api.postj_T("cart/checkaddress", data, "");
  }


  getZipFromLatLong(place: any, geodecoder: any, placeTmp: any) {

    console.log("get zip from lat lng");

    var latlng = {
      lat: 0,
      lng: 0
    };

    if ((place.geometry) && (place.geometry.location)) {
      latlng.lat = place.geometry.location.lat();
      latlng.lng = place.geometry.location.lng();
    }

    geodecoder.geocode({ 'location': latlng }, (results: Array<any>, status: any) => {
      if (status === 'OK' && results[0]) {
        var locationTmp = results[0];

        for (var i = 0; i < locationTmp.address_components.length; i++) {
          var addressType = locationTmp.address_components[i].types[0];
          if (this.componentForm[addressType]) {
            var val = locationTmp.address_components[i][this.componentForm[addressType]];
            if (addressType === "postal_code" && val != "") {
              placeTmp.zipcode = val;     
              break; 
            }
          }
        }
        
      }
    })

    
  }
  
  checkAddressHome(location: any){
    console.log("Check address home");//{place: location}
    return this.api.postj_("restaurant/checkaddress", {place: location}, "");
  }

}
