<owl-carousel-o [options]="configSlider" class="home-slider">
    <ng-container *ngFor="let slider of items">
        <ng-template carouselSlide>
            <div class="home">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="slider-contain d-flex flex-column">
                                <div class="screen">
                                    <h3 *ngIf="slider.type==3 || slider.type==4"
                                        class="text-size-sm text-color-accent font-bold text-wrap no-margin">
                                        {{(slider.value/1000).toFixed(2)}} Km</h3>
                                    <h3 *ngIf="slider.type==2"
                                        class="text-size-sm text-color-accent font-bold text-wrap no-margin">
                                        {{getTimeWorkout(slider)}}</h3>
                                    <h3 *ngIf="slider.type!=3 && slider.type!=4  && slider.type!=2"
                                        class="text-size-sm text-color-accent font-bold text-wrap no-margin">
                                        {{slider.value}}</h3>
                                    <h2 class="text-size-md text-color-accent  text-wrap">
                                        {{slider.descr_short}}</h2>
                                </div>
                                <div class="circle-progress mt-5">
                                    <circle-progress [percent]="slider.value*100/slider.max_value" [maxPercent]="100"
                                        [outerStrokeWidth]="3" [innerStrokeWidth]="18" [outerStrokeColor]="'#62F2F9'"
                                        [innerStrokeColor]="'#F4FEFE'" [animation]="true" [space]="-11"
                                        [animationDuration]="300" [title]="slider.category" [responsive]="true"
                                        [class]="'eclaim'" [showUnits]="false" [showSubtitle]="false"></circle-progress>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>