import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../../shared/services/api.service';
import { RESPONSE } from '../../../../shared/services/env.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-request-company',
  templateUrl: './request-company.component.html',
  styleUrls: ['./request-company.component.scss']
})
export class RequestCompanyComponent implements OnInit {

  requestForm: FormGroup;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  
  constructor(private modalService: NgbModal, private api: ApiService, private toastService: ToastrService) { 
    this.requestForm = new FormGroup({
      name: new FormControl("", Validators.required),
      lastname: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      phone: new FormControl("", Validators.required),
      age: new FormControl(""),
      job: new FormControl(""),
      city: new FormControl(""),
      company: new FormControl("", Validators.required),
      notes: new FormControl("Azienda Corporate"),
      type: new FormControl(3)
    })
  }

  ngOnInit(): void {
  }

  @Input() init(){
    this.requestForm.patchValue({name: "", lastname: "", email: "", phone: "", company: ""})
  }

  inputHasError(input: string, error: string){
    return this.requestForm.get(input).invalid && (this.requestForm.get(input).dirty || this.requestForm.get(input).touched) && this.requestForm.get(input).hasError(error)
  }

  send(){
    console.log(this.requestForm.value)
    if(this.requestForm.valid){
      this.api.postj_T("richieste/sendrequest", this.requestForm.value, "").subscribe(res => {
        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
          this.toastService.success("Richiesta inviata!")
          this.dismiss(RESPONSE.JPS_OK)
        }
        else this.toastService.error("Controlla i dati inseriti!")
      });
    }
    else this.toastService.error("Controlla i dati inseriti!")
  }

  dismiss(status?: string){
    this.onDismiss.emit({status: (status ? status : RESPONSE.JPS_ERROR)})
    this.modalService.dismissAll()
  }

}
