import { Component, Input, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() public menuItems: Menu[] = [];
  public isLogged = true;

  constructor(private router: Router, public navServices: NavService, private authService: AuthService) {
    
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {

    this.authService.currentUser.subscribe(x => {

      if (x) {
        this.isLogged = true;
      }
      else {
        this.isLogged = false;
      }

    });
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  logout() {
    this.authService.logout();
  }

}
