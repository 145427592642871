<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="container-fluid">
    <div class="row-menu p-0 d-flex align-items-center justify-content-between">
      <div class="text-end d-flex flex-sm-row flex-column align-items-md-center align-items-start mw">
        <!--Left page-->
        <div class="p-md-2">
          <app-left-menu></app-left-menu>
        </div>
        <div class="d-inline-flex align-items-center gap-sm-2 saldo" *ngIf="user">
          <img src="assets/images/fitcoin.png" class="img-fluid saldo-img">
          <div class="ps-2">
            <!--TODO: inserire saldo account-->
            <h3 class="m-0 text-center text-light fw-bold">{{user.etikacoin+user.fitcoin}}</h3>
            <p class="text-center text-light fw-bold m-0">saldo</p>
          </div>
        </div>
      </div>
      <div class="main-menu d-md-flex d-block text-center justify-content-center">
        <!--Left logo-->
        <div class="menu-left mx-5 ">
          <app-menu [menuItems]="menuItemsLeft"></app-menu>
        </div>

        <!--logo-->
        <div class="brand-logo p-0">
          <a routerLink="/" id="headerlogo">
            <img src="assets/images/logo-etika.png" class="img-fluid" alt="logo">
          </a>
        </div>

        <!--right logo-->

        <div class="menu-right mx-5">
          <div>
            <app-menu [menuItems]="menuItemsRight"></app-menu>
          </div>
        </div>
        
      </div>
      <div class="d-flex justify-content-end pe-3 mw">
        <!--account-->
        <app-settings></app-settings>
      </div>
    </div>
  </div>
</header>