import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { RESULT, RESPONSE, DB, CategorieAttivita } from '../../services/env.service';
import { ConfigApp } from '../../services/config-app';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-gainerslider',
  templateUrl: './gainerslider.component.html',
  styleUrls: ['./gainerslider.component.scss']
})
export class GainersliderComponent {

    @Input() configSlider: OwlOptions = {
    loop: true,
    dots: false,
    nav: false,
    navSpeed: 300,
    skip_validateItems: true,  //non controlla se il numero di elmenti nell'array è inferiore al numero di items da visualizzare
    responsive: {
      0: {
        items: 2
      },
      420: {
        items: 5
      },
      767: {
        items: 5
      },
      991: {
        items: 8
      },
      1300:{
        items:8
      }
    }
  };

  // @Input() location: null;
  sliders: any;
  @Input() items:Array<any> = []

  distance = 50;
  scrollSpeed = 100;
  leftArrowHide = false;
  rightArrowHide = false;

  @ViewChild('promoScroll', { static: false }) scrollWrapper: ElementRef;


  constructor(public config: ConfigApp,
    private route: ActivatedRoute, private router: Router,
    public api: ApiService) { 
    this.sliders = {};
    this.sliders.items = [];
  }

  ngOnInit(): void {
    this.initSlider();
    setTimeout(() => {
      if (this.scrollWrapper && this.scrollWrapper.nativeElement && this.scrollWrapper.nativeElement.parentElement && (this.scrollWrapper.nativeElement.scrollWidth > this.scrollWrapper.nativeElement.parentElement.clientWidth)) {
        this.leftArrowHide = true;
        this.rightArrowHide = true;
      }
    }, 1000);
  }

  initSlider() {

    this.sliders = {};
    this.sliders.items = [];
    this.sliders.items = this.items;
    // if (this.location != undefined && this.location != "undefined" && this.location != null && this.location != "null") {

    //   this.api.postj("home/mediaslider", this.location, "").subscribe(
    //     res => {
    //       if (
    //         res[this.config.JP_STATUS] == this.config.JPS_OK &&
    //         res[this.config.JP_RESULT]
    //       ) {
    //         if (res[this.config.JP_RESULT].length > 0) {
    //           this.sliders.items = [];
    //           //this.cdRef.detectChanges();
    //           this.sliders.items = res[this.config.JP_RESULT];
    //           //this.cdRef.detectChanges();
    //         }
    //         else {
              
    //         }

    //       } else if (
    //         res[this.config.JP_STATUS] == this.config.JPS_ERROR &&
    //         res[this.config.JP_MSG]
    //       ) {
            
    //       } else {
    //         console.log(res);
           
    //       }
    //     },
    //     err => {
    //       console.log(err);
          
    //     }
    //   );
    // }

  }

  scrollRight(): void {
    const listWrapper = document.getElementById('mix-items');
    const scrollLeft = listWrapper.scrollLeft;
    const distance = scrollLeft + this.distance;
    this.scroll(distance);
  }

  scrollLeft(): void {
    const leftArrow = document.getElementById('mix-items');
    const scrollLeft = leftArrow.scrollLeft;
    const distance = scrollLeft - this.distance;
    this.scroll(distance);

  }

  scroll(distance): void {
    const listWrapper = document.getElementById('mix-items');
    listWrapper.scrollTo({ behavior: 'smooth', left: distance });
  }

  getTimeWorkout(item)
  {

    var minutes = item.value * 60;

    var hour = Math.trunc(item.value);

    minutes = minutes - (hour * 60);

    minutes = Math.round(minutes);

    var result = hour + "h " + minutes + "min";

    return result;
  }

  onGoToGainer(id: number) {
    this.router.navigate(['/gainer'], { queryParams: { id_gainer: id } });
  }

}
