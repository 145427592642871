import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RESPONSE } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CartService } from "../../../services/cart-service";

@Component({
  selector: 'app-coupon-modal',
  templateUrl: './coupon-modal.component.html',
  styleUrls: ['./coupon-modal.component.scss']
})
export class CouponModalComponent implements OnInit {
  
  @Input() order: any = {}    
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()

  coupon_id: any = 0;
  coupon_code: any = "";
  ordineminimo: any = 0;
  codice: any = "";
  couponapplicato: any = false;

  constructor(private modalService: NgbModal, private api: ApiService, private toastService: ToastrService, private cartService: CartService) { 
    
  }

  ngOnInit(): void {
    
  }

  @Input() init(){

    console.log("init coupon");
    
    this.coupon_id = this.order.coupon_id;
    this.coupon_code = this.order.coupon;
    this.ordineminimo = this.order.ordineminimo;
    this.couponapplicato = this.coupon_id>0;

  }

  dismiss(status?: string){
    console.log("dismiss coupon: " + this.coupon_code);
    this.onDismiss.emit({status: (status ? status : RESPONSE.JPS_ERROR)});
    this.modalService.dismissAll();
  }

  /*
  updateorario(){
    console.log("dismiss datetime: " + this.coupon_code);
    this.onDismiss.emit({status: RESPONSE.JPS_OK, coupon: this.coupon_code});
    this.modalService.dismissAll();
  }
  */

  applica()
  {
    var data: any = {};
    data.codice = this.codice;

    data.orderid = this.order.id;
    data.costcart = this.order.costcart;
    data.euro_delivery = this.order.euro_delivery;

    this.api.postj_T("order/applycoupon", data, "").subscribe(
      res => {
        console.log(res);
        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
          this.coupon_code = this.codice;
          this.couponapplicato = true;
          let msg = res[RESPONSE.JP_MSG];
          this.toastService.success(msg);

          this.cartService.loadCart(this.order.id);


        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
          let msg = res[RESPONSE.JP_MSG];
          this.toastService.error("Attenzione!  " + msg);
        } else {
          console.log(res);
        }
      },
      err => {
        console.log(err);
      }
    );



  }

  rimuovi()
  {
    var data: any = {};
    data.orderid = this.order.id;

    this.api.postj_T("order/removecoupon", data, "").subscribe(
      res => {
        console.log(res);
        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
          this.coupon_code = "assente";
          this.couponapplicato = false;

          //this.cartData = res[this.config.JP_RESULT];
          //localStorage.setItem("cart", JSON.stringify(this.cartData));

          this.toastService.success("Coupon rimosso correttamente.");

          this.cartService.loadCart(this.order.id);
        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
          let msg = res[RESPONSE.JP_MSG];
          this.toastService.error("Attenzione!  " + msg);
        } else {
          console.log(res);
        }
      },
      err => {
        console.log(err);
      }
    );

  }

}
