import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-gainer',
  templateUrl: './gainer.component.html',
  styleUrls: ['./gainer.component.scss']
})
export class GainerComponent {
  id: number = 0;
  data: any;

  constructor(private route: ActivatedRoute, private api: ApiService) {
    this.id = this.route.snapshot.queryParams.id_gainer;
    console.log(this.id)
    this.fill()
  }

  fill(){
    this.api.getGainer(this.id).then(res => {
      this.data = res;
    })
  }

  ngOnChanges(changes: { [propKey: string]: any }) {
    this.data = changes["data"].currentValue;
  }

  getHeader() : string{
    return this.data && this.data.media_header ? this.data.media_header.realpath : ""
  }

  onWebsiteFunc() {
    window.location.href = this.data.link_website; 
  }
}
