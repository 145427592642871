<div *ngIf="!loader && cartData && cartData.order">
    <div class="product-right product-form-box checkout-details box cart-box">
        
        <div class="text-center m-0 box-title">
            <h3 class="m-0">Il tuo menu</h3>
        </div>
        
        <ng-container *ngFor="let elCartArray of (cartData.order.items | array)">

            <ng-container *ngFor="let elCart of elCartArray">

                <div class="media cart-product  mr-3 ml-3">
                    <button *ngIf="editable" type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus"
                        (click)="removeToCartSingle(elCart)">
                        <!--<i class="ti-angle-right"></i>-->
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-dash-circle-fill"
                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z" />
                        </svg>
                    </button>

                    <div class="media-body align-self-center">
                        <h6 class="text-start">{{ elCart.name }}</h6>

                        <p class="text-start"
                            *ngIf="elCart.details && elCart.details.length==0 && elCart.extradetails && elCart.extradetails.length==0 && elCart.subitems && elCart.subitems.length==0">
                            {{ elCart.descr  }}
                        </p>
                        <p class="text-start" *ngFor="let detail of elCart.details">
                            + {{ detail.name }}
                        </p>
                        <p class="text-start" *ngFor="let extradetail of elCart.extradetails">
                            + {{ extradetail.name }}
                        </p>
                        <ng-container *ngFor="let subitem of elCart.subitems">
                            <p class="text-start" *ngIf="subitem.name">+ {{ subitem.name }}</p>
                            <ng-container *ngIf="!subitem.name">
                                <p class="text-start" *ngFor="let subit of subitem">
                                    + {{ subit.name }}
                                </p>
                            </ng-container>
                        </ng-container>
                    </div>
                    <h6 class="align-self-top cart-price text-end">{{ elCart.qty }} x {{ elCart.endprice }} &euro;
                    </h6>

                    <button *ngIf="editable" type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus"
                        (click)="addToCartSingle(elCart)">
                        <!--<i class="ti-angle-right"></i>-->
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill"
                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                        </svg>
                    </button>

                </div>

            </ng-container>
        </ng-container>
        <div class="border-product mr-3 ml-3"></div>
        <div class="media mr-3 ml-3" *ngIf="cartData.order.euro_coupon>0.01">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Coupon</h6>
            </div>
            <h6 class="align-self-top text-end product-title">-{{cartData.order.euro_coupon}} &euro;</h6>
        </div>

        <div class="media mr-3 ml-3" *ngIf="cartData.order.euro_promo==0 && cartData.order.messaggiopromo != null">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">{{cartData.order.messaggiopromo}}</h6>
            </div>
        </div>

        <div class="media mr-3 ml-3" *ngIf="cartData.order.euro_promo>0.01">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Promo</h6>
            </div>
            <h6 class="align-self-top text-end product-title">-{{cartData.order.euro_promo}} &euro;</h6>
        </div>

        <div class="media mr-3 ml-3" *ngIf="cartData.order.credit>0.01">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Credito</h6>
            </div>
            <h6 class="align-self-top text-end product-title">-{{cartData.order.credit}} &euro;</h6>
        </div>

        <div class="media mr-3 ml-3" >
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Totale</h6>
            </div>

            <h6 class="align-self-top text-end product-title">{{cartData.order.euro_total}} &euro;</h6>

        </div>

    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>