<app-header-one [class]="'header-gym'"></app-header-one>
<section class="section-b-space" *ngIf="data">
    <div class="container">
        <div class="row px-3">
            <div class="col-12 d-md-none"> <!--titolo per versione mobile-->
                <h1 class="mobile-title mb-5">{{data.name}}</h1>
            </div>
            <div class="col-12 col-md-6 "> <!--immagini-->
                <div class="product-image p-5 text-center">
                    <img class="product-img" *ngIf="data.media" [src]="data.media.realpath">
                </div>
            </div>
            <div class="col-12 col-md-6 py-3 px-sm-5"><!--testi-->
                <div class="desc d-none d-md-block py-2">
                    <h1 class="title">{{data.name}}</h1>
                </div>
                <div class="price">
                    <h3 class="text-size-md fw-bold text-color-primary mb-0">{{data.price?.toFixed(2)}}&euro;</h3>
                    <p class="text-size-sm product-descr font-regular text-color-secondary">
                        Prezzo consigliato
                    </p>
                </div>
                <div class="price">
                    <h2 class="text-size-lg fw-bold product-price">
                        <ng-container *ngIf="!data.allfitcoin">
                        {{(data.price-data.fitcoins).toFixed(2)}}
                        <span class="text-color-accent-euro">&euro;</span> + </ng-container>{{data.fitcoins}} <span class="text-color-accent-fit text-capitalize">Fitcoin</span>
                    </h2>
                </div>
                <div class="shop-button text-start py-5">
                    <button (click)="goToPayment()" class="btn btn-solid">Acquista ora</button>
                </div>
                <h6 class="text-uppercase eit-text-grey fw-bold" *ngIf="data && data.descr && data.descr.length">Descrizione</h6>
                <div class="py-2 eit-text-grey" [innerHTML]="data.descr"></div>
            </div>
        </div>
    </div>



    <div
        class="d-flex justify-content-center align-items-center text-center mobile-fix-option	d-block d-sm-block d-md-none mobile-footer-fixed">
        <a class="btn btn-solid mr-3 text-center ng-star-inserted mr-3 text-center" (click)="goToPayment()">Acquista
            ora</a>
    </div>
</section>

<app-footer-two [class]="'bg-img-gym bg-size'"></app-footer-two>