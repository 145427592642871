<div class="modal-content">
    <div class="modal-body">
        <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h2 class="text-center">Cancella prenotazione</h2>

        <h4 class="text-justify" *ngIf="booking.status == BookStatus.CONFIRMED && prenotazionenoneliminabile">La tua prenotazione n.{{booking.id}} non pu&ograve; essere cancellata!</h4>
        <h4 class="text-justify" *ngIf="booking.status == BookStatus.CANCELLED_BY_USER">La tua prenotazione da {{booking.restname}} &egrave; stata <strong>ANNULLATA</strong>!</h4>
        <h4 class="text-justify" *ngIf="booking.status == BookStatus.CANCELLED_BY_RESTAURANT">La tua prenotazione da {{booking.restname}} risulta essere stata <strong>ANNULLATA</strong>!</h4>

        <h5 class="text-center">Riepilogo Prenotazione</h5>
        <table class="m-auto" style="width: 180px;">
            <tr>
                <td><strong>Ristorante:</strong></td>
                <td class="text-end">{{booking.restname}}</td>
            </tr>
            <tr>
                <td><strong>Giorno:</strong></td>
                <td class="text-end">{{booking.book_date}}</td>
            </tr>
            <tr>
                <td><strong>Ora:</strong></td>
                <td class="text-end">{{booking.book_hours}}</td>
            </tr>
            <tr>
                <td><strong>Coperti:</strong></td>
                <td class="text-end">{{booking.n_coperti}}</td>
            </tr>
            <tr *ngIf="booking.ordine?.total_products">
                <td><strong>Totale:</strong></td>
                <td class="text-end">{{booking.ordine?.total_products.toFixed(2)}} &euro;</td>
            </tr>
        </table>
        <ng-container *ngIf="booking.ordine">  
            <div class="bt-eit my-2"></div>
            <div style="width: 180px;" class="m-auto">
                <h5 class="text-center">Dettagli</h5>
                <div class="row d-block m-0" *ngFor="let recitem of booking.ordine.items" style="font-size: 0.9em;">
                    <ng-container *ngIf="!recitem.parent_id">
                        <strong>{{recitem.qty}} x {{recitem.name}}</strong>
                        <div *ngIf="!recitem.joins || !recitem.subJoins">
                            <div *ngFor="let subitem of recitem.subitems">
                            <span><em>&nbsp;&nbsp;&nbsp;&nbsp;- {{subitem.name}}</em></span>
                            </div>
                        </div>
                        <div *ngFor="let ordertmp of booking.ordine.items">
                            <div *ngIf="ordertmp.parent_id == recitem.id">
                                <span *ngIf="ordertmp.typecustom != 3" style="font-size: 0.875em;"> 
                                    &nbsp;&nbsp;&nbsp;&nbsp;<strong>Con aggiunta di:</strong> <em>&nbsp;&nbsp;&nbsp;&nbsp;- {{ordertmp.name}}</em>
                                </span> 
                                <span *ngIf="ordertmp.typecustom == 3" style="font-size: 0.875em;"> 
                                    &nbsp;&nbsp;&nbsp;&nbsp;<strong>Con selezione di:</strong> <em>&nbsp;&nbsp;&nbsp;&nbsp;- {{ordertmp.name}}</em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="recitem.joins && recitem.subJoins">
                            <div *ngFor="let subitem of recitem.subitems">
                                <span style="font-size: 0.875em;"> &nbsp;&nbsp;&nbsp;&nbsp;<strong>Opzione {{subitem[0].name_custom}}:</strong></span> <br>
                                <div *ngFor="let sub of subitem">
                                    <span style="font-size: 0.875em;"><em>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{sub.name}}</em></span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="booking.status == BookStatus.CONFIRMED; else cancelled">
            <h5>Note</h5>
            <p class="text-justify" *ngIf="booking.note.length">{{booking.note}}</p>
            <p *ngIf="!booking.note.length">-</p>
        </ng-container>
        <ng-template #cancelled>
            &Egrave; stata annullata da 
            <span *ngIf="booking.status == BookStatus.CANCELLED_BY_USER; else byrest">te</span> 
            <ng-template #byrest>Eatintime</ng-template>
            in data <em>{{booking.datamodifica}}</em> alle ore <em>{{booking.oramodifica}}</em>.
        </ng-template>
        <ng-container *ngIf="booking.status == BookStatus.CONFIRMED; else cancelled2">
            <ng-container *ngIf="prenotazionenoneliminabile; else motivazione">
                <h5>Attenzione!</h5>
                <p class="text-justify"> 
                    Non &egrave; pi&ugrave; possibile eliminare manualmente la prenotazione.<br> 
                    Contattare il servizio clienti Eatintime al Numero Verde <u>800-911282</u>
                </p>
            </ng-container>
            <ng-template #motivazione>
                <h5>Motivo della cancellazione:</h5>
                <textarea name="note" rows="3" [(ngModel)]="booking.reasoncancellation"></textarea>
            </ng-template>
        </ng-container>
        <ng-template #cancelled2>
            <p class="text-justify" *ngIf="booking.reasoncancellation">Motivazione: <em>{{booking.reasoncancellation}}</em></p>
        </ng-template>

        <div class="text-center mt-4" *ngIf="booking.status == BookStatus.CONFIRMED && !prenotazionenoneliminabile">
            <button type="button" (click)="cancelBooking()" class="btn btn-eit">Annulla prenotazione</button>            
        </div>
    </div>
</div>  
