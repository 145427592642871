<div *ngIf="!loader">
  <div class="img-wrapper">
    <!--<div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>-->
    <div class="front">


      <div class="lable-block">
        <!--<span class="lableEitRist" *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder">Pre-ordina</span>-->

        <span class="labelPromo"
          *ngIf="restaurant.has_promo || restaurant.has_free_delivery">{{restaurant.promos[0].text_short}}</span>

        <span class="labelClock">
          <span
            *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && !restaurant.preorder">Torno
            presto</span>
          <span
            *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder && !restaurant.ordinabiledomani">dalle
            {{restaurant.next_open}}</span>
          <span
            *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder && restaurant.ordinabiledomani">per
            domani</span>
          <span *ngIf="!restaurant.message_event && restaurant.day_closed">Torno presto</span>

          <span *ngIf="!restaurant.message_event && !(restaurant.nowclosed || restaurant.day_closed)">Aperto</span>
        </span>
        
        <span class="labelLogo"><img class="img-fluid w-auto" src="{{restaurant.media.realpathsm}}" /></span>
      </div>

      
      
      <!--
      <span class="labelClock">
        <span *ngIf="restaurant.message_event">{{restaurant.message_event}}</span>
        <span *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && !restaurant.preorder">Torno presto</span>
        <span *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder && !restaurant.ordinabiledomani">Pre-ordina dalle {{restaurant.next_open}}</span>
        <span *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder && restaurant.ordinabiledomani">Pre-ordina per domani</span>
        <span *ngIf="!restaurant.message_event && !(restaurant.nowclosed || restaurant.day_closed)">Aperto</span>
        <span *ngIf="!restaurant.message_event && restaurant.day_closed">Torno presto</span>
      </span>
      -->
    </div>


    <a [routerLink]="['/ristoranti/asporto/', restaurant.slug_takeaway]" (click)="goToRest(restaurant)">
      <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
        [lazyLoad]="ImageSrc ? ImageSrc : restaurant.mediaheader.realpath" class="img-fluid lazy-loading"
        alt="{{ restaurant.name }}" />
    </a>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/ristoranti/asporto/', restaurant.slug_takeaway]" (click)="goToRest(restaurant)">
        <img [src]="ImageSrc ? ImageSrc : restaurant.mediaheader.realpath" class="img-fluid lazy-loading"
          alt="{{ restaurant.name }}">
      </a>
      <span>
        <span *ngIf="restaurant.message_event">{{restaurant.message_event}}</span>
        <span *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && !restaurant.preorder">Torno presto</span>
        <span *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder && !restaurant.ordinabiledomani">Pre-ordina dalle {{restaurant.next_open}}</span>
        <span *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder && restaurant.ordinabiledomani">Pre-ordina per domani</span>
        <span *ngIf="!restaurant.message_event && !(restaurant.nowclosed || restaurant.day_closed)">Aperto</span>
        <span *ngIf="!restaurant.message_event && restaurant.day_closed">Torno presto</span>
      </span>
    </div>
    <!--<ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of restaurant.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul>-->
    <!--<div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>-->
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="restaurant.rating" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/ristoranti/asporto/', restaurant.slug_takeaway]" (click)="goToRest(restaurant)">
        <h4>{{ restaurant?.name | titlecase }}</h4>
      </a>
      <!--<a [routerLink]="['/shop/product/left/sidebar/', restaurant?.descr.replace(' ', '-')]">
        <h6><span *ngFor="let cucina of restaurant.cucine; index as i; last as isLast">{{cucina.name}} <span
          *ngIf="!isLast">- </span></span></h6>
      </a>-->
      <p><span *ngFor="let cucina of restaurant.cucine; index as i; last as isLast">{{cucina.name}} <span
            *ngIf="!isLast">- </span></span></p>
      <ul class="color-variant">
        <ng-container *ngFor="let payment of restaurant.payments">
          <img *ngIf="payment.id==payType.MONEY" float-left alt="Contanti" src="assets/images/contanti.png" />
          <img *ngIf="payment.id==payType.CARD" float-left alt="Carte di Credito" src="assets/images/carte.png" />
          <img *ngIf="payment.id==payType.PAYPAL" float-left alt="Paypal" src="assets/images/paypal.png" />
          <img *ngIf="payment.id==payType.SATISPAY" float-left alt="satispay" src="assets/images/satispay.png" />
          <img *ngIf="payment.id==payType.CREDIT" float-left alt="Eatpoints" src="assets/images/cashback.png" />
          <img *ngIf="payment.id==payType.INLIRE" float-left alt="InLire" src="assets/images/inlire.png" />
          <img *ngIf="payment.id==payType.TICKET" src="assets/images/edenred100.png"
            style="min-height: 18px; max-width: 20% !important; padding-top: 10px;" />
        </ng-container>
      </ul>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<!--<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>-->