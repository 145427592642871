<div *ngIf="!loader">
  <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
    [lazyLoad]="ImageSrc ? ImageSrc : promo.srcl" class="lazy-loading promo-logo" alt="{{ promo.descr }}" />
  <div class="img-wrapper">

    <div class="front promo-avatar" [ngStyle]="{'background-image': 'url('+promo.srch+')'}">

    </div>
    <div class="product-detail">
      <div>

        <a href="{{promo.link}}">
          <h6>{{promo.restname }}</h6>
        </a>
        <h4 class="eit-text-color-red">
          {{ promo.descr }}
        </h4>

      </div>
    </div>
  </div>
  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>