<div class="modal-content">
  <div class="modal-body text-center">
    <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="text-center">Seleziona un indirizzo</h3>
    <input ngx-google-places-autocomplete [options]='options' [(ngModel)]="query" #placesRef="ngx-places"
      (onAddressChange)="handleAddressChange($event)" class="form-control mb-2 mt-3" #geosearch
      placeholder="Inserisci il tuo indirizzo">
   <!--  <agm-map *ngIf="!loading" [latitude]="mapInfo.lat" [longitude]="mapInfo.lng" [zoom]="18" [disableDefaultUI]="false"
      [draggable]="false" (mapReady)="onMapReady($event)">
      <agm-marker [latitude]="mapInfo.lat" [longitude]="mapInfo.lng" [iconUrl]="mapInfo.icon" [markerDraggable]="false">
      </agm-marker>
    </agm-map> -->
    <div class="text-center mt-4">
      <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3 ">
          <button type="button" (click)="dismiss()" class="btn btn-outline btn-block">Annulla</button>
        </div>
        <div class="col-md-9 col-sm-9 col-xs-9 ">
          <button type="button" (click)="save()" class="btn btn-solid btn-block">Conferma</button>
        </div>
      </div>
    </div>
  </div>