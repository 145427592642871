import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { RESPONSE } from '../../../services/env.service';
import { BookService } from '../../../services/book-service';

@Component({
  selector: 'app-tablereview-box',
  templateUrl: './tablereview-box.component.html',
  styleUrls: ['./tablereview-box.component.scss']
})
export class TableReviewBoxComponent implements OnInit {

  restaurant_id: any = 0;
  queryPage: number = 0;
  reviews : Array<any> = [];
  initVal: boolean = true;
  canScroll: boolean = false;

  constructor(private bookService: BookService, private api: ApiService) {
    
    bookService.bookObs.subscribe(x => {
      this.restaurant_id = x.rest_id;
    });    

  }

  ngOnInit(): void {
   
  }

  init(){    

    this.bookService.bookObs.subscribe(x => {
      this.restaurant_id = x.rest_id;
      if(this.initVal){
        this.initVal = false;
        this.fillReviews();
      }

    }); 


    /*
    this.restService.restaurant.subscribe(x => {
      this.restaurant = x;
      if(this.initVal){
        this.initVal = false;
        this.fillReviews();
      }
    });    
    */
  }

  fillReviews(){
    this.api.postj_T("restaurant/reviews", {RESTID: this.restaurant_id, PAGE: this.queryPage}).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.reviews = this.reviews.concat(res[RESPONSE.JP_RESULT])
      }
    });
  }

  nextclick(){
    if(this.canScroll){
      this.queryPage++; 
      this.fillReviews(); 
    }
  }

}
