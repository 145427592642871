import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DB } from 'src/app/shared/services/env.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent {

  description: String;
  index = 0;
  id:any = null;
  dataBK: any;
  data: any = {};
  user: any = null;

  constructor(private api: ApiService, private route: ActivatedRoute, private router: Router, private toastService: ToastrService, private auth:AuthService) {

    this.id = this.route.snapshot.queryParams.id_product;

    this.auth.currentUser.subscribe(state => {
      this.user = state;
    });
    this.fill();
  }

 
  ngOnInit() {

  }

  fill(){
    this.api.getProduct(this.id).then(res => {
      this.data = res;
    })
  }

  goToPayment(){
    if(this.data.fitcoins == 0 || (this.user && (this.user.fitcoin + this.user.etikacoin) >= this.data.fitcoins)){
      if(!this.data.allfitcoin || this.user.etikalevel >= 10){
        localStorage.setItem(DB.PRODUCT, JSON.stringify(this.data))
        this.router.navigate(['/shop-checkpay']);
      }
      else this.toastService.warning("Per acquistare i prodotti 100% Fitcoins devi raggiungere il livello 10!") 
    }
    else this.toastService.warning("Il tuo saldo Fitcoins non è sufficiente!")
  }

}
