<!--div *ngIf="restaurant">
  Promo
</div-->

<div class="py-5">
  <h3 *ngIf="promos.length == 0; else promosContainer" class="text-center">Nessuna promozione attiva per questo ristorante.</h3>
  <ng-template #promosContainer>
    <h3 class="text-center mb-5">Promozioni in corso</h3>
    <form [formGroup]="searchForm" (ngSubmit)="applyFilter()" style="margin: 0 -15px">
      <input type="text" class="form-control" formControlName="LIKE" name="LIKE">
    </form>
    <div class="blog-detail-page corporate-box cb-i mt-4" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" (scrolled)="next()">
      <ul class="comment-section">
        <li *ngFor="let record of promos">
          <div class="media">
            <div class="media-body row">
              <div class="col-2 d-flex align-items-center">
                <img *ngIf="record.media_mid_id <= 0" src="assets/images/promo.png">
                <img *ngIf="record.media_mid_id > 0" [src]="record.media_mid.realpath">
              </div>
              <div class="col-10">
                <h4 class="font-weight-bold">{{record.text_short}}</h4>
                <h4>{{record.text_long}}</h4>
                <h4 *ngIf="record.orariopromostart !== '00:00' || record.orariopromoend !== '00:00'">
                  Valida dalle {{record.orariopromostart}} alle {{record.orariopromoend}}
                </h4>
              </div>
            </div>
          </div>
        </li>
      </ul>      
    </div>
  </ng-template>
</div>