import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-promo-box',
  templateUrl: './promo-box.component.html',
  styleUrls: ['./promo-box.component.scss']
})
export class PromoBoxComponent implements OnInit {

  @Input() promo: any;
  @Input() loader: boolean = false;
  
  public ImageSrc : string

  constructor() { }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

  

}
