<app-header-one [class]="'header-gym'"></app-header-one>
<section class="section-b-space p-0 mb-5">
    <div class="top-image position-relative" [ngStyle]="{'background-image': 'url(' + getHeader() + ')'}">
        <div class="overlay"></div>
    </div>
    <div class="container">
        <div class="desc p-5 d-flex flex-column align-items-center">
            <h1 class="title my-5 text-color-accent-fit fw-normal">{{data.name}}</h1>
            <div [innerHTML]="data.descr"></div>
        </div>
        <div class="shop-button text-center">
            <button (click)="onWebsiteFunc()" class="btn btn-solid">Acquista su {{data.name}}</button>
        </div>
    </div>
</section>
<div class="d-flex justify-content-center align-items-center text-center mobile-fix-option	d-block d-sm-block d-md-none mobile-footer-fixed">
    <a class="btn btn-solid mr-3 text-center ng-star-inserted mr-3 text-center" (click)="onWebsiteFunc()">Acquista su {{data.name}}</a>
</div>
<app-footer-two [class]="'bg-img-gym bg-size'"></app-footer-two>