import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterref' })
export class RefPipe implements PipeTransform {
    transform(items: any[], term: string): any {
        // I am unsure what id is here. did you mean title?
        if (!items) {
            return
        }
        return items.filter(item => item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1);
    }
}
