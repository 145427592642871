import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { RESPONSE, DB } from '../services/env.service';
//import { LoadingService } from '../services/loading-service'
import { AuthService } from '../services/auth.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EdenredService } from '../services/edenred-service';


@Injectable({
  providedIn: 'root'
})
export class CartbookService {

  private cartbookSubject: BehaviorSubject<any>;
  public cartbookObs: Observable<any>;


  constructor(private api: ApiService, private eService: EdenredService,
    private toastService: ToastrService, private router: Router) {

    this.cartbookSubject = new BehaviorSubject<any>(null);
    this.cartbookObs = this.cartbookSubject.asObservable();
  }

  public get cart(): any {
    return this.cartbookSubject.value;
  }



  public next(value: any) {
    localStorage.setItem(DB.CARTBOOK, JSON.stringify(value));
    this.cartbookSubject.next(value);
  }

  resetCart() {
    this.cartbookSubject.next([]);
  }

  loadCart(order_id) {
    // this.loadingService.show();
    var data: any = {};
    data.rest_id = 0;
    data.order_id = order_id;

    return this.api.postj_T("cart/updatecart", data, "").subscribe(data => {
      if (
        data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
        data[RESPONSE.JP_RESULT]
      ) {

        this.next(data[RESPONSE.JP_RESULT]);

      }
      //this.loadingService.hide();
    }, (err) => {
      console.log(err);
      //this.loadingService.hide();
    })
  }

  addToCart(id_item, qty, notes, returndata, startdata) {
    console.log("add to cart cartbook service")
    var current = this.cart;
    var dataCall: any = {};
    dataCall.orderid = current.order_id;
    dataCall.productid = id_item;
    dataCall.returndata = returndata;
    dataCall.qty = qty;
    dataCall.notes = "";
    if (notes) {
      dataCall.notes = notes;
    }
    if (startdata) {
      dataCall.result = startdata;
    }

    //this.loadingService.show();
    this.api.postj_T("cart/addmtoc", dataCall, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {
          this.next(res[RESPONSE.JP_RESULT]);

        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
          //this.showAlert('Attenzione', res[this.config.JP_MSG]);
        } else {
          console.log(res);
          //this.showAlert('Attenzione', "Impossibile inviare la richiesta!");
        }
        //this.loadingService.hide();
      },
      err => {
        console.log(err);
        //this.loadingService.hide();
      }
    );
  }

  addToCartSingle(id_item, qty, notes, returndata) {

    console.log("add to cart Single cartbook service");

    var current = this.cart;
    var dataCall: any = {}; 
    dataCall.orderid = current.order_id;
    dataCall.productid = id_item;
    dataCall.returndata = returndata;
    dataCall.qty = qty;
    dataCall.notes = notes;
    if (notes) {
      dataCall.notes = notes;
    }

    //this.loadingService.show();
    this.api.postj_T("cart/addptoc", dataCall, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {
          this.next(res[RESPONSE.JP_RESULT]);

        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
          //this.showAlert('Attenzione', res[this.config.JP_MSG]);
        } else {
          console.log(res);
          //this.showAlert('Attenzione', "Impossibile inviare la richiesta!");
        }
        //this.loadingService.hide();
      },
      err => {
        console.log(err);
        //this.loadingService.hide();
      }
    );
  }

  removeToCartP(id_item, qty, returndata) {

    console.log("remove to cart Single cartbook service");

    var current = this.cart;
    var dataCall: any = {};
    dataCall.orderid = current.order_id;
    dataCall.productid = id_item;
    dataCall.returndata = returndata;
    dataCall.qty = qty;
    //this.loadingService.show();
    this.api.postj_T("cart/removemtoc", dataCall, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {
          this.next(res[RESPONSE.JP_RESULT]);

        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
          //this.showAlert('Attenzione', res[this.config.JP_MSG]);
        } else {
          console.log(res);
          //this.showAlert('Attenzione', "Impossibile inviare la richiesta!");
        }
        // this.loadingService.hide();
      },
      err => {
        console.log(err);
        //this.loadingService.hide();
      }
    );

  }



  finalize(data) {
    return this.api.postj_T("order/finalize", data, "").subscribe(
      res => {

        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
          this.loadCart(this.cart.order_id);
          //this.router.navigate(['/check'], { queryParams: {} });
          var id_book = JSON.parse(localStorage.getItem(DB.BOOK_ID));
          this.router.navigate(['/tablescheckout/tablessuccess/' + id_book], { queryParams: { } });
        }else
        {
          this.toastService.error("Attenzione! Pagamento NON riuscito!")
        }
      },
      err => {
        console.log(err);
        this.toastService.error("Attenzione! Pagamento NON riuscito!")
      }
    );
  }

  finalizeEdenred(data, vouchers) {

    if (vouchers && vouchers.vouchers && vouchers.vouchers.length > 0) {
      this.eService.eAuthorize(data.token, data.orderid, vouchers.vouchers[0]).then(
        res => {
          console.log(res);
          this.finalize(data);

        },
        err => {
          //   this.loadingService.hide();
          console.log(err);
          var msg = "Attenzione! Pagamaneto Non autorizzato!";
          this.toastService.error(msg);
        }
      );
    } else {
      //this.loadingService.hide();
      var msg = "Attenzione! Pagamaneto NON è stato possibile effettuare il pagamento!";
      this.toastService.error(msg);
    }

  }
}
