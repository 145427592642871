<div *ngIf="!loader1">
  <div class="img-wrapper">
    <!--<div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>-->
    <div class="front">
      <div class="lable-block">
        <a [routerLink]="['/restaurant/tables/', restaurant1.id]" (click)="goToRest(restaurant1)">
          <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
            [lazyLoad]="ImageSrc ? ImageSrc : appInfo.ASSETS_URL + restaurant1.mediaheader.filename" class="img-fluid lazy-loading"
            alt="{{ restaurant1.name }}" />
        </a>
        <span>
          <span *ngIf="restaurant1.message_event">{{restaurant1.message_event}}</span>
          <!--
          <span *ngIf="!restaurant1.message_event && restaurant1.nowclosed && !restaurant1.day_closed && !restaurant1.preorder">Torno presto</span>
          <span *ngIf="!restaurant1.message_event && restaurant1.nowclosed && !restaurant1.day_closed && restaurant1.preorder && !restaurant1.ordinabiledomani">Pre-ordina dalle {{restaurant.next_open}}</span>
          <span *ngIf="!restaurant1.message_event && restaurant1.nowclosed && !restaurant1.day_closed && restaurant1.preorder && restaurant1.ordinabiledomani">Pre-ordina per domani</span>
          -->
          <span *ngIf="!restaurant1.message_event && !(restaurant1.nowclosed || restaurant1.day_closed)">Aperto</span>
          <span *ngIf="!restaurant1.message_event && restaurant1.day_closed">Torno presto</span>
        </span>

        <!--span class="labelLogo"><img class="img-fluid w-auto" src="{{restaurant1.media.realpathsm}}" /></span-->
      </div>
    </div>
    <div class="back" *ngIf="onHowerChangeImage1">
      <a [routerLink]="['/restaurant/tables/', restaurant1.id]" (click)="goToRest(restaurant1)">
        <img [src]="ImageSrc ? ImageSrc : appInfo.ASSETS_URL + restaurant1.mediaheader.filename" class="img-fluid lazy-loading"
          alt="{{ restaurant1.name }}">
      </a>
    </div>
    
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="restaurant1.ratingtables" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/restaurant/tables/', restaurant1.id]" (click)="goToRest(restaurant1)">
        <h4>{{ restaurant1?.name | titlecase }}</h4>
      </a>      
      <p><span *ngFor="let cucina of restaurant1.cucine; index as i; last as isLast">{{cucina.name}} <span
            *ngIf="!isLast">- </span></span></p>
      
      <ul class="color-variant">
        <ng-container *ngFor="let payment of restaurant1.payments">
          <img *ngIf="payment.id==payType.MONEY" float-left alt="Contanti" src="assets/images/contante.png" />
          <img *ngIf="payment.id==payType.CARD" float-left alt="Carte di Credito" src="assets/images/carta.png" />
          <img *ngIf="payment.id==payType.PAYPAL" float-left alt="Paypal" src="assets/images/paypal.png" />
          <img *ngIf="payment.id==payType.SATISPAY" float-left alt="satispay" src="assets/images/satispay.png" />
          <img *ngIf="payment.id==payType.CREDIT" float-left alt="Punti fedeltà" src="assets/images/credito.png" />
          <img *ngIf="payment.id==payType.INLIRE" float-left alt="InLire" src="assets/images/inlire.png" />
          <img *ngIf="payment.id==payType.TICKET" src="assets/images/edenred100.png"
            style="min-height: 18px; max-width: 20% !important; padding-top: 10px;" />
        </ng-container>
      </ul>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader1"></app-skeleton-product-box>
<!--<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>-->