<!--div *ngIf="restaurant">
  Review
</div-->

<div class="py-5">
  <div class="box-account box-info blog-detail-page review-page" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" (scrolled)="nextclick()">
    <ul class="comment-section">
      <li *ngFor="let review of reviews">
        <div class="media">
          <div class="media-body row">
            <div class="col-md-7">
              <h4>{{review.namerestaurant}}</h4>
              <p>
                <strong>
                  Servizio: 
                  <span *ngIf="review.fortables; else dom">Tavoli</span>
                  <ng-template #dom>Domicilio</ng-template>
                </strong>
              </p>
              <p *ngIf="review.inmoderazione">[ Recensione in moderazione ]</p>
              <p *ngIf="!review.inmoderazione">
                {{review.review}}<br>
                <em *ngIf="review.restaurantanswer" style="font-style: italic">&nbsp; - Risposta ristorante:
                  {{review.restaurantanswer}}</em>
              </p>
            </div>
            <div class="col-md-5" *ngIf="!review.inmoderazione">
              <div class="row" *ngIf="!review.fortables">
                <div class="col-5 d-flex align-items-center font-weight-bold">Qualit&agrave; del cibo</div>
                <div class="col-7">
                  <bar-rating [rate]="review.ratingquality" [readOnly]="true"></bar-rating>
                </div>
              </div>
              <div class="row">
                <div class="col-5 d-flex align-items-center font-weight-bold">Servizio e cortesia</div>
                <div class="col-7">
                  <bar-rating [rate]="review.ratingservice" [readOnly]="true"></bar-rating>
                </div>
              </div>
              <div class="row" *ngIf="!review.fortables">
                <div class="col-5 d-flex align-items-center font-weight-bold">Puntualit&agrave;</div>
                <div class="col-7">
                  <bar-rating [rate]="review.ratingpunctuality" [readOnly]="true"></bar-rating>
                </div>
              </div>
              <div class="row" *ngIf="review.fortables">
                <div class="col-5 d-flex align-items-center font-weight-bold">Cucina</div>
                <div class="col-7">
                  <bar-rating [rate]="review.ratingcucina" [readOnly]="true"></bar-rating>
                </div>
              </div>
              <div class="row" *ngIf="review.fortables">
                <div class="col-5 d-flex align-items-center font-weight-bold">Qualit&agrave; / Prezzo</div>
                <div class="col-7">
                  <bar-rating [rate]="review.ratingqualityprice" [readOnly]="true"></bar-rating>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>