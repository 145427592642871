import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RESPONSE } from '../../../../shared/services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-extrainfo',
  templateUrl: './extrainfo.component.html',
  styleUrls: ['./extrainfo.component.scss']
})


export class ExtrainfoComponent implements OnInit {

  @Input() order: any = {}  
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  
  ordermin: any = 0;  
  constructor(private modalService: NgbModal) {
    
  }

  ngOnInit(): void {
  }

  dismiss(result?: any){
    this.onDismiss.emit(result ? result : {status : RESPONSE.JPS_ERROR})
    this.modalService.dismissAll()
  }

  @Input() init(){

    console.log("init extra info sovrapprezzo");
    
    this.ordermin = this.order.ordineminimo;    

  }

}
