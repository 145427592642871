import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RESPONSE, EitUrl } from 'src/app/shared/services/env.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-promo-corporate',
  templateUrl: './promo-corporate.component.html',
  styleUrls: ['./promo-corporate.component.scss']
})
export class PromoCorporateComponent implements OnInit {

  @Input() partner: any = {};
  path: string = ""
  promos: Array<any> = []
  restaurants: Array<any> = []
  searchForm: FormGroup;
  searchFormR: FormGroup;

  constructor(private modalService: NgbModal, private api: ApiService) { 
    this.searchForm = new FormGroup({
      LIKE: new FormControl(""),
      PAGE: new FormControl(0),
      PAGESIZE: new FormControl(10),
      PID: new FormControl(0)
    })

    this.searchFormR = new FormGroup({
      LIKE: new FormControl(""),
      pId: new FormControl(0)
    })
  }

  ngOnInit(): void {
  }

  @Input() init(){
    this.path = EitUrl.ASSETS_URL + this.partner.media?.filename + (this.partner.media_id && this.partner.media?.path.length > 0 ? '.' + this.partner.media?.ext : "")
    this.searchForm.patchValue({ID: this.partner.id_partner})
    this.searchFormR.patchValue({pId: this.partner.id_partner})
    this.fill()
    this.fillRestaurants()
  }

  dismiss(){
    this.modalService.dismissAll()
  }

  fill(){
    this.api.postj_T("user/settings/getpromos", this.searchForm.value).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.promos = this.promos.concat(res[RESPONSE.JP_RESULT])
      }
    });
  }

  fillRestaurants(){
    this.api.postj_T("user/settings/getconvrestaurants", this.searchFormR.value).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.restaurants = res[RESPONSE.JP_RESULT]
      }
    });
  }

  applyFilter(type: number){
    switch(type){
      case 0: 
        this.searchForm.patchValue({PAGE: 0});
        this.promos = []
        this.fill();
        break;
      case 1: 
        this.searchFormR.patchValue({PAGE: 0});
        this.fillRestaurants();
        break;
    }
  }

  next(){
    this.searchForm.patchValue({PAGE: this.searchForm.controls.PAGE.value + 1});
    this.fill()
  }

  goToSite(){
    window.open(this.partner.site, "_blank");
  }

  goToRest(rest: any, id: number){
    window.open(this.restaurantUrl(id, rest.permalink, rest.slug), "_blank");
  }

  restaurantUrl (id: number, permalink: string, slug: string) {
		return slug?.length ? "/ristoranti/" + slug : (permalink?.length ? "/" + permalink : "/restaurant/view?pid=" + id);
  }
  
  getPath(partner: any){
    return EitUrl.ASSETS_URL + partner.media?.filename + (partner.media_id && partner.media?.path.length > 0 ? '.' + partner.media?.ext : "")
  }

}
