import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { ConfigApp } from 'src/app/shared/services/config-app';

@Component({
  selector: 'app-gainers-list',
  templateUrl: './gainers-list.component.html',
  styleUrls: ['./gainers-list.component.scss']
})
export class GainersListComponent {

  public _gainer = Array();

  constructor(public config: ConfigApp,
    private route: ActivatedRoute, private router: Router,
    public api: ApiService) { 
      this.gainer();
  }

  gainer() {
    this.api.loadGainers(-2, 1).then((res: Array<any>) => {
      console.log("gainers t4", res)
      this._gainer = res;
      /* if (this._gainer && this._gainer.length > 0 && this._gainer.length < 3) {
        this._gainer = this._gainer.concat(res);
        this._gainer = this._gainer.concat(res);
        this._gainer = this._gainer.concat(res);
        this._gainer = this._gainer.concat(res);
        this._gainer = this._gainer.concat(res);
        this._gainer = this._gainer.concat(res);
        this._gainer = this._gainer.concat(res);
        this._gainer = this._gainer.concat(res);
      } */
    })
  }

  getHeader(item: any) : string{
    return item.media_header ? item.media_header.realpath : ""
  }

  onGoToGainer(id: number) {
    this.router.navigate(['/gainer'], { queryParams: { id_gainer: id } });
  }


}
