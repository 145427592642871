import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RESPONSE } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeOrder, ET_TipoOraConsegna } from '../../../services/config-app';
import { ApiService } from '../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ConstantPool } from '@angular/compiler';
import { DB } from '../../../services/env.service';


import * as momentTz from  'moment-timezone';

@Component({
  selector: 'app-datetime-modal',
  templateUrl: './datetime-modal.component.html',
  styleUrls: ['./datetime-modal.component.scss']
})
export class DatetimeModalComponent implements OnInit {
  
  @Input() order: any = {}    
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  public typeOrder: any = TypeOrder;
  elencoorari: Array<any> = [];
  orarioscelto: any;
  giornoconsegna: any;
  isPreorder : boolean;
  excludetoday : boolean;
  weeklyreserv : boolean;
  monthlyreserv : boolean;
  soloingiornata : boolean;
  dataminima: any = "2020-10-01";
  datamassima: any = "2021-10-30";
  restaurant: any;
  bsValue:any= "2020-10-16";
  //bsConfig: any;
  minDate: any = "2020-10-01";
  maxDate: any = "2021-10-30";
  


  constructor(private modalService: NgbModal, private api: ApiService, private toastService: ToastrService) { 
    //this.localeService.use('it');

    this.bsValue = new Date();
  }

  ngOnInit(): void {
    
  }

  @Input() init(){

    console.log("init datetime");
    this.giornoconsegna = "2020-10-14";

    let newDate = momentTz.tz(this.order.bookingdate, "Europe/Rome").format('YYYY-MM-DD');
    this.bsValue = new Date(newDate);

    this.calcStartDay();
    //non faccio qui la filltimes per evitare di lanciarla due volte e generare errore di init() (viene lanciata dalla OnChangeDate())
    this.fillTimes(this.giornoconsegna);


    /*
    let arr = this.order.date_time.split(" ");
    let fascia = this.order.fasciaoraria;
    
    let arrDate = arr[0].split("-");
    let arrHour = arr[1].split(":");
    */
    //this.prenotazionenoneliminabile = new Date(arrDate[0], parseInt(arrDate[1]) - 1, arrDate[2], parseInt(arrHour[0]) - 2, arrHour[1], arrHour[2]) < new Date();
  }

  dismiss(status?: string){
    console.log("dismiss datetime: " + this.orarioscelto);
    this.onDismiss.emit({status: (status ? status : RESPONSE.JPS_ERROR)});
    this.modalService.dismissAll();
  }

  onChangeDate(event)
  {
    console.log(event); // --> will contains $event.day, $event.month and $event.year 

    let giorno = event.getDate().toString();
    let mese = (event.getMonth()+1).toString();
    let anno = event.getFullYear().toString();

    //formato yyyy-mm-gg

    if(parseInt(giorno)<10)
    {
      giorno = '0'+giorno;
    }
    if(parseInt(mese)<10)
    {
      mese = '0'+mese;
    }
    
    this.giornoconsegna = anno + "-" + mese + "-" + giorno;
    this.fillTimes(this.giornoconsegna);
  }

  updateorario(){
    console.log("dismiss datetime: " + this.orarioscelto);
    this.onDismiss.emit({status: RESPONSE.JPS_OK, orario: this.orarioscelto, giornoconsegna: this.giornoconsegna});
    this.modalService.dismissAll();

    

/*
    let params = {ID: this.order.id, AUX_FIELD_1: this.order.order_date};
    this.api.postj_T("order/saveorderdate", params, "").subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {        	 
        this.toastService.success("Orario aggiornato!")
        this.dismiss(RESPONSE.JPS_OK);
      }
      else if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR && res[RESPONSE.JP_MSG]) {
        res[RESPONSE.JP_MSG].forEach((msg: any) => {
          this.toastService.error(msg)
        });
      }
      else {
        console.log(res);
      }
    }, (err) => {
      console.log(err);
    })

  */


  }

  calcStartDay()
  {
    this.dataminima =  "2020-02-27";
    this.datamassima =  "2020-03-05";
    this.giornoconsegna = "2020-02-26";

    this.isPreorder = false;
    this.excludetoday = false;

    this.restaurant = JSON.parse(localStorage.getItem(DB.ACTIVITY_BASE));
    if (this.restaurant != undefined && this.restaurant != null) 
    {
      this.isPreorder = (!this.restaurant.message_event && this.restaurant.nowclosed && !this.restaurant.day_closed && this.restaurant.preorder);   
      this.excludetoday = this.restaurant.excludetoday;
      this.weeklyreserv = this.restaurant.weeklyreserv;
      this.monthlyreserv = this.restaurant.monthlyreserv;
      
      console.log("Is excludetoday: " + this.excludetoday);
      console.log("Is weeklyreserv: " + this.weeklyreserv);
      console.log("Is monthlyreserv: " + this.monthlyreserv);
         
      //nascondo o disabilito il calendario
      this.soloingiornata = false; //(!this.restData.weeklyreserv && !this.restData.monthlyreserv);

      let adesso = new Date();

      //let currdate = new Date(adesso.getFullYear(), adesso.getMonth(),  adesso.getDay());

      let giorno = adesso.getDate().toString();
      let mese = (adesso.getMonth()+1).toString();
      let anno = adesso.getFullYear().toString();

      if (this.excludetoday && (this.weeklyreserv || this.monthlyreserv)){            
        let domani = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        giorno = domani.getDate().toString();
        mese = (domani.getMonth()+1).toString();
        anno = domani.getFullYear().toString();
      }

      //formato yyyy-mm-gg

      if(parseInt(giorno)<10)
      {
        giorno = '0'+giorno;
      }
      if(parseInt(mese)<10)
      {
        mese = '0'+mese;
      }

      this.dataminima = anno + "-" + mese + "-" + giorno;
      this.giornoconsegna = this.dataminima;

      let enddate = new Date();
      
      if (this.restaurant.weeklyreserv){
        enddate = new Date(adesso.getFullYear(), adesso.getMonth(),  adesso.getDate()+7);              
      }else if(this.restaurant.monthlyreserv){
        enddate = new Date(adesso.getFullYear(), adesso.getMonth(),  adesso.getDate()+30);
      }else{
        this.soloingiornata = true;
      }

      giorno = enddate.getDate().toString();
      mese = (enddate.getMonth()+1).toString();
      anno = enddate.getFullYear().toString();

      //formato yyyy-mm-gg

      if(parseInt(giorno)<10)
      {
        giorno = '0'+giorno;
      }
      if(parseInt(mese)<10)
      {
        mese = '0'+mese;
      }

      this.datamassima = anno + "-" + mese + "-" + giorno;


      this.minDate = new Date(this.dataminima);
      this.maxDate = new Date(this.datamassima);

    } //fine restaurant

    if (this.order.tipooraconsegna == ET_TipoOraConsegna.FASCIAORARIA) {
      this.orarioscelto = this.order.fasciaoraria;
    }else{
      this.orarioscelto = this.order.order_date;
    }

    if (this.order.bookingdate != null){

      //let odday = new Date(this.order.bookingdate);
      let odday = momentTz.tz(this.order.bookingdate, "Europe/Rome").format('YYYY-MM-DD');
      let oddayArray = odday.toString().split("-");
      let giorno = oddayArray[2];
      let mese = oddayArray[1];
      let anno = oddayArray[0];

      //formato yyyy-mm-gg

      if(parseInt(giorno)<10)
      {
        giorno = '0'+giorno;
      }
      if(parseInt(mese)<10)
      {
        mese = '0'+mese;
      }

      let bookd = momentTz.tz(this.order.bookingdate, "Europe/Rome").format('YYYY-MM-DD');
      var x = new Date(bookd);
      var y = new Date(this.giornoconsegna);
      if (!this.soloingiornata){
        if ( x >= y ){ 
          //leggo e setto il valore memorizzato solo se la data è compatibile
          this.giornoconsegna = bookd;
        }
      }


    }

  }

  fillTimes(daytocheck: any)
  {

    var data: any = {};
    data.restid = this.order.restaurant_id;
    data.isfortakeaway = this.order.type_order == TypeOrder.EIT_ASPORTO || this.order.type_order == TypeOrder.REST_ASPORTO;
    data.dayorder = daytocheck;
    console.log("Dayorder: "+data.dayorder);

    this.api.postj_T("restaurant/elencoorari", data, "").subscribe(
      res => {
        console.log(res);
        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
          this.elencoorari = res[RESPONSE.JP_RESULT];

          //Forzo la scelta dell'orario
          console.log( this.order.order_date );
          console.log( this.elencoorari[0] );

          //if (this.isPreorder){
            if (this.order.order_date == "FAST" || this.order.order_date == null){
              this.orarioscelto = this.elencoorari[0].value;
            }else{
              if (this.order.tipooraconsegna == ET_TipoOraConsegna.FASCIAORARIA) {
                this.orarioscelto = this.order.fasciaoraria;
              }else{
                this.orarioscelto = this.order.order_date;
              }              
            }
          //}
        }
      },
      err => {
        console.log(err);
      }
    );

    
  }

  updateSelection(index){
    this.orarioscelto == this.elencoorari[index].value;
  }


  isChecked(orario: any){
    
    if (orario.value == this.orarioscelto) return true;
    return false;

    /*
    for(let i = 0; i < this.elencoorari.length; i++) {
      if (i == index) return true;
    }
    return false;
    */
  }

}
