<div class="modal-content">
    <div class="modal-body">
        <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="text-center">Orario di <span *ngIf="order?.type_order==typeOrder.REST_DOMICILIO">consegna</span><span *ngIf="order?.type_order==typeOrder.REST_ASPORTO">ritiro</span></h3>

        <h5 class="text-center">Scegli l'orario di <span *ngIf="order?.type_order==typeOrder.REST_DOMICILIO">consegna</span><span *ngIf="order?.type_order==typeOrder.REST_ASPORTO">ritiro</span></h5>

        <div class="row text-center">
            <div class="col-6" [ngClass]="{'d-none': soloingiornata}">
              <!--  <ng-container class="m-auto">
                    
                         <input 
                            style="border-radius: 12px; height: 35px;"    
                            type="text"
                            placeholder="Datepicker"
                            class="form-control"
                            bsDatepicker
                            [bsConfig]="{ isAnimated: true, containerClass: 'theme-default', dateInputFormat: 'DD-MM-YYYY' }"
                            (bsValueChange)="onChangeDate($event)"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            [bsValue]="bsValue"
                            [isDisabled]="soloingiornata"
                            > 
                </ng-container>-->

            </div>
            <div class="col">            
                <ng-container class="m-auto" > <!--class="m-auto style="width: 180px !important;"" [(ngModel)]="order.order_date" -->
                    <select [(ngModel)]="orarioscelto" placeholder="Seleziona un orario" > <!-- (ngModelChange)="select(ind, i)" -->
                        <option *ngFor="let it of elencoorari" [value]="it.value">{{it.label}}</option>
                    </select>
                </ng-container>
            </div>

        </div>

        <div class="text-center mt-4" >
            <button type="button" (click)="updateorario()" class="btn btn-solid">Aggiorna orario</button>            
        </div>
    </div>
</div>  
