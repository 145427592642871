<owl-carousel-o [options]="configSlider" class="home-slider">
    <ng-container *ngFor="let slider of items">
        <ng-template carouselSlide>
            <div class="home">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="slider-contain d-flex flex-column">
                                <div class="screen" (click)="onGoToGainer(slider.id)">
                                    <img src="{{slider.media.realpath}}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>