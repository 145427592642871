import { Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { RESPONSE } from '../../../services/env.service';
// import { AgmMap } from '@agm/core';
import { BookService } from '../../../services/book-service';

declare const google: any;

@Component({
  selector: 'app-tableinfo-box',
  templateUrl: './tableinfo-box.component.html',
  styleUrls: ['./tableinfo-box.component.scss']
})
export class TableInfoBoxComponent implements OnInit {

  restaurant_id: any = 0;
  // @ViewChild(AgmMap) agmMap: AgmMap;
  restInfo: any = {}
  days: any = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];
  mapInfo: any = {icon: {url: "assets/images/cappellino-eit-grigio.png", scaledSize: new google.maps.Size(61, 60)}}

  constructor(private bookService: BookService, private api: ApiService, private ref: ChangeDetectorRef) {    
    this.bookService.bookObs.subscribe(x => {
      this.restaurant_id = x.rest_id;
    });      
  }

  ngOnInit(): void {
   
  }

  init(){    
    this.bookService.bookObs.subscribe(x => {
      this.restaurant_id = x.rest_id;
      this.fill();
    });
  }

  fill(){
    console.log("fill inforest");
    this.api.postj_T("restaurant/getinforest", {RESTID: this.restaurant_id}).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.restInfo = res[RESPONSE.JP_RESULT]
        setTimeout(()=> {
          this.ref.detectChanges();
          // this.agmMap.disableDefaultUI = false;
          // this.agmMap._setControls();
          // this.agmMap.triggerResize(true);
        }, 100)
      }
    });
  }

  onMapReady(map: any){
    console.log("On map ready");
    map.setOptions({zoomControl: false, mapTypeControl: false, streetViewControl: false, fullscreenControl: false});
  }
}
