<app-header-one [class]="'header-gym'"></app-header-one>

<section class="shop-checkpay-page section-b-space">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <form [formGroup]="accountForm" class="login-form" (ngSubmit)="checkAddr()">
                    <h3 class="mb-5 px-3">Indirizzo di spedizione</h3>
                    <div class="box-content mt-5 px-4">
                        <div class="row">
                            <div class="col-lg-12 form-group">
                                <input type="text" class="form-control"
                                    [ngClass]="{'errInput': inputHasError('address', 'required')}" placeholder="Indirizzo"
                                    formControlName="address" name="address">
                            </div>
                            <div class="col-lg-12 ps-5">
                                <div class="err" *ngIf="inputHasError('address', 'required')">La Via &egrave; richiesta.</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 form-group">
                                <input type="text" class="form-control"
                                    [ngClass]="{'errInput': inputHasError('num', 'required')}" placeholder="Numero Civico"
                                    formControlName="num" name="num">
                            </div>
                            <div class="col-lg-12 ps-5">
                                <div class="err" *ngIf="inputHasError('num', 'required')">Il Numero Civico &egrave; richiesto.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 form-group">
                                <input type="text" class="form-control"
                                    [ngClass]="{'errInput': inputHasError('townstr', 'required')}" placeholder="Città"
                                    formControlName="townstr" name="townstr">
                            </div>
                            <div class="col-lg-12 ps-5">
                                <div class="err" *ngIf="inputHasError('townstr', 'required')">La Citt&agrave; &egrave;
                                    richiesta.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 form-group">
                                <input type="text" class="form-control"
                                    [ngClass]="{'errInput': inputHasError('zip', 'required')}" placeholder="CAP"
                                    formControlName="zip" name="zip">
                            </div>
                            <div class="col-lg-12 ps-5">
                                <div class="err" *ngIf="inputHasError('zip', 'required')">Il CAP &egrave; richiesto.</div>
                                <div class="err" *ngIf="inputHasError('zip', 'minlength') || inputHasError('zip', 'maxlength')">
                                    Il CAP deve essere di 5 caratteri
                                </div>
                                <div class="err" *ngIf="inputHasError('zip', 'pattern')">Il CAP deve contenere solo numeri</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 form-group">
                                <input type="text" class="form-control"
                                    [ngClass]="{'errInput': inputHasError('citofono', 'required')}" placeholder="Citofono"
                                    formControlName="citofono" name="citofono">
                            </div>
                            <div class="col-lg-12 ps-5">
                                <div class="err" *ngIf="inputHasError('citofono', 'required')">Il Citofono &egrave; richiesto.
                                </div>
                            </div>
                            <!-- <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="Interno o scala (opzionale)" formControlName="interno" name="interno">
                    </div> -->
                        </div>
                        <div class="row">
                            <div class="col-lg-12 form-group">
                                <input type="phone" class="form-control"
                                    [ngClass]="{'errInput': inputHasError('phone', 'required')}" placeholder="Telefono"
                                    formControlName="phone" name="phone">
                            </div>
                            <div class="col-lg-12 ps-5">
                                <div class="err" *ngIf="inputHasError('phone', 'required')">
                                    Il Numero di telefono &egrave; richiesto.
                                </div>
                                <div class="err" *ngIf="inputHasError('phone', 'pattern')">
                                    Il Numero di telefono deve contenere solo numeri.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <input class="form-control" type="text" name="note" formControlName="note" value="" autocomplete="off" placeholder="Eventuali note per il corriere">
                            </div>
                        </div>
                    </div>
                    <div class="box-content text-center mt-4">
                        <button class="btn btn-solid" type="submit">Vai al pagamento</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<app-footer-two [class]="'bg-img-gym bg-size'"></app-footer-two>