import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../services/app.service';
import { AppInfo } from '../../services/env.service';
import { NavService, Menu } from '../../services/nav.service';

@Component({
  selector: 'app-footer-two',
  templateUrl: './footer-two.component.html',
  styleUrls: ['./footer-two.component.scss']
})
export class FooterTwoComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = ''; // default Logo
  @Input() mainFooter: boolean = true; // Default true 
  @Input() subFooter: boolean = true; // Default false 
  year: number
  accept: boolean = false;
  
  appData: any = null;
  appInfo: any = AppInfo;
  public today: number = Date.now();
  public menuItems: Menu[];
  public legalNoticeMenuItems: Menu[];
  
  constructor(private appService: AppService, private viewScroller: ViewportScroller, private navServices: NavService) {  
    this.navServices.footerMenuItems.subscribe(menuItems => this.menuItems = menuItems );
    this.navServices.legalNoticeMenuItems.subscribe(menuItems => this.legalNoticeMenuItems = menuItems );
    this.appService.appObs.subscribe(x => { 
      this.appData = x;
      this.themeLogo = this.appService.getLogo(x);
    });
    let d = new Date()
    this.year = d.getFullYear()
  }

  ngOnInit(): void {
  }

  tapToTop() {
  	this.viewScroller.scrollToPosition([0, 0]);
  }

  goToAccount(){
    
  }
}
