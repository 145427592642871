import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})




export class EnvService {

  constructor() { }
}
export enum EitEnv {

  baseUrl = "https://etikapp.it/",
  //baseUrl = "http://localhost:4000", //local
  //baseUrl = "https://t2020.eatintime.it",  //test

  /* per cmpilare in release fare replace con il valore corretto baserurl */
}

export enum EitUrl {
  apiUrl = "https://appit.etikapp.it/mobilev1",
  //apiUrl = "https://api56test.esoft.it/vtest/apimobile/web/v1",
  url = "https://www.eatintime.it",
  urlCardOk = "it.etikapp.etikapp://ordercheck",
  urlCardKo = "https://api56.esoft.it/eatintime/cardpayko",
  urlCardSync =  "https://api5621.esoft.it/v3/apimobile/web/v1/order/cardsync",
  urlCardSyncEdenred =  "https://api5621.esoft.it/v3/apimobile/web/v1/order/ecardsync",
  urlTablesCardOk = "",
  urlTablesCardKo = "",  
  EIT_DOMAIN = "eatintime.it",
  ASSETS_URL = "https://assets.eatintime.it/eatintime/img/media/"
}

export enum Paypal {
  keyRel = "Ad27XU85tEhawHSm1wEM1KV3ybTYXz34t4kpr8YS5fLf1baoDiGGMxp3q3VVBWAgio8pHhBO3vuvDyXY",
  keySand = "AV5dnyh3y5DeM-5xvZUPycFxewXT4LYHpajvPgQVk0N2qF0U_J8IRGAq6rDdPCMzVSoYAZz4BwveLMKZ", 
}

export enum Satispay {
  urlRel = "https://authservices.satispay.com",
  urlSand = "https://staging.authservices.satispay.com",
  urlReturn = "http://localhost/satispayreturn",
  urlEReturn = "http://localhost/satispayreturn",
  urlCallback = "https://app5621.eatintime.it/v1/apimobile/web/v1/order/satispay?charge_id={uuid}",
  urlCallbackEdenred = "https://app5621.eatintime.it/v1/apimobile/web/v1/order/esatispay?charge_id={uuid}",
  keyRel = "osh_cu673pcv7pvpmt351j564feo7qco2044577grpfadm4p1gigctaa2iu0di47s8inghjphsefvfg0lcdom4e22v1siaiqbgm2k052uoo18e6f88hn12ia4vdodqhtek8uhtqbvsdrtp3c6oqvvmc3e2j72hgvku74h1m0kdoe5olalio7qg5ivgeng77gd6p2b3bn1tt7"
}


export enum Edenred {
  authUrl = "https://sso.auth.api.edenred.com/idsrv",
  authClientId = "f6d4185a307043a38b87426043163351",
  authClientSecret = "c33e75e039964d9296fc991e80f37757",
  authRedirectUrl = "https://www.eatintime.it/user/security/elogin",
  payClientId = "f54bb6dfefb54cdf9d8e2da928ee4ba9",
  payClientSecret ="e7e6a220a3ae400183D1aD37C6a4474B"
}

export let AppInfo : any = {
  APP_ID: 0,
  APP_NAME: "ETIKA",
  ADDRESS: "",
  LATITUDE: 0,
  LONGITUDE: 0,
  ASSETS_URL: "",
  LINK_COOKIE: "/cookie-policy",
  LINK_TERMS: "/terms",
  LINK_PRIVACY: "/privacy",
  BASE_COLOR: '#fed150',//b3a8ab,//#8b0000
  backUrl: ""
};

export enum DB {
  LOCATION = "location",
  USER = "currentuser",
  SETTINGS = "settings",
  TOKEN = "token",
  ORDER_ID = "orderid",
  ACTIVITY_BASE = "activity_base",
  CART = "cart",
  BOOK = "book",
  BOOK_ID = "bookid",
  BOOKORDER = "bookorder",
  BOOKORDER_ID = "bookorderid",
  CARTBOOK = "cartbook",
  ETOKEN = "etoken",
  EVOUCHERS = "evouchers",
  CREDIT_TRANSACTION = "creditTrans",
  RECHARGE_RETURNURL = "recharge_returnurl",
  APP_INFO = "appinfo",
  SERVICE = "order_service",
  JOBS = "jobs",
  NFT = "nft",
  PRODUCT = "product",
  STORE = "store",
}

export enum RESPONSE {
  JP_STATUS = "status",
  JP_RESULT = "result",
  JP_RURL = "url",
  JP_MSG = "msg",
  JP_HTML = "html",
  JPS_OK = "ok",
  JPS_ERROR = "error",
  JPS_REDIRECT = "redirect",
  JPS_VIEW = "view",
}

export enum RESULT {
  RESULT_OK = 1,
  RESULT_CANCEL = 0,

  RESULT_ERROR = 2,
  RESULT_ERROR_CIVICO = 3,
  RESULT_ERROR_ADDRESS = 4,
}

export enum TypePayment
{
  UNDEF = -1,
	MONEY = 1,
	PAYPAL = 2,
	CARD = 3,
	TICKET = 4,
	EATPOINTS = 5,
	CREDIT = 6,
  SATISPAY=7,
  INLIRE=8,
  GIFTCARD=9

	/* ID Table */
}

export enum CategorieAttivita
{
  UNDEF = 0,
  Ristorante = 1,
	Farmacia = 2,
	Spesa = 3,
	Shopping = 4,
	Wine = 5
}

export enum ET_Jobs
{
  UNDEF = -1,
	STEPS = 0,
	FOOD = 1,
	WORKOUT = 2,
  RUNNING = 3,
	BIKING = 4
}