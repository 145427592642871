<div *ngIf="!loader">
  <div class="eproduct p-3">
    <div class="img-wrapper">
      <div class="front c-pointer py-3" (click)="onGoToProduct(item.id)">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : item.media.realpath" class="img-fluid lazy-loading"
          alt="{{ item.name }}" />
      </div>
      <div class="product-detail">
        <div>
          <a [routerLink]="" (click)="onGoToProduct(item.id)">
            <h4 class="text-uppercase my-3">{{ item?.name | titlecase }}</h4>
          </a>
          <p class="product-descr">{{item.descr}}</p>
        </div>

        <div>
          <div class="price py-2">
            <h3 class="text-size-md fw-bold text-color-primary mb-0">{{item.price?.toFixed(2)}}&euro;</h3>
            <p class="text-size-sm product-descr font-regular ion-text-wrap text-color-secondary">
              Prezzo consigliato
            </p>
          </div>
          <div class="price">
            <h2 class="text-size-lg font-bold product-price mb-0">
              <ng-container *ngIf="!item.allfitcoin">{{(item.price-item.fitcoins).toFixed(2)}}
              <span class="text-color-accent-euro">&euro;</span> +</ng-container> {{item.fitcoins}} <span class="text-color-accent-fit text-capitalize">Fitcoin</span>
            </h2>
            <p class="text-size-sm product-descr font-regular ion-text-wrap text-color-secondary">
              Prezzo in esclusiva Etika
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>