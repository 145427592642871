<div class="modal-content">
  <div class="modal-body">
    <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center">Filtri</h2>
    <div class="container py-3">
      <h4>In primo piano</h4>
      <div class="row">
        <div class="col-md-4 col-sm-6" *ngFor="let promo of promos; index as i">
          <div class="card-filter" [ngClass]="{'clicked': promo.active}" ><!--(click)="updateFilter(0, i)"-->
            <input type="checkbox" [value]="promo" [checked]="promo.active" [id]="'promo-'+promo.id" (change)="updateFilter(0, promo)" class="custom-control-input" [(ngModel)]="promo.active">
            <label class="text-center" for="promo-{{promo.id}}">
              <img [src]="promo.img">
              {{promo.title}}
            </label>
          </div>
        </div>
      </div>
      <h4 class="mt-3">Pagamenti</h4>
      <div class="custom-control custom-checkbox collection-filter-checkbox" *ngFor="let pay of pays">
        <input type="checkbox" [value]="pay" [checked]="pay.active" [id]="'pay-'+pay.id" (change)="updateFilter(1, pay)" class="custom-control-input" [(ngModel)]="pay.active">
        <label class="custom-control-label" for="pay-{{pay.id}}">{{pay.name}}</label>
      </div>
      <h4 class="mt-3">Ordina per</h4>
      <div class="custom-control custom-checkbox collection-filter-checkbox" *ngFor="let sort of sorting">
        <input type="checkbox" [value]="sort" [checked]="sort.checked" [id]="'sort-'+sort.id" (change)="updateSorting(sort)" class="custom-control-input" [(ngModel)]="sort.checked">
        <label class="custom-control-label" for="sort-{{sort.id}}">{{sort.title}}</label>
      </div>
    </div>
  </div>
</div>