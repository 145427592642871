import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from '../app/shared/guard/auth.guard';
//import { NgxJsonLdModule } from '@ngx-lite/json-ld';

import { AppComponent } from './app.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
// import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
//import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';

//import 'hammerjs';
import 'mousetrap';
import { AppInfo } from './shared/services/env.service';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { ShopComponent } from './pages/shop/shop.component';
import { ProductBoxComponent } from './shared/components/product-box/product-box.component';
import { ProductComponent } from './pages/product/product.component';
import { GainerComponent } from './pages/gainer/gainer.component';
import { NftComponent } from './pages/nft/nft.component';
import { ShopCheckpayComponent } from './pages/shop-checkpay/shop-checkpay.component';
import { GainersListComponent } from './pages/gainers-list/gainers-list.component';
// import { NgCircleProgressModule } from 'ng-circle-progress';


const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'esoft.it/'// it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#000',
      text: '#fff'
    },
    button: {
      background: '#fff',//+AppInfo.MAIN_COLOR,
      text: '#000'  
    }
  },
  theme: 'edgeless',
  type: 'opt-in',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>
    </span>
    `
  },
  content:{
    message: "Utilizziamo i cookie per offrirti la miglior esperienza possibile sul nostro sito web. Accettando, accetti l'utilizzo di tali cookie.",
    
    dismiss: "Accetta",
    deny: "Rifiuta tutti",
    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: 'https://www.muurgheria.com/hamburger-muurgheria',

    privacyPolicyLink: 'Informativa sulla privacy.',
    privacyPolicyHref: 'https://www.muurgheria.com/termini-d-uso-dati-personali',

    tosLink: 'Terms of Service',
    tosHref: 'https://tos.com',
  }
}


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    RestaurantComponent,
    PagesComponent,
    ElementsComponent,
    ShopComponent,
    ProductBoxComponent,
    ProductComponent,
    GainerComponent,
    NftComponent,
    ShopCheckpayComponent,
    GainersListComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    /* AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB7zPHj0X2lXw3C4d8LHB4cbMSC1nGrgjg', 
      libraries: ['places'],
      region: "IT",
      language: "IT"
    }), */
   // NgcCookieConsentModule.forRoot(cookieConfig),
    GooglePlaceModule,
    SocialLoginModule,
    //NgxJsonLdModule,
    SharedModule,
    AppRoutingModule,
    // NgCircleProgressModule.forRoot({
    //   // set defaults here
    
    //   outerStrokeWidth: 16,
    //   innerStrokeWidth: 8,
    //   outerStrokeColor: "#78C000",
    //   innerStrokeColor: "#C7E596",
    //   animationDuration: 300
 
    // })
  ],
  providers: [Meta, AuthGuard,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('931332180279295')
          }
        ]
      } as SocialAuthServiceConfig,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
