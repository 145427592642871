import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Restaurant } from "../../../classes/restaurant";
import { CartbookService } from "../../../services/cartbook-service";
import { RestaurantService } from "../../../services/restaurant.service";
import { TypePayment, DB } from '../../../services/env.service';
import { TypeOrder } from '../../../services/config-app';
import { EitCart } from '../../../../shared/classes/eitcart';

@Component({
  selector: 'app-cartbook-box',
  templateUrl: './cartbook-box.component.html',
  styleUrls: ['./cartbook-box.component.scss']
})
export class CartbookBoxComponent implements OnInit {

  @Input() restaurant: Restaurant;
  @Input() loader: boolean = false;
  @Input() editable: boolean = false;
  
  cartData: any = null;
  payType: any = TypePayment;
  curTime: any;
  sticky: boolean = true;
  public stick: boolean = false;
  public typeOrder: any = TypeOrder;

  constructor(private route: ActivatedRoute, private router: Router, private cartbookService: CartbookService, private restService: RestaurantService) { 
    this.curTime = new Date().getHours();
    this.cartData = new EitCart();
    this.cartbookService.cartbookObs.subscribe(x => {

      this.cartData = x;

    });
  }

  ngOnInit(): void {
    this.curTime = new Date().getHours();
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

   // @HostListener Decorator
   @HostListener("window:scroll", [])
   onWindowScroll() {
     let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
     if (number >= 300 && window.innerWidth > 400) { 
       this.stick = true;
     } else {
       this.stick = false;
     }
   }

  

  addToCart(product: any) {
  //  this.productService.addToCart(product);
  }

  addToCartSingle(menuitem) 
  {
    this.cartbookService.addToCartSingle(menuitem.id, 1, "", 1);
  }

  removeToCartSingle(menuitem) 
  {
    this.cartbookService.removeToCartP(menuitem.id, 1, 1);
  }

}
