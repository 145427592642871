import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/services/auth.service';
import { AppInfo, RESPONSE } from '../../../shared/services/env.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { SocialAuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { AppService } from '../../../shared/services/app.service';

const fbLoginOptions = {
  scope: 'public_profile,email'
};

@Component({
  selector: 'app-login-box',
  templateUrl: './login-box.component.html',
  styleUrls: ['./login-box.component.scss']
})
export class LoginBoxComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string = "/"
  userSocial: SocialUser;
  themeLogo: string = ''; // Default Logo
  appInfo: any = AppInfo
  appData: any = null;

  constructor(private toastService: ToastrService, private route: ActivatedRoute, private router: Router, private authService: AuthService, 
              private authSocialService: SocialAuthService, private appService: AppService) { 
    this.route.queryParams.subscribe(params => {
      if (params && params['returnUrl']) this.returnUrl = params['returnUrl'];
    });
    this.appService.appObs.subscribe(x => { 
      this.appData = x;
    })
    
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required, Validators.minLength(6)]),
    })
  }

  ngOnInit(): void {
    console.log("login")
    this.authSocialService.authState.subscribe((user) => {
      if (user != null)
      {
        console.log(JSON.stringify(user))

        var userFb = {id : "", email : "", name : ""};
        userFb.id = user.id;
        if(user.email)
        {
          userFb.email = user.email;
        }
        if(user.name)
        {
          userFb.name = user.name;
        }
        
        this.authService.loginFB(userFb).pipe(first()).subscribe(data => {
          if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]) {
            this.router.navigate([this.returnUrl]);
          }
          else this.toastService.error("Username e/o password errate!")
        });
      }
    });
  }

  login(){
    if(this.loginForm.valid){

      this.authService.login(this.loginForm.value).pipe(first()).subscribe(data => {
        if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]) {
          this.router.navigate([this.returnUrl]);
        }
        else this.toastService.error("Username e/o password errate!")
      });
    }
    else{
      let rErr = "Mancano i seguenti campi: "
      let err1 = false;
      if(!this.loginForm.controls['email'].valid && this.loginForm.controls['email'].getError('required')) {
        rErr += "<br> Email"
        err1 = true;
      }
      else if(!this.loginForm.controls['email'].valid && this.loginForm.controls['email'].getError('email')) this.toastService.error("Email non valida!")

      if(!this.loginForm.controls['password'].valid && this.loginForm.controls['password'].getError('required')) {
        rErr += "<br> Password"
        err1 = true;
      }
      else if(!this.loginForm.controls['password'].valid && this.loginForm.controls['password'].getError('minlength')) this.toastService.error("Password non valida!")

      if (err1)  this.toastService.error(rErr)
    }
  }

  recovery(){
    this.router.navigate(['pages/forget/password'])
  }

  register(){
    this.router.navigate(['pages/register'])
  }

  fbLogin(){
    this.authSocialService.signIn(FacebookLoginProvider.PROVIDER_ID, fbLoginOptions);

   
  }

}
