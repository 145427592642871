<div class="modal-content">
  <div class="modal-body">
    <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center">Inserisci i dati per contattare la tua azienda</h2>
    <form [formGroup]="requestForm" class="login-form mt-5 mb-3" (ngSubmit)="send()">
      <div class="row">
        <div class="form-group col-lg-6">
          <input type="text" class="form-control" id="name" placeholder="Nome del referente"
            [ngClass]="{'errInput': inputHasError('name', 'required')}" formControlName="name" name="name">
          <div class="err" *ngIf="inputHasError('name', 'required')">Il Nome del referente &egrave; richiesto.</div>
        </div>
        <div class="form-group col-lg-6">
          <input type="text" class="form-control" id="lastname" placeholder="Cognome del referente"
            [ngClass]="{'errInput': inputHasError('lastname', 'required')}" formControlName="lastname"
            name="lastname">
          <div class="err" *ngIf="inputHasError('lastname', 'required')">Il Cognome del referente &egrave; richiesto.</div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6">
          <input type="email" class="form-control" id="email" placeholder="Email"
            [ngClass]="{'errInput': inputHasError('email', 'required')}" formControlName="email" name="email">
          <div class="err" *ngIf="inputHasError('email', 'required')">L'Email &egrave; richiesta.</div>
        </div>
        <div class="form-group col-lg-6">
          <input type="phone" class="form-control" [ngClass]="{'errInput': inputHasError('phone', 'required')}"
            placeholder="Telefono" formControlName="phone" name="phone">
          <div class="err" *ngIf="inputHasError('phone', 'required')">Il Telefono &egrave; richiesto.</div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6">
          <input type="text" class="form-control" id="company" placeholder="Nome dell'azienda"
            [ngClass]="{'errInput': inputHasError('company', 'required')}" formControlName="company" name="company">
          <div class="err" *ngIf="inputHasError('company', 'required')">Il Nome dell'azienda &egrave; richiesto.</div>
        </div>
      </div>
      <button class="btn btn-eit d-block mx-auto mt-3" type="submit">Invia la richiesta</button>
    </form>
  </div>
</div>