<app-header-one [class]="'header-gym'"></app-header-one>

<section>
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1 class="eit-act-title text-color-accent-fit text-center mb-4">
                        SHOP
                    </h1>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="align-self-center" style="margin: auto;">
                <ngx-horizontal-scroll-menu (notifyCat)="onNotify($event)"
                    [items]="categories"></ngx-horizontal-scroll-menu>
            </div>
        </div>
        <div class="container">
            <div class="collection-content">
                <div class="page-main-content">
                    <div class="collection-product-wrapper" id="products">
                        <div class="product-wrapper-grid grid-view">
                            <div class="row">
                                <div class="col-grid-box col-xl-3 col-lg-4 col-md-6"
                                    *ngFor="let item of products">
                                    <div class="product-box">
                                        <app-product-box [item]="item" [thumbnail]="false"
                                            [loader]="true">
                                        </app-product-box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-two [class]="'bg-img-gym bg-size'"></app-footer-two>