import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from "../../services/cart-service";
import { EitCart } from "../../classes/eitcart";
import { Menu, NavService } from '../../services/nav.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public cartData: any = null;
  public user: any = null;
  
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  public menuItems: Menu[];
  level: number = 1;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public cartService: CartService,
    public navServices:NavService,
    public auth:AuthService) {
      this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
      this.cartData = new EitCart();
      this.cartService.cartObs.subscribe(x => {
  
        this.cartData = x;
  
      });

      this.auth.currentUser.subscribe(state => {
        this.user = state;
        if(state) this.level = Math.floor(this.user.etikalevel)
      });
  }

  ngOnInit(): void {
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  changeCurrency(currency: any) {
    //this.productService.Currency = currency
  }

}
