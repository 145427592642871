
<section class="login-page text-center section-b-space pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="card login-card">
                    <div class="p-0 card-body text-center">
                        <form [formGroup]="regForm" class="login-form" (ngSubmit)="register()">
                            <div class="form-group">
                                <input type="text" class="form-control my-5" id="name" placeholder="Nome" required
                                    formControlName="name" name="name">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control my-5" id="lastname" placeholder="Cognome" required
                                    formControlName="lastname" name="lastname">
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control my-5" id="email" placeholder="Email" required
                                    formControlName="email" name="email">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control my-5" id="phone" placeholder="Telefono" required
                                    formControlName="phone" name="phone">
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control my-5" id="review" placeholder="Password" required
                                    formControlName="password" name="password">
                            </div>
                            <ng-container *ngIf="appData">
                                <div class="form-group text-start" *ngIf="appData.link_terms && appData.link_terms.length">
                                    <div class="custom-control custom-checkbox collection-filter-checkbox">
                                    <input type="checkbox" class="custom-control-input" formControlName="terms" name="terms" id="terms"/>
                                    <label class="custom-control-label" for="terms">
                                        *Ho letto, compreso e accettato le <a [href]="appData.link_terms">Condizioni generali di uso</a>
                                    </label>
                                    </div>
                                </div>
                                <div class="form-group text-start" *ngIf="appData.link_privacy && appData.link_privacy.length">
                                    <div class="custom-control custom-checkbox collection-filter-checkbox">
                                    <input type="checkbox" class="custom-control-input" formControlName="privacy" name="privacy" id="privacy"/>
                                    <label class="custom-control-label" for="privacy">
                                        *Ho letto e compreso l’<a [href]="appData.link_privacy">Informativa privacy</a>
                                    </label>
                                    </div>
                                </div>
                                <div class="form-group text-start" *ngIf="appData.link_privacy && appData.link_privacy.length">
                                    <div class="custom-control custom-checkbox collection-filter-checkbox">
                                    <input type="checkbox" class="custom-control-input" formControlName="newsletter" name="newsletter" id="newsletter"/>
                                    <label class="custom-control-label" for="newsletter">
                                        Letta l’<a [href]="appData.link_privacy">Informativa privacy</a>, 
                                        acconsento  all’invio di comunicazioni commerciali e messaggi 
                                        promozionali relativi ai prodotti e servizi.
                                    </label>
                                    </div>
                                </div>
                                <div class="form-group text-start" *ngIf="appData.link_privacy && appData.link_privacy.length">
                                    <div class="custom-control custom-checkbox collection-filter-checkbox">
                                    <input type="checkbox" class="custom-control-input" formControlName="profiling" name="profiling" id="profiling"/>
                                    <label class="custom-control-label" for="profiling">
                                        Letta l’<a [href]="appData.link_privacy">Informativa privacy</a>,
                                        acconsento  all’invio di comunicazioni commerciali personalizzate e all’offerta di prodotti in linea con le mie 
                                        preferenze (c.d. profilazione)
                                    </label>
                                    </div>
                                </div>
                            </ng-container>
                            <button class="btn  btn-solid btn-block py-3 px-5" type="submit">Registrati</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>