import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the ArrayPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'array',
})
export class ArrayPipe implements PipeTransform {
 
  transform(value) : any {
    let values= [];
    for (let key in value) {
     values.push(value[key]);
    }
    return values;
   }
}
