
<!--<div class="d-flex" [ngClass]="background">
  <div *ngIf="!hideNav" class="control d-flex align-items-center justify-content-center mousePointer" (mousedown)="left()"
(click)="scrollLeft()" (mouseup)="clear()">
  <div class="prev" [ngClass]="{'opacity': !leftArrowHide}">
    <img [src]="leftIcon" width="24" />
  </div>
</div>

<div class="flex-fill align-items-center" [ngClass]="!hideNav ? 'items-wrapper' : 'w-100'" [ngClass]="background" style="background: #ffffff; max-width: 83%;">

  <ul id="list-items"   class="nav-fill flex-nowrap overflow-auto" 
    *ngIf="items" #hScroll ngxScrollMenu (scrolled)="listenToItemsScroll($event)">

    <li *ngFor="let item of items" >
      <a  routerLink="." fragment="{{item.id}}" class="cat-nav">
        {{item.name}}
      </a>
    </li>

  </ul>

</div>

<div *ngIf="!hideNav" class="control d-flex justify-content-center align-items-center mousePointer"
(click)="scrollRight()" (mousedown)="right()" (mouseup)="clear()">
  <div class="next rotate-right" [ngClass]="{'opacity': !rightArrow}">
    <img [src]="leftIcon" width="24" />
  </div>
</div>
</div>-->

<nav class="d-flex" *ngIf="items?.length" [ngClass]="background">
  <div *ngIf="!hideNav" class="control flex-fill mousePointer d-flex align-items-center" (mousedown)="left()"
  (click)="scrollLeft()" (mouseup)="clear()" [ngClass]="{'display': leftArrowHide}">
    <div class="prev ion-text-align-center" [ngClass]="{'opacity': !leftArrowHide}">
      <img [src]="leftIcon" width="32" />
    </div>
  </div>
  <div [ngClass]="!hideNav ? 'items-wrapper' : 'w-100'" class="flex-fill align-items-center">
    <ul id="list-items" #hScroll ngxScrollMenu (scrolled)="listenToItemsScroll($event)" class="nav-fill align-items-center flex-nowrap overflow-auto">
      <li *ngFor="let item of items" class="px-2 align-items-center" [ngClass]="{'clicked': item.id === clicked}">
        <a class="cat-nav text-uppercase" ngbNavLink [ngClass]="text" (click)="onSelectCat(item)">{{ item?.name}}</a>
      </li>
    </ul>
  </div>

  <div *ngIf="!hideNav" class="control flex-fill align-items-center mousePointer d-flex align-items-center"
  (click)="scrollRight()" (mousedown)="right()" (mouseup)="clear()" [ngClass]="{'display': rightArrow}">
    <div class="next ion-text-align-center rotate-right" [ngClass]="{'opacity': !rightArrow}">
      <img [src]="leftIcon" width="32" />
    </div>
  </div>
</nav>