import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-allergens',
  templateUrl: './allergens.component.html',
  styleUrls: ['./allergens.component.scss']
})
export class AllergensComponent implements OnInit {

  @Input() allergens: Array<any> = []
  @Input() link_allergens: string = ""

  constructor(private modalService: NgbModal) { 
    console.log("allergens")
  }
  
  ngOnInit(): void {
  }

  dismiss(){
    this.modalService.dismissAll()
  }
}
