import { Injectable } from '@angular/core';


@Injectable()
export class EitPlace {

  zipcode:any = "";
  num:any = "";
  numForced:any = "";
  nomelocale:any = "";
  latitude:any = 0;
  longitude:any = 0;
  street_address:any = "";
  locality:any = "";
  sublocality:any = "";
  regione:any = "";
  nazione:any = "";
  admin_area_3:any = "";
  description:any = "";

  importo:any='';
  citofono:any ='';
  interno:any ='';
  telefono:any ='';
  noteride:any ='';
  address:any = '';


  constructor() {
    this.initData();
  }

  initData() {
    this.zipcode = "";
    this.num = "";
    this.numForced = "";
    this.nomelocale = "";
    this.latitude = 0;
    this.longitude = 0;
    this.street_address = "";
    this.locality = "";
    this.sublocality = "";
    this.regione = "";
    this.nazione = "";
    this.admin_area_3 = "";
    this.importo='';
    this.citofono ='';
    this.interno ='';
    this.telefono ='';
    this.noteride='';
    this.address='';
    this.description='';
  }

  setData(data: any) {
    this.zipcode = data?.zipcode;
    this.num = data?.num;
    this.numForced = data?.numForced;
    this.nomelocale = data?.nomelocale;
    this.latitude = data?.latitude;
    this.longitude = data.longitude;
    this.street_address = data?.street_address;
    this.locality = data?.locality;
    this.sublocality = data?.subLocality;
    this.regione = data?.regione;
    this.nazione = data?.nazione;
    this.admin_area_3 = data?.admin_area_3;
    this.importo = data?.importo;
    this.citofono = data?.citofono;
    this.interno = data?.interno;
    this.telefono = data?.telefono;
    this.noteride = data?.noteride;
    this.address = data?.address;
    this.description = data?.description;
  }

}