<div *ngIf="!loader"> <!-- && cartData && cartData.order -->
    <div class="product-right product-form-box checkout-details box cart-box">
        <div class="text-center m-0 box-title">
            <h3 class="m-0">Prenota un tavolo</h3>
        </div>        

        <div class="box-account box-fidelity row">
            <div class="col-lg-4 row m-0" (click)="owlCar.to(activeSlide = '0')">
                <img src="assets/images/prenotazioni.png" class="mr-3" style="transform: scale(0.3);">                
            </div>
            <div class="col-lg-4 row m-0" (click)="owlCar.to(activeSlide = '1')">
                <img src="assets/images/orologio.png" class="mr-3" style="transform: scale(0.3);">
            </div>
            <div class="col-lg-4 row m-0" (click)="owlCar.to(activeSlide = '2')">
                <img src="assets/images/account.png" class="mr-3" style="transform: scale(0.3);">
            </div>
        </div>

        <ng-container> 
            <owl-carousel-o [options]="TablesMainSlider" #owlCar class="product-slick">
                <ng-container >
                    <ng-template carouselSlide [id]="0">
                        
                        <!--[events]="calendarEvents" (eventClick)="handleEventClick($event)" [displayEventTime]="false"-->
                        <!--
                        <full-calendar [defaultView]="activeView" [plugins]="calendarPlugins" #calendar (dateClick)="handleDateClick($event)" 
                            deepChangeDetection="true"  selectable="true" (eventColor)="setColor($event)" eventTextColor="#fff" 
                            class="rest-calendar">
                        </full-calendar>
                        style="height: 500px;"
                        -->
                        <!-- <div class="m-4" (click)="owlCar.to(activeSlide = '1')">  <!-[class.active]="0 == activeSlide"->
                            <full-calendar #mycalendar [options]="calendarOptions" deepChangeDetection="true" selectable="true" class="rest-calendar" 
                             (dateClick)="handleDateClick($event)" ></full-calendar>
                        </div> -->

                    </ng-template>
                </ng-container>
                <ng-container >
                    <ng-template carouselSlide [id]="1">
                        <div (click)="owlCar.to(activeSlide = '2')"> <!--[class.active]="1 == activeSlide"-->
                            <!--pag 2 - Orari-->

                            <h4 *ngIf="fascepranzo.length==0 && fascecena.length==0" >Non è possibile prenotare per la data selezionata!</h4>
                            
                            <h3 class="eit-title">Pranzo</h3>
                            
                            <h3 *ngIf="fascepranzo.length == 0 && fascecena.length>0" style="text-align: center;"> Non ci sono posti disponibili per Pranzo! </h3>
        
                            <div class="box-account box-fidelity row text-center" style="margin-left: 10% !important; margin-right: 10% !important;">
                              <div *ngFor="let timeslots of fascepranzo" class="tables-btn-wizard">
                                <button [disabled]="timeslots.posti_rimanenti==0" 
                                    [ngClass]="timeselected==timeslots.orario ? 'btn-block eit-button-by-selected' : 'btn-block eit-button-by'"														 
                                    (click)="acceptorario(timeslots)">
                                        {{timeslots.orario}}
                                </button>
                              </div>
                            </div>

                            <h3 class="eit-title">Cena</h3>

                            <h3 *ngIf="fascecena.length == 0 && fascepranzo.length>0" style="text-align: center;"> Non ci sono posti disponibili per Cena! </h3>

                            <div class="box-account box-fidelity row text-center" style="margin-left: 10% !important; margin-right: 10% !important;">
                              <div *ngFor="let timeslots of fascecena" class="tables-btn-wizard" >
                                <button [disabled]="timeslots.posti_rimanenti==0" 
                                    [ngClass]="timeselected==timeslots.orario ? 'btn-block eit-button-by-selected' : 'btn-block eit-button-by'"														 
                                    (click)="acceptorario(timeslots)">
                                        {{timeslots.orario}}
                                </button>
                              </div>
                            </div>

                        </div>
                    </ng-template>
                </ng-container>
                <ng-container >
                    <ng-template carouselSlide [id]="2">
                        <div > <!--[class.active]="2 == activeSlide"-->
                            <h3 class="eit-title">Per quante persone vuoi prenotare?</h3>
                            <div class="box-account box-fidelity row text-center" style="margin-left: 10% !important; margin-right: 10% !important;">

                                <div [hidden]="numpeoplearray.length==0" *ngFor="let npeople of numpeoplearray" class="tables-btn-wizard">
                                    <button [disabled]="npeople.posti_rimanenti==0" 
                                        [ngClass]="numpeopleselected==npeople.num ? 'btn-block eit-button-by-selected' : 'btn-block eit-button-by'"														 
                                        (click)="acceptnpeople(npeople.num)">
                                        {{npeople.num}}													
                                    </button>
                                </div>
                                <div [hidden]="numpeoplearray.length>0 || !timeselected">
                                    Non ci sono posti disponibili per quest'ora!
                                </div>

                            </div>
                            <div>
                                <a class="btn btn-eit" disabled="false" (click)="goToBook()">Prenota il tavolo</a>
                            </div>
                        </div>

                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </ng-container>
        <div class="border-product mr-3 ml-3"></div>        
    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>