<div class="modal-content">
  <div class="modal-body">
    <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center">Aggiungi convenzione</h2>
    <form [formGroup]="searchForm" (ngSubmit)="applyFilter()" style="margin: 0 -15px">
      <input type="text" class="form-control" formControlName="LIKE" name="LIKE">
    </form>
    <div class="row corporate-box cb-i" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" [scrollWindow]="false" (scrolled)="next()">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let record of associazioni">
        <div class="img-container" (click)="select(record)"><img [src]="getPath(record)"><span *ngIf="record.joined" (click)="removeJoin(record.id_company)">&times;</span></div>
      </div>
    </div>
    <div class="info_row row">
      <div class="col-lg-6" *ngIf="info_company.id">
        <div class="form-group">
          <label>Nome</label>
          <p>{{info_company.name}}</p>
        </div>        
        <div class="form-group">
          <label>Descrizione</label>
          <p>{{info_company.description}}</p>
        </div>        
        <div class="form-group">
          <label>Indirizzo</label>
          <p *ngIf="info_company.address.length">{{info_company.address}}, {{info_company.num}} {{info_company.zip}}-{{info_company.townstr}}</p>
        </div>        
      </div>
      <div class="col-lg-6" *ngIf="info_company.id && !info_company.joined">
        <form [formGroup]="addForm" (ngSubmit)="add()" class="border-bottom-0">
          <input type="text" class="form-control" formControlName="CODE" name="CODE" placeholder="Codice di affiliazione">
          <button class="btn btn-eit mt-3 mx-auto d-block text-uppercase" type="submit">Aggiungi</button>
        </form>        
      </div>
    </div>
  </div>
</div>