import { Component, PLATFORM_ID, Inject, Injector, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from './shared/services/location.service';
import { AuthService } from './shared/services/auth.service';
import { CartService } from './shared/services/cart-service';
import { BookService } from './shared/services/book-service';
import { DB, RESPONSE } from './shared/services/env.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { AppService } from './shared/services/app.service';
import { ApiService } from './shared/services/api.service';

// declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit, OnDestroy {

  ccService = null;
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  schema = {
   
  };
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) private dom,
    private loader: LoadingBarService, translate: TranslateService, private appService: AppService,
    private locService: LocationService,
    private auth: AuthService, private cartService: CartService, private bookService: BookService,
    private router: Router,
    /*private ccService: NgcCookieConsentService,*/
    private readonly injector: Injector, private api:ApiService) {

      this.ccService = null;
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('it');
      translate.addLangs(['en', 'fr', 'it']);

        var id_order = JSON.parse(localStorage.getItem(DB.ORDER_ID));
        // var cart = JSON.parse(localStorage.getItem(DB.CART));
        // if (id_order) {
        //   this.auth.nextOrder(id_order);
        // }

        // if (cart) {
        //   this.cartService.next(cart);
        // }

          console.log("getItem Order_ID: " + id_order);
          if (!id_order || id_order == null) {
            id_order = -1;
          }
            
            this.api.getOrderId(id_order).then((res: any) =>{
              console.log("getItem Order_ID getORDER: " + res);
              let ord_Id = res;
              this.auth.nextOrder(ord_Id);
            });


        var user = JSON.parse(localStorage.getItem(DB.USER));
        if (user) {
          this.auth.next(user);
          this.api.updateInfo({token: null, uuid: null}).then(res => {
            if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {        
              let cUser = res[RESPONSE.JP_RESULT];
              this.auth.next(cUser)                       
            }    
          }, (err) => {   
            console.log(err);    
          });
        }
      

        // if (id_order && !cart) {
        //   this.cartService.loadCart(id_order);
        // }
     
        // this.appService.getAppInfo();
    
        //const ccService = this.injector.get(NgcCookieConsentService);
      
        // Configure stuff
        // ...
        //ccService.init(ccService.getConfig()); 
    }
  }


  ngOnInit(){
    //customerly('update');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        var url = event.url;
        url = url.indexOf('/') == 0 ? url.substring(1) : url;
      
      //   gtag('config', 'UA-70840386-1', 
      //   {
      //     'page_path': event.urlAfterRedirects
      //   }
      //  );
 
      }});
/*
      // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });*/
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
   /* this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();*/
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

}
