import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CartService } from "../../services/cart-service";
import { EitCart } from "../../classes/eitcart";
import { AppService } from '../../services/app.service';
import { AppInfo } from '../../services/env.service';
import { Menu, NavService } from '../../services/nav.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = ''; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  appData: any = null;
  public cartData: any = null;
  appInfo: any = AppInfo
  user:any = null;

  public menuItemsLeft: Menu[];
  public menuItemsRight: Menu[];

  constructor(public cartService: CartService, private appService: AppService, private navServices:NavService, private auth:AuthService) {

    this.navServices.itemsLeft.subscribe(menuItems => this.menuItemsLeft = menuItems);
    this.navServices.itemsRight.subscribe(menuItems => this.menuItemsRight = menuItems);

    console.log("items left ", this.menuItemsLeft, " items right ", this.menuItemsRight );

    this.cartData = new EitCart();
      this.cartService.cartObs.subscribe(x => {
  
        this.cartData = x;
  
      });

      this.appService.appObs.subscribe(x => {  
        this.appData = x;
        console.log(this.appData)
        this.themeLogo = this.appService.getLogo(this.appData);
      });

      this.auth.currentUser.subscribe(state => {
        this.user = state;
      });
   }

  ngOnInit(): void {
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 300 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

}
