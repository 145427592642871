import { Component, OnInit } from '@angular/core';
import { BookService } from "../../../services/book-service";
import { ApiService } from '../../../services/api.service';
import { RESPONSE } from '../../../services/env.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-tableinfo-promo-box',
  templateUrl: './tableinfo-promo-box.component.html',
  styleUrls: ['./tableinfo-promo-box.component.scss']
})
export class TableInfoPromoBoxComponent implements OnInit {

  restaurant_id: any = 0;
  queryPage: number = 0;
  promos: Array<any> = []
  initVal: boolean = true;
  searchForm: FormGroup;
  canScroll: boolean = false;

  constructor(private bookService: BookService, private api: ApiService) {
    this.bookService.bookObs.subscribe(x => {
      this.restaurant_id = x.rest_id;
    }); 
    this.searchForm = new FormGroup({
      LIKE: new FormControl(""),
      PAGE: new FormControl(0),
      PAGESIZE: new FormControl(10),
      PID: new FormControl(0)
    })
  }

  ngOnInit(): void {
   
  }

  init(){    

    this.bookService.bookObs.subscribe(x => {
      this.restaurant_id = x.rest_id;
      if(this.initVal){
        this.initVal = false;
        this.fillPromos();
      }
    }); 

  }

  fillPromos(){
    this.api.postj_T("restaurant/getpromos", {RESTID: this.restaurant_id, PAGE: this.queryPage}).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.promos = this.promos.concat(res[RESPONSE.JP_RESULT])
      }
    });
  }

  next(){
    if(this.canScroll){
      this.queryPage++; 
      this.fillPromos(); 
    }
  }
  
  applyFilter(){
    this.searchForm.patchValue({PAGE: 0});
    this.promos = []
    this.fillPromos();
  }

}
