import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { EitPlace } from 'src/app/shared/classes/eitplace';
import { LocationService } from 'src/app/shared/services/location.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
// import { AgmMap } from '@agm/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DB, RESPONSE, RESULT } from '../../..//services/env.service';
import { CartService } from '../../../services/cart-service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EitCart } from '../../../classes/eitcart';
import { TypeOrder } from 'src/app/shared/services/config-app';
import { NcivicoComponent } from '../ncivico/ncivico.component';

declare const google: any;

@Component({
  selector: 'app-location-select',
  templateUrl: './location-select.component.html',
  styleUrls: ['./location-select.component.scss']
})
export class LocationSelectComponent implements OnInit, OnDestroy {

  query: string = "";
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  // @ViewChild(AgmMap) agmMap: AgmMap;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  places: any = [];
  onSelected: boolean = false;
  location: any;
  loc: any;
  geocoder: any;
  options: any;
  loading: boolean;
  mapInfo: any = { icon: { url: "assets/images/pin-mappa-home-72.png", lat: 51.673858, lng: 7.815982, scaledSize: new google.maps.Size(61, 60) } }
  cartSub: Subscription;
  public cartData: any = {};
  typeService:any;

  constructor(private toastService: ToastrService, private locService: LocationService, private ref: ChangeDetectorRef, private modalService: NgbModal, private cartService: CartService) {
    this.options = {
      componentRestrictions: { country: 'IT' },
      types: ['geocode']  // 'establishment' / 'address' / 'geocode'
    }
    this.typeService = JSON.parse(localStorage.getItem(DB.SERVICE));
    this.cartData = new EitCart();
    this.cartSub = new Subscription;
    this.location = new EitPlace();
    this.location.initData();
    this.loc = new EitPlace();
    this.loc.initData();

    this.locService.location.subscribe(x => {
      if (x) {
        this.location = x;
        this.loc.setData(this.location)
      //   this.location = x;
      //   if (x.description) {
      //     this.query = this.location.description;
      //   }
      //   if (x.latitude && x.longitude) {
      //     this.mapInfo.lat = this.location.latitude
      //     this.mapInfo.lng = this.location.longitude

      //     setTimeout(() => {
      //       this.loading = false;
      //       this.ref.detectChanges();
      //       this.agmMap.triggerResize(true);
      //     }, 100)
      //   }
      this.changeMapLocation(x);
      }
    });

  }

  ngOnInit(): void {
    this.loading = true;
    this.geocoder = new google.maps.Geocoder;
    this.cartSub.add(this.cartService.cartObs.subscribe(x => {

      if (x) {
        this.cartData = x;
      }

    }));
  }

  changeMapLocation(x:any){
    // this.location = x;
        if (x.description) {
          this.query = this.loc.description;
        }
        if (x.latitude && x.longitude) {
          this.mapInfo.lat = this.loc.latitude
          this.mapInfo.lng = this.loc.longitude

          setTimeout(() => {
            this.loading = false;
            this.ref.detectChanges();
            // this.agmMap.triggerResize(true);
          }, 100)
        }
  }

  ngOnDestroy() {
    this.cartSub.unsubscribe();
  }

  dismiss(status?: string) {
    this.onDismiss.emit({ status: (status ? status : RESPONSE.JPS_ERROR) })
    this.modalService.dismissAll()
  }

  public handleAddressChange(address: Address) {
    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }
    console.log(address)
    this.locService.selectPlace(this.geocoder, this.loc, address).then(res => {
      // this.location = res;
      this.changeMapLocation(res);
      this.query = res.description;
      /* this.locService.nextLocation(this.location);
       if(this.locService.isDeliveryOk(null)) {
         this.locService.loadDelivery_R(true);
       }*/
      this.verify();
    });
  }

  onMapReady(map: any) {
    console.log("On map ready");
    map.setOptions({ zoomControl: false, mapTypeControl: false, streetViewControl: false, fullscreenControl: false });
  }

  save() {
    this.location = this.loc;
    this.cartService.checkAddress(this.location);/*.subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
        this.locService.nextLocation(this.location);
        this.dismiss(RESPONSE.JPS_OK);
      }
    })*/
    if (this.typeService == TypeOrder.EIT_DOMICILIO){
      var result = this.locService.isDeliveryOk(null);
      if (result == RESULT.RESULT_ERROR_CIVICO) {
        this.selectCivico()
      } else {
        this.locService.nextLocation(this.location);
        this.dismiss(RESPONSE.JPS_OK);
      }
    } else {
      this.locService.nextLocation(this.location);
      this.dismiss(RESPONSE.JPS_OK);
    }
    
  }

  verify() {
    this.locService.checkAddressOnly(this.loc, this.cartData.order.restaurant_id).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
        //this.postLocation(location, this.cart)
        this.toastService.success("Indirizzo valido!");
        //this.locService.nextLocation(this.location);
      }
      else {
        this.toastService.error("Il ristorante non consegna all'indirizzo indicato!");
      }
    });
  }

  selectCivico() {
    let modal = this.modalService.open(NcivicoComponent, { size: 'md', centered: true, ariaLabelledBy: 'Ncivico-Modal', backdrop: 'static' })
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if (event.status == RESPONSE.JPS_OK && event.result) {
        this.location.num = event.result
        this.location.numForced = event.result;
        this.location.description = this.locService.getAddressFromPlace(this.location);
        this.locService.selectAddress(this.geocoder, this.location, this.location.description, true);
        this.query = this.location.description;
        this.locService.nextLocation(this.location);
      }
    })
  }
}
