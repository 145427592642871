<div class="modal-content">
  <div class="modal-body">
    <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="text-center">Informazioni Sovrapprezzo</h3>
    <div class="text-center">
      <p>Sovrapprezzo per ordine minimo di {{ordermin}} € non raggiunto!</p>
    </div>
    <button class="btn btn-eit d-block mx-auto mt-3" (click)="dismiss()">Chiudi</button>
  </div>
</div>