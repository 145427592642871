import { Component, OnInit, Input, ViewChild, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Restaurant } from "../../../classes/restaurant";
import { RestaurantService } from "../../../services/restaurant.service";
import { CartService } from "../../../services/cart-service";
import { TypePayment, DB, RESPONSE, AppInfo } from '../../../services/env.service';
import { CustomModalComponent } from '../../modal/custom-modal/custom-modal.component';
import { InfoModalComponent } from '../../modal/info-modal/info-modal.component';
import { EitCart } from '../../../../shared/classes/eitcart';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-menu-box',
  templateUrl: './menu-box.component.html',
  styleUrls: ['./menu-box.component.scss']
})
export class MenuBoxComponent implements OnInit {

  @Input() list: any=null;
  @ViewChild("customModal") CustomModal: CustomModalComponent;
  @ViewChild('catNav', { read: ElementRef }) public catNav: ElementRef<any>;
  appInfo: any = AppInfo
  
  items: any[] = [];

  public scrollRight(): void {
    this.catNav.nativeElement.scrollTo({ left: (this.catNav.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.catNav.nativeElement.scrollTo({ left: (this.catNav.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  cartData: any = "";
  restaurant: any;
  active: boolean = false;
  sticky: boolean = true;
  public stick: boolean = false;
 

  constructor(private route: ActivatedRoute, private router: Router, private restService: RestaurantService, private cartService: CartService, private modalService: NgbModal) {
    this.cartData = new EitCart();
    this.cartService.cartObs.subscribe(x => {

      this.cartData = x;

    });

    this.restaurant = JSON.parse(localStorage.getItem(DB.ACTIVITY_BASE));

    
  }

  ngOnInit(): void {
   
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 500 && window.innerWidth > 600) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  ingredients(item) {
    var result = "";

    for (var iG = 0; iG < item.ingredients.length; iG++) {
      result += item.ingredients[iG].name;
      if (iG < item.ingredients.length - 1) {
        result += ", ";
      }
    }

    return result;
  };

  shortDescr(item) {
    var result = "";

    if (item.descr.length > 0 && item.descr.length < 15) {
      result = " (" + item.descr + ")";
    }

    return result;
  }

  longDescr(item) {
    var result = "";

    if (item.descr.length > 15) {
      result = item.descr + " ";
    }

    return result;
  }

  async addToCart(menuitem : any, variante?: any){
    console.log("add")
    let ristoranteChiuso=false;

    if (this.restaurant.nowclosed){
      if (this.restaurant.preorder) {
        //Preordine possibile
        ristoranteChiuso=false;
      }else{
        //ristorante chiuso e non preordinabile
        ristoranteChiuso=true;
      }
    }else if (this.restaurant.dayclosed) {
      //  Il Ristorante al momento è chiuso e non preordinabile
      ristoranteChiuso=true;
    }else{
      //Ristorante aperto
      ristoranteChiuso=false;
    }

    if (ristoranteChiuso){
      this.infoRestClosed();
    }else{
      var item = variante == null ? menuitem : variante;
      this.CustomModal.openModal(item, menuitem)
      if (0 != item.custom_type || item.joins.length > 0) {
        //this.CustomModal.openModal(item, menuitem)
      // let data = {item: item, cartData: this.cartData, parent: menuitem}
        //this.goToProduct(data);
      }
      else{
      /* product.quantity = this.counter || 1;
        const status = await this.productService.addToCart(product);
        if(status)
          this.router.navigate(['/shop/cart']);*/
      }
    }

  }

  addToCartSingle(menuitem) 
  {
    console.log("add single")

    let ristoranteChiuso=false;

    if (this.restaurant.nowclosed){
      if (this.restaurant.preorder) {
        //Preordine possibile
        ristoranteChiuso=false;
      }else{
        //ristorante chiuso e non preordinabile
        ristoranteChiuso=true;
      }
    }else if (this.restaurant.dayclosed) {
      //  Il Ristorante al momento è chiuso e non preordinabile
      ristoranteChiuso=true;
    }else{
      //Ristorante aperto
      ristoranteChiuso=false;
    }

    if (ristoranteChiuso){
      this.infoRestClosed();
    }else{
      this.cartService.addToCartSingle(menuitem.id, 1, "", 1);  
    }


  }

  removeToCartSingle(menuitem) 
  {
    this.cartService.removeToCartP(menuitem.id, 1, 1);
  }



  infoRestClosed()
  {

    console.log("info modal");
    var order: any;
    order = this.cartData.order;
    let modal = this.modalService.open(InfoModalComponent, {size: 'md', centered: true, ariaLabelledBy: 'Info-Modal', windowClass: 'info-modal'})
    //modal.componentInstance.restaurant = restaurant
    modal.componentInstance.type = 1
    modal.componentInstance.init()
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if(event.status == RESPONSE.JPS_OK){
        console.log("dismiss infomodal response OK");        
      }else{
        console.log("dismiss infomodal response ERROR");
      }
    })

    /*
    let modalinfo = this.InfoModalComponent.create(UtilityPage, {"type":"7"});
    modalinfo.onDidDismiss(result => {
      / *
      if ((result.result = RESPONSE.RESULT_OK)) {
        if(null!=result.){        
        }        
      }
      * /
    });
    modalinfo.present({
      / *ev: myEvent* /
    });
    */
  }

  goToCheckout()
  {
    this.router.navigate(['/cart'], { queryParams: { } });
  }
}
