
<nav class="d-flex" *ngIf="items?.length" [ngClass]="background">
  <!--<div *ngIf="!hideNav" class="control flex-fill mousePointer" (mousedown)="left()"
  (click)="scrollLeft()" (mouseup)="clear()" [ngClass]="{'display': leftArrowHide}">
    <div class="prev ion-text-align-center" [ngClass]="{'opacity': !leftArrowHide}">
      <img [src]="leftIcon" width="32" />
    </div>
  </div>-->
  <div [ngClass]="!hideNav ? 'items-wrapper' : 'w-100'" class="flex-fill align-items-center">
    <!--<ul id="list-items" #hScroll ngxScrollMenu (scrolled)="listenToItemsScroll($event)" class="nav-fill align-items-center flex-nowrap overflow-auto">
      <li *ngFor="let item of items" class="px-2 align-items-center">-->
        <!--<a class="cat-nav" [ngClass]="text" (click)="onSelectCat(item)">{{ item?.name}}</a>
        <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
          <div class="collection-banner p-left text-start">
              <img src="assets/images/banner/6.jpg" alt="" class="img-fluid">
              <div class="absolute-contain">
                  <h3>casual collection</h3>
                  <h4>festive sale</h4>
              </div>
          </div>
      </a>-->
      <owl-carousel-o #hScroll ngxScrollMenu [options]="CategorySliderConfig">
        <ng-template carouselSlide *ngFor="let item of items">
      <div class="category-block" [ngClass]="{'clicked': item.id === clicked}">
        <a (click)="onSelectCat(item)">
            <!-- <div class="category-image">
                <img src="https://assets.eatintime.it/imgs/categories/{{item.id}}.png" alt="" class="w-auto">
                
            </div> -->
        </a>
        <div class="category-details">
            <a (click)="onSelectCat(item)">
                <h5>{{ item?.name}}</h5>
            </a>
        </div>
    </div>
  </ng-template>
</owl-carousel-o>
      <!--</li>
    </ul>-->
   
  </div>

  <!--<div *ngIf="!hideNav" class="control flex-fill align-items-center mousePointer"
  (click)="scrollRight()" (mousedown)="right()" (mouseup)="clear()" [ngClass]="{'display': rightArrow}">
    <div class="next ion-text-align-center rotate-right" [ngClass]="{'opacity': !rightArrow}">
      <img [src]="leftIcon" width="32" />
    </div>
  </div>-->
</nav>