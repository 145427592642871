import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { Restaurant } from '../classes/restaurant';
import { ApiService } from '../services/api.service';
import { ConfigApp } from '../services/config-app';
import { Edenred } from '../services/env.service';
import { DB } from '../services/env.service';

@Injectable({
  providedIn: 'root'
})
export class EdenredService {

  private authUrlEdenred = "https://sso.auth-sandbox.api.edenred.com/idsrv/connect/authorize?response_type=code&client_id=CLIENT_ID&scope=openid%20edg-xp-mealdelivery-api%20offline_access&redirect_uri=http://nowhere.edenred.net/oauth/callback&state=abc123&nonce=456azerty&acr_values=tenant:it-ben&ui_locales=it-IT";
  private tokenEdenred = "";
  private vouchers = null;
  private balances = null;

  constructor(private api: ApiService, private config: ConfigApp) {

    this.tokenEdenred = "";
    this.vouchers = null;
    this.balances = null;
    this.authUrlEdenred = Edenred.authUrl + "/connect/authorize?response_type=code&client_id=" + Edenred.authClientId + "&scope=openid%20edg-xp-mealdelivery-api%20offline_access&redirect_uri=" + Edenred.authRedirectUrl + "&state=abc123&nonce=456azerty&acr_values=tenant:it-ben&ui_locales=it-IT";

  }


  eLogin(order_id) {
    var data: any = {};
    data.order_id = order_id;

    return new Promise((resolve, reject) => {
      this.api.postj_T("order/elogin", data, "").subscribe(
        res => {
          if (
            res[this.config.JP_STATUS] == this.config.JPS_OK &&
            res[this.config.JP_RESULT]
          ) {
            this.authUrlEdenred = res[this.config.JP_RESULT];
            resolve(this.authUrlEdenred);
          } else if (
            res[this.config.JP_STATUS] == this.config.JPS_ERROR &&
            res[this.config.JP_MSG]
          ) {
            reject(res);
          } else {
            console.log(res);
            reject(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  eToken(code, url, order_id) {
    var data: any = {};
    data.authcode = code;
    data.urlauthcode = url;
    data.ID = order_id;

    return new Promise((resolve, reject) => {

      this.api.postj_T("order/etoken", data, "").subscribe(
        res => {
          if (
            res[this.config.JP_STATUS] == this.config.JPS_OK &&
            res[this.config.JP_RESULT]
          ) {
            this.tokenEdenred = res[this.config.JP_RESULT];
            this.setToken(this.tokenEdenred);
            resolve(this.tokenEdenred);
          } else if (
            res[this.config.JP_STATUS] == this.config.JPS_ERROR &&
            res[this.config.JP_MSG]
          ) {
            this.deleteToken();
            reject(res);
          } else {
            console.log(res);
            this.deleteToken();
            reject(res);
          }

        },
        err => {
          console.log(err);
          this.deleteToken();
          reject(err);
        }
      );
    });
  }

  eBalances(token, order_id, mid) {
    var data: any = {};
    data.token = token;
    data.ID = order_id;
    data.mid = mid;

    return new Promise((resolve, reject) => {
      this.api.postj_T("order/ebalances", data, "").subscribe(
        res => {
          if (
            res[this.config.JP_STATUS] == this.config.JPS_OK &&
            res[this.config.JP_RESULT]
          ) {
            this.balances = res[this.config.JP_RESULT];
            resolve(this.balances);
          } else if (
            res[this.config.JP_STATUS] == this.config.JPS_ERROR &&
            res[this.config.JP_MSG]
          ) {
            reject(res);
          } else {
            console.log(res);
            reject(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  eEstimate(token, order_id) {
    var data: any = {};
    data.token = token;
    data.ID = order_id;

    return new Promise((resolve, reject) => {
      this.api.postj_T("order/eestimate", data, "").subscribe(
        res => {
          if (
            res[this.config.JP_STATUS] == this.config.JPS_OK &&
            res[this.config.JP_RESULT]
          ) {
            this.vouchers = res[this.config.JP_RESULT];
            this.setVouchers(this.vouchers);
            resolve(this.vouchers);
          } else if (
            res[this.config.JP_STATUS] == this.config.JPS_ERROR &&
            res[this.config.JP_MSG]
          ) {
            this.deleteVouchers();
            reject(res);
          } else {
            console.log(res);
            this.deleteVouchers();
            reject(res);
          }
        },
        err => {
          console.log(err);
          this.deleteVouchers();
          reject(err);
        }
      );
    });
  }

  eAuthorize(token, order_id, vouchers) {
    var data: any = {};
    data.token = token;
    data.ID = order_id;
    data.vouchers = Array(vouchers);

    return new Promise((resolve, reject) => {

     // var res = Array();
      //res[this.config.JP_STATUS] = this.config.JPS_OK;

      //resolve(res);
      
      this.api.postj_T("order/eauthorize", data, "").subscribe(
        res => {
          if (
            res[this.config.JP_STATUS] == this.config.JPS_OK &&
            res[this.config.JP_RESULT]
          ) {
            //this.vouchers = res[this.config.JP_RESULT];
            resolve(res);
          } else if (
            res[this.config.JP_STATUS] == this.config.JPS_ERROR &&
            res[this.config.JP_MSG]
          ) {
            reject(res);
          } else {
            console.log(res);
            reject(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  deleteToken()
  {
    localStorage.removeItem(DB.ETOKEN);
  }

  getToken()
  {
    return JSON.parse(localStorage.getItem(DB.ETOKEN));
  }

  setToken(token)
  {
    localStorage.setItem(DB.ETOKEN, JSON.stringify(token));
  }

  deleteVouchers()
  {
    localStorage.removeItem(DB.EVOUCHERS);
  }

  getVouchers()
  {
    return JSON.parse(localStorage.getItem(DB.EVOUCHERS));
  }

  setVouchers(token)
  {
    localStorage.setItem(DB.EVOUCHERS, JSON.stringify(token));
  }
  

}
