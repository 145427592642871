import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Restaurant } from "../../../classes/restaurant";
import { CartService } from "../../../services/cart-service";
import { RestaurantService } from "../../../services/restaurant.service";
import { TypePayment, DB, RESPONSE } from '../../../services/env.service';
import { TypeOrder } from '../../../services/config-app';
import { EitCart } from '../../../../shared/classes/eitcart';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatetimeModalComponent } from '../../../../shared/components/modal/datetime-modal/datetime-modal.component';
import * as momentTz from  'moment-timezone';

@Component({
  selector: 'app-carttakeaway-box',
  templateUrl: './carttakeaway-box.component.html',
  styleUrls: ['./carttakeaway-box.component.scss']
})
export class CarttakeawayBoxComponent implements OnInit {

  @Input() restaurant: Restaurant;
  @Input() loader: boolean = false;
  @Input() editable: boolean = false;
  
  cartData: any = null;
  payType: any = TypePayment;
  curTime: any;
  sticky: boolean = true;
  public stick: boolean = false;
  public typeOrder: any = TypeOrder;

  constructor(private route: ActivatedRoute, private router: Router, private cartService: CartService, private modalService: NgbModal) { 
    this.curTime = new Date().getHours();
    this.cartData = new EitCart();
    this.cartService.cartObs.subscribe(x => {

      if(x)
      {
        this.cartData = x;
        
       // let enddate = new Date(this.cartData.order.bookingdate);
       let enddate =  momentTz.tz(this.cartData.order.bookingdate, "Europe/Rome").format('DD/MM')

       /*
       enddate.getFullYear().toString();
       */
      this.cartData.order.realbookdate = enddate;
       //this.cartData.order.realbookdate = enddate.getDate().toString() + '/' + (enddate.getMonth() + 1).toString();
        
        

        this.cartData.euro_delivery =0;
        this.cartData.euro_extradelivery =0;
        this.cartData.type_order = TypeOrder.REST_ASPORTO;
        //this.cartService.postCart(this.cartData);
        

      }
    });
  }

  ngOnInit(): void {
    console.log("cart takeaway box")
    this.curTime = new Date().getHours();
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

   // @HostListener Decorator
   @HostListener("window:scroll", [])
   onWindowScroll() {
     let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
     if (number >= 300 && window.innerWidth > 400) { 
       this.stick = true;
     } else {
       this.stick = false;
     }
   }

  

  addToCart(product: any) {
  //  this.productService.addToCart(product);
  }

  addToCartSingle(menuitem) 
  {
    this.cartService.addToCartSingle(menuitem.id, 1, "", 1);
  }

  removeToCartSingle(menuitem) 
  {
    this.cartService.removeToCartP(menuitem.id, 1, 1);
  }
  
  goToCheckout()
  {
    this.router.navigate(['/cart'], { queryParams: { } });
  }

  opendatetimemodal(){
    console.log("open date");
    var order: any;
    order = this.cartData.order;
    let modal = this.modalService.open(DatetimeModalComponent, {size: 'md', centered: true, ariaLabelledBy: 'Datetime-Modal', windowClass: 'datetime-modal'})
    modal.componentInstance.order = order
    modal.componentInstance.init()
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if(event.status == RESPONSE.JPS_OK){
        console.log("dismiss datetime response OK");
        this.cartData.order.order_date = event.orario;
        this.cartService.postOrario(this.cartData, event.orario, event.giornoconsegna);
      }else{
        console.log("dismiss datetime response ERROR");
      }
    })
  }
}
