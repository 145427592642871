<!--div *ngIf="restaurant">
  <h4>Info</h4>
</div-->
<div class="py-5" *ngIf="restaurant">
  <div class="row">
    <div class="col-lg-3">
      <img *ngIf="restaurant.media" [src]="restaurant.media.realpath" class="img-fluid" alt="">
    </div>
    <div class="col-lg-5">
      <h3 class="mb-4">{{restaurant.name}}</h3>
      <h4 class="mb-4">{{restaurant.descr}}</h4>
      <h4 class="mb-4" *ngIf="restInfo.location">{{restInfo.location.address}}, {{restInfo.location.num}}<br>{{restInfo.location.zipstr}} - {{restInfo.location.townstr}}</h4>
      <!-- <agm-map *ngIf="restInfo.location" [latitude]="restInfo.location.latitudine" [longitude]="restInfo.location.longitudine" [zoom]="18" [disableDefaultUI]="false" [draggable]="false" (mapReady)="onMapReady($event)">
        <agm-marker [latitude]="restInfo.location.latitudine" [longitude]="restInfo.location.longitudine" [iconUrl]="mapInfo.icon" [markerDraggable]="false">
        </agm-marker>
      </agm-map> -->
      <!-- MAPPA -->
    </div>
    <div class="col-lg-4">
      <h3 class="mb-4 text-center">Orari consegne a domicilio</h3>
      <table class="w-100 text-center mb-4">
        <thead>
          <th class="p-2 border-right">Giorno</th>
          <th class="p-2 border-right">Pranzo</th>
          <th class="p-2">Cena</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of restInfo.openingtimes">
            <td class="p-2 border-right">{{days[item.week_day]}}</td>
            <td class="p-2 border-right">
              <span *ngIf="item.closed_lunch">Chiuso</span>
              <span *ngIf="!item.closed_lunch">{{item.open_lunch}} - {{item.close_lunch}}</span>
            </td>
            <td class="p-2">
              <span *ngIf="item.closed_dinner">Chiuso</span>
              <span *ngIf="!item.closed_dinner">{{item.open_dinner}} - {{item.close_dinner}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <h3 class="mb-4 text-center">Orari servizio asporto</h3>
      <table class="w-100 text-center mb-4">
        <thead>
          <th class="p-2 border-right">Giorno</th>
          <th class="p-2 border-right">Pranzo</th>
          <th class="p-2">Cena</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of restInfo.openingtimes">
            <td class="p-2 border-right">{{days[item.week_day]}}</td>
            <td class="p-2 border-right">
              <span *ngIf="item.closed_lunch_away">Chiuso</span>
              <span *ngIf="!item.closed_lunch_away">{{item.open_lunch_away}} - {{item.close_lunch_away}}</span>
            </td>
            <td class="p-2">
              <span *ngIf="item.closed_dinner_away">Chiuso</span>
              <span *ngIf="!item.closed_dinner_away">{{item.open_dinner_away}} - {{item.close_dinner_away}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>