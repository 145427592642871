import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RESPONSE } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ET_BookStatus } from '../../../services/config-app';
import { ApiService } from '../../../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cancelbooking',
  templateUrl: './cancelbooking.component.html',
  styleUrls: ['./cancelbooking.component.scss']
})
export class CancelbookingComponent implements OnInit {
  
  @Input() booking: any = {}
  prenotazionenoneliminabile: boolean = false;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  BookStatus: any = ET_BookStatus;

  constructor(private modalService: NgbModal, private api: ApiService, private toastService: ToastrService) { }

  ngOnInit(): void {
  }

  @Input() init(){
    let arr = this.booking.oraprenotazione.split(" ");
    let arrDate = arr[0].split("-");
    let arrHour = arr[1].split(":");
    this.prenotazionenoneliminabile = new Date(arrDate[0], parseInt(arrDate[1]) - 1, arrDate[2], parseInt(arrHour[0]) - 2, arrHour[1], arrHour[2]) < new Date();
  }

  dismiss(status?: string){
    this.onDismiss.emit({status: (status ? status : RESPONSE.JPS_ERROR)})
    this.modalService.dismissAll()
  }

  cancelBooking(){
    let params = {ID: this.booking.id, AUX_FIELD_1: this.booking.reasoncancellation, STATUS: ET_BookStatus.CANCELLED_BY_USER};
    this.api.postj_T("user/settings/cancelbooking", params, "").subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {        	 
        this.toastService.success("Prenotazione cancellata!")
        this.dismiss(RESPONSE.JPS_OK);
      }
      else if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR && res[RESPONSE.JP_MSG]) {
        res[RESPONSE.JP_MSG].forEach((msg: any) => {
          this.toastService.error(msg)
        });
      }
      else {
        console.log(res);
      }
    }, (err) => {
      console.log(err);
    })
  }
}
