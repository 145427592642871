import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../classes/user';
import { AppInfo, DB, RESPONSE } from '../services/env.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EitUrl } from '../services/env.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private id_orderSubject: BehaviorSubject<any>;
  public id_order: Observable<any>;
  private id_bookSubject: BehaviorSubject<any>;
  public id_book: Observable<any>;
  private id_bookorderSubject: BehaviorSubject<any>;
  public id_bookorder: Observable<any>;
  
  httpData: {};

  constructor(private http: HttpClient) {
    let x = localStorage[DB.USER];
      this.currentUserSubject = new BehaviorSubject<User>(localStorage[DB.USER] ? JSON.parse(localStorage[DB.USER]) : null);
      this.currentUser = this.currentUserSubject.asObservable();

      this.id_orderSubject = new BehaviorSubject<any>(null);
      this.id_order = this.id_orderSubject.asObservable();
      this.id_bookSubject = new BehaviorSubject<any>(null);
      this.id_book = this.id_bookSubject.asObservable();
      this.id_bookorderSubject = new BehaviorSubject<any>(null);
      this.id_bookorder = this.id_orderSubject.asObservable();
  }
  
 
  public get currentUserValue(): User {
    var result = this.currentUserSubject.value;



    return result;
  }

  public get idOrder(): any {

    return this.id_orderSubject.value;
  }

  public get idBook(): any {

    return this.id_bookSubject.value;
  }

  public next(value: any) {
    localStorage.setItem(DB.USER, JSON.stringify(value));
    this.currentUserSubject.next(value);
  }

  public nextOrder(value) {
    localStorage.setItem(DB.ORDER_ID, JSON.stringify(value));
    this.id_orderSubject.next(value);
  }

  public nextBook(value) {
    console.log("Next book ID");
    localStorage.setItem(DB.BOOK_ID, JSON.stringify(value));
    this.id_bookSubject.next(value);
  }

  public nextOrderbook(value) {
    localStorage.setItem(DB.BOOKORDER_ID, JSON.stringify(value));
    this.id_bookorderSubject.next(value);
  }


  public login(credentials: any){
    if(credentials === "") credentials = {};
    credentials.idApp = AppInfo.APP_ID;
    return this.http.post<any>(EitUrl.apiUrl + '/user/security/login', credentials, {}).pipe(map(data => {
      // login successful if there's a jwt token in the response
      if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        
        this.next(data[RESPONSE.JP_RESULT]);
      }
      return data;
    }));
  }

  public loginFB(credentials: any){
    return this.http.post<any>(EitUrl.apiUrl + '/user/security/authfb', credentials, {}).pipe(map(data => {
      // login successful if there's a jwt token in the response
      if (data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && data[RESPONSE.JP_RESULT]) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        
        this.next(data[RESPONSE.JP_RESULT]);
      }
      return data;
    }));
  }

  logout() {
    // remove user from local storage to log user out
    this.next(null);
    localStorage.removeItem(DB.USER)
    this.id_orderSubject.next(null);
    localStorage.removeItem(DB.ORDER_ID)
    
  }
}
