import { Injectable, Pipe, PipeTransform } from '@angular/core';

export type SortOrder = 'asc' | 'desc';
const _clone = (d: any) => JSON.parse(JSON.stringify(d));

@Injectable()
@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(array: Array<any>, fields: any): any {
    if (!array || !array.length || !fields || !fields.length) return array;
    let arrSorted = _clone(array);
    arrSorted.sort((a: any, b: any) => {
     // console.log("A:" + a['name'] + ": "+ a['day_closed'] +" - "+ a['nowclosed'] +" - "+ a['preorder'] +" - "+ a['distanza'] +" - "+ a['rating'])
      //console.log("B:" + b['name'] + ": "+ b['day_closed'] +" - "+ b['nowclosed'] +" - "+ b['preorder'] +" - "+ b['distanza'] +" - "+ b['rating'])
      if (typeof fields === 'string') {
        if (a[fields] < b[fields]) return -1;
        else if (a[fields] > b[fields]) return 1;
        else return 0;
      }
      else if(fields.length > 0){
        for (let i = 0; i < fields.length; i++) {
          let sortType: SortOrder = 'asc';
          let sKey = "";
          let onlyIf: any = null;
          if (typeof fields[i] !== 'string') {
            sortType = fields[i].sortOrder;
            sKey = fields[i].sortKey;
            onlyIf = fields[i].onlyIf;
          }
          else sKey = fields[i];
          if(!onlyIf || a[onlyIf.name] !== b[onlyIf.name] || a[onlyIf.name] == onlyIf.val){
            if (typeof a[sKey] !== 'boolean') {
              if (a[sKey] < b[sKey]) return sortType === 'asc' ? -1 : 1;
              else if (a[sKey] > b[sKey]) return sortType === 'asc' ? 1 : -1;
            }
            else{
              let x = a[sKey] ? 1 : 0;
              let y = b[sKey] ? 1 : 0;
              if (x < y) return sortType === 'asc' ? -1 : 1;
              else if (x > y) return sortType === 'asc' ? 1 : -1;
            }
          }
        }
        return 0
      }
     /* if (typeof fields === 'object') {
        if (a[fields.sortKey] < b[fields.sortKey]) return fields.sortOrder === 'asc' ? -1 : 1;
        else if (a[fields.sortKey] > b[fields.sortKey]) return fields.sortOrder === 'asc' ? 1 : -1;
        else return 0;
      }*/
    });
    return arrSorted;
  }
}
