import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RESPONSE } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  @Input() title: string = ""
  @Input() msg: string = ""
  @Input() img: string = ""
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  public closeResult: any = {};

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    console.log("confirm modal")
  }

  confirm(){
    this.onDismiss.emit({status: RESPONSE.JPS_OK})
    this.modalService.dismissAll()
  }

  cancel(){
    this.onDismiss.emit({status: RESPONSE.JPS_ERROR})
    this.modalService.dismissAll()
  }
}
