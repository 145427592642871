import { Component, Input } from '@angular/core';
import { AppInfo } from '../../services/env.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent {
  @Input() item: any;
  @Input() thumbnail: boolean = false; // Default False 
  @Input() loader: boolean = false;
  

  appInfo: any = AppInfo

  public ImageSrc : string


  constructor(private router:Router) { 
    console.log("product box")
  }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    this.ImageSrc = product.mediaheader.realpath;
   /* product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })*/
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

    onGoToProduct(id) {
    this.router.navigate(['/product'], { queryParams: { id_product: id } });
  }
}
