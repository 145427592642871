<div class="modal-content">
  <div class="modal-body theme-tab">
    <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="text-center corporate-box" (click)="goToSite()">
      <div class="img-container d-inline-block"><img [src]="path"></div>
      <h2 class="text-center mt-3 mb-5">{{partner.nome_company}}</h2>
    </div>
    <ngb-tabset [justify]="'center'" [type]="'pills'" class="tabs tab-title">
      <ngb-tab>
        <ng-template ngbTabTitle>Ristoranti convenzionati</ng-template>
        <ng-template ngbTabContent>
          <form [formGroup]="searchFormR" (ngSubmit)="applyFilter(1)" style="margin: 0 -15px">
            <input type="text" class="form-control" formControlName="LIKE" name="LIKE">
          </form>
          <div class="row corporate-box mt-4">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let record of restaurants">
              <div class="img-container" (click)="goToRest(record, record.id)">
                <img [src]="record.mediaheader?.realpath">
                <h4 class="text-center m-0 d-flex align-items-center">{{record.name}}</h4>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab>
        <ng-template ngbTabTitle>Promozioni attive</ng-template>
        <ng-template ngbTabContent>
          <form [formGroup]="searchForm" (ngSubmit)="applyFilter(0)" style="margin: 0 -15px">
            <input type="text" class="form-control" formControlName="LIKE" name="LIKE">
          </form>
          <div class="blog-detail-page corporate-box cb-i mt-4" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" [scrollWindow]="false" (scrolled)="next()">
            <ul class="comment-section">
              <li *ngFor="let record of promos">
                <div class="media">
                  <div class="media-body row">
                    <div class="col-2 d-flex align-items-center">
                      <img *ngIf="record.promo.media_mid_id <= 0" src="assets/images/promo.png">
                      <img *ngIf="record.promo.media_mid_id > 0" [src]="record.promo.media_mid.realpath">
                    </div>
                    <div class="col-10">
                      <h4 class="font-weight-bold">{{record.promo.text_short}}</h4>
                      <h4>{{record.promo.text_long}}</h4>
                      <h4 *ngIf="record.promo.restaurant != 'Tutti'">Promozione valida per il ristorante <a (click)="goToRest(record, record.promo.restaurant_id)">{{record.promo.restaurant}}</a></h4>
                      <h4 *ngIf="record.promo.orariopromostart !== '00:00' || record.promo.orariopromoend !== '00:00'">
                        Valida dalle {{record.promo.orariopromostart}} alle {{record.promo.orariopromoend}}
                      </h4>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>