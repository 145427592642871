<!--footer section -->
<footer [ngClass]="class">
    <div class="dark-layout" *ngIf="mainFooter">
        <div class="overlay-footer"></div>
        <div class="container">
            <section class="section-b-space p-0 links-section">
                <div class="row">
                    <div class="col-lg-3 p-3 footer-col">
                        <img src="assets/images/logo-etika.png">
                        
                    </div>
                    <div class="col-lg-3 p-3 footer-col">
                        <ul class="menu">
                            <li *ngFor="let menuItem of menuItems">
                                <!-- Link -->
                                <a [routerLink]="menuItem.path" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link {{menuItem.class}}"
                                    *ngIf="menuItem.type === 'link'">
                                    {{ menuItem.title }}
                                </a>
                                <!-- External Link -->
                                <a href="{{ menuItem.path }}" class="nav-link {{menuItem.class}}"
                                    *ngIf="menuItem.type === 'extLink'">
                                    {{ menuItem.title }}
                                    <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}
                                    </div>
                                    <span class="sub-arrow" *ngIf="menuItem.children"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3 p-3 footer-col">
                        <ul class="menu legal-notice">
                            <li *ngFor="let menuItem of legalNoticeMenuItems">
                                <!-- Link -->
                                <a [routerLink]="menuItem.path" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link {{menuItem.class}}"
                                    *ngIf="menuItem.type === 'link'">
                                    {{ menuItem.title }}
                                </a>
                                <!-- External Link -->
                                <a href="{{ menuItem.path }}" class="nav-link {{menuItem.class}}"
                                    *ngIf="menuItem.type === 'extLink'">
                                    {{ menuItem.title }}
                                    <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}
                                    </div>
                                    <span class="sub-arrow" *ngIf="menuItem.children"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3 p-3 footer-col flex-column justify-content-center gap-3">
                        <a href="" class="applink"><img src="assets/images/scarica-su-ios.png"></a>
                        <a href="" class="applink"><img src="assets/images/scarica-su-android.png"></a>
                    </div>
                    <!-- <div class="col-lg-3"><img src="assets/images/new/Pizza-morso-tellia-torino.png" style="width: 397px !important;"></div> -->
                </div>
            </section>
        </div>
    </div>
    <div class="sub-footer darker-subfooter">
        <div class="container">
            <div class="sub-footer-wrapper">
                <p class="text-center p-0">
                    Etika {{year}} - Tutti i diritti riservati
                </p>
                <!-- <div class="social-network-wrapper">
                    <ul class="footer-social-cp">
                      <!- <li *ngIf="appData && appData.link_facebook && appData.link_facebook.length"><a [href]="appData.link_facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li *ngIf="appData && appData.link_instagram && appData.link_instagram.length"><a [href]="appData.link_instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a></li> ->
                      <li><a href="https://www.facebook.com/CyberPizzaItaly"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li><a href="https://www.instagram.com/cyberpizzaitaly/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                    </ul>
                  </div> -->
                <!--div class="social-network-wrapper">
                    <a class="social-icon social-icon-facebook" title="Facebook" href="https://www.facebook.com/TelliaTorino/" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a class="social-icon social-icon-instagram" title="Instagram" href="https://www.instagram.com/telliatorino/" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-instagram d-block"></i>
                    </a>
                    <a class="social-icon social-icon-custom" title="Google my Business" target="_blank" rel="noopener noreferrer"
                        href="https://www.google.com/maps/place/TELLIA%C2%AE/@45.0694622,7.6786191,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xc419e1134a613de2!8m2!3d45.0694622!4d7.6808078">
                        <img src="https://www.tellia.it/wp-content/uploads/2019/09/icona-g-business.png" style="width:auto;" alt="Google my Business">
                    </a>
                    <a class="social-icon social-icon-custom" title="Tripadvisor" target="_blank" rel="noopener noreferrer"
                        href="https://www.tripadvisor.it/Restaurant_Review-g187855-d18915885-Reviews-TELLIA-Turin_Province_of_Turin_Piedmont.html">
                        <img src="https://www.tellia.it/wp-content/uploads/2019/09/icona-tripadvisor.png" style="width:auto;" alt="Tripadvisor">
                    </a>
                    <a class="social-icon social-icon-youtube" title="YouTube" 
                        href="https://www.youtube.com/channel/UCV8K9psCV21WnKgQjXw0Pww" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-youtube"></i>
                    </a>
                </div-->
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->