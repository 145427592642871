import { Component } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';


@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent {
  public page = 0;
  products: Array<any> = [];
  id_cat: number = -1;
  blockSegment: boolean = false;
  categories: Array<any> = [];
  selectedCat: any;



  constructor(/* private router: Router, */ private api: ApiService) {

    //this.ngOnInit();
    this.products = [];
    this.categories = [];
    this.page = 0;
    this.id_cat = -1;
    this.selectedCat = 0
    this.loadCategory();

  }

  ngOnInit() {

    this.products = [];

    this.api.loadProduct(this.id_cat, this.page, null).then(res => {
      // this.infiniteScroll.disabled = false;
      this.products = this.products.concat(res);
    }, (err) => {
      console.log(err);
      this.products = [];
    });

  }

  loadCategory(){
    this.api.loadCategories().then((res: Array<any>) => {
      this.categories = res;
    }, (err) => {
      console.log(err);
      this.categories = [];
    });
  }


  loadData(event) {
    setTimeout(() => {
      console.log('Done');
      // event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      /*if (data.length == 1000) {
        event.target.disabled = true;
      }*/
    }, 500);

    this.page++;


    this.api.loadProduct(this.id_cat, this.page, null).then(res => {
      var count = this.products.length;
      this.products = this.products.concat(res);
      event.target.complete();
      //Rerender Virtual Scroll List After Adding New Data
      //this.virtualScroll.checkEnd();
      if (count == this.products.length) {
        event.target.disabled = true;
      }
    }, (err) => {
      console.log(err);
      event.target.disabled = true;
      event.target.complete();
    });


  }

  selectCat(id): void {
    console.log(id);
    this.id_cat = id;
    this.page = 0;
    this.products = [];
    this.api.loadProduct(this.id_cat, this.page, null).then(res => {
      // this.infiniteScroll.disabled = false;
      this.products = this.products.concat(res);
    }, (err) => {
      console.log(err);
      this.products = [];
    });

  }

  scrolling(event: any) {
    setTimeout(()=> {
      this.blockSegment = event.detail.currentY > 100
    })
  }

  lowercaseText(text: string) {
    return text.toLowerCase();
  }

  onNotify(message: any): void {
    console.log("sel:"+message)
    this.selectCat(message)
  }

  // onGoToProduct(id) {
  //   this.router.navigate(['/product'], { queryParams: { id_product: id } });
  // }
}
