import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { Restaurant } from "../../../classes/restaurant";
import { ProductService } from "../../../services/product.service";
import { RestaurantService } from "../../../services/restaurant.service";
import { TypePayment, DB, AppInfo } from '../../../services/env.service';

@Component({
  selector: 'app-resttable-box',
  templateUrl: './resttable-box.component.html',
  styleUrls: ['./resttable-box.component.scss']
})
export class ResttableBoxComponent implements OnInit {

  @Input() restaurant1: Restaurant;
  @Input() thumbnail1: boolean = false; // Default False 
  @Input() onHowerChangeImage1: boolean = false; // Default False  
  @Input() loader1: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  appInfo: any = AppInfo
  

  public ImageSrc : string
  payType: any = TypePayment;
  curTime: any;

  constructor(private productService: ProductService, private restService: RestaurantService) { 
    this.curTime = new Date().getHours();
  }

  ngOnInit(): void {
    this.curTime = new Date().getHours();
    if(this.loader1) {
      setTimeout(() => { this.loader1 = false; }, 2000); // Skeleton Loader
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    this.ImageSrc = product.mediaheader.realpath;
   /* product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })*/
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  goToRest(restaurant)
  {
    console.log("rest table box -> GoToRest ");
    localStorage.setItem(DB.ACTIVITY_BASE, JSON.stringify(restaurant));
    this.restService.loadList(restaurant.menu.id);
  }

}
