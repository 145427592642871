import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Restaurant } from '../classes/restaurant';
import { ApiService } from '../services/api.service';
import { DB, RESPONSE, RESULT } from './env.service';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  private listSubject: BehaviorSubject<any>;
  public list: Observable<any>;

  private restSubject: BehaviorSubject<any>;
  public restaurant: Observable<any>;


  constructor(private http: HttpClient, private api: ApiService,
    private toastrService: ToastrService) {

    this.listSubject = new BehaviorSubject<any>(null);
    this.list = this.listSubject.asObservable();

    this.restSubject = new BehaviorSubject<any>(null);
    this.restaurant = this.restSubject.asObservable();
  }


  public nextRestaurant(value) {
    this.restSubject.next(value);
  }

  public get rest(): any {
    return this.restSubject.value;
  }



  loadList(id_list) {
    // this.loadingService.show();
    var data: any = {};
    data.id = id_list;

    var current = this.listSubject.value;
    if (!current || (current && current.id != id_list)) {

      this.api.postj_T("restaurant/menu", data, "").subscribe(data => {
        if (
          data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          data[RESPONSE.JP_RESULT]
        ) {

          this.listSubject.next(data[RESPONSE.JP_RESULT]);

        }
        // this.loadingService.hide();
      }, (err) => {
        console.log(err);
        //this.loadingService.hide();
      })
    }
  }

  // Get Products By Slug
  public getRestaurantBySlug(slug: string, silent): Observable<Restaurant> {

    if (!silent) {
      //  this.loadingService.show();
    }
    //var loc = this.locSubject.value;
    //loc.iswww = 1;
    return this.api.postj_T("restaurant/slug", { 'slug': slug }, "");
  }

  public getRestaurantBySlugta(slug: string, silent): Observable<Restaurant> {

    if (!silent) {
      //  this.loadingService.show();
    }
    //var loc = this.locSubject.value;
    //loc.iswww = 1;
    return this.api.postj_T("restaurant/slugtakeaway", { 'slug': slug }, "");
  }

  public getRestaurantById(id: string, silent): Observable<Restaurant> {

    if (!silent) {
      //  this.loadingService.show();
    }
    //var loc = this.locSubject.value;
    //loc.iswww = 1;
    return this.api.postj_T("restaurant/record", { 'id': id }, "");
  }



}
