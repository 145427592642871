import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RESPONSE } from 'src/app/shared/services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LocationService } from 'src/app/shared/services/location.service';

@Component({
  selector: 'app-ncivico',
  templateUrl: './ncivico.component.html',
  styleUrls: ['./ncivico.component.scss']
})
export class NcivicoComponent implements OnInit {

  form: FormGroup;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  
  constructor(private modalService: NgbModal, /*private api: ApiService, */private locService: LocationService, private toastService: ToastrService) {
    this.form = new FormGroup({
      num: new FormControl("", Validators.required)
    })
   }

  ngOnInit(): void {
  }

  inputHasError(input: string, error: string){
    return this.form.get(input).invalid && (this.form.get(input).dirty || this.form.get(input).touched) && this.form.get(input).hasError(error)
  }

  send(){
    console.log(this.form.value)
    if(this.form.valid){
      this.dismiss({status: RESPONSE.JPS_OK, result: this.form.get("num").value})
    }
  }

  dismiss(result?: any){
    this.onDismiss.emit(result ? result : {status : RESPONSE.JPS_ERROR})
    this.modalService.dismissAll()
  }

}
