import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { RESPONSE, DB, EitUrl } from '../services/env.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private appSubject: BehaviorSubject<any>;
  public appObs: Observable<any>;

  constructor(private api: ApiService) {

    this.appSubject = new BehaviorSubject<any>(null);
    this.appObs = this.appSubject.asObservable();
  }

  public get appData(): any {
    return this.appSubject.value;
  }

  public next(value: any) {
    localStorage.setItem(DB.APP_INFO, JSON.stringify(value));
    this.appSubject.next(value);
  }

  getAppInfo() {
    this.api.getInfoApp().subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.next(res[RESPONSE.JP_RESULT]);
      }
    });
  }

  countServices (appData: any): number{
    let c = 0;
    if(appData){
      if(appData.count_restaurants > 0) c++;
      if(appData.count_asporto > 0) c++;
      if(appData.count_tavoli > 0) c++;
    }
    return c;
  }

  getLogo(appData: any) : string{
    return appData && appData.mail_logo.id ? EitUrl.ASSETS_URL + appData.assets_folder +"/"+ appData.mail_logo.filename : "";
  }

  getLogoSm(appData: any) : string{
    return appData && appData.mail_logo.id ? EitUrl.ASSETS_URL + appData.assets_folder +"/"+ appData.mail_logo.filename_small : "";
  }

  //type = 0 => domicilio non attivo; type = 1 => domicilio con un solo ristorante disponibile; type = 2 => domicilio con scelta ristorante
  typeDomicilio(appData: any) : number{
    return appData ? (appData.count_restaurants > 1 ? 2 : appData.count_restaurants) : 0;
  }

  //type = 0 => asporto non attivo; type = 1 => asporto con un solo ristorante disponibile; type = 2 => asporto con scelta ristorante
  typeAsporto(appData: any) : number{
    return appData ? (appData.count_asporto > 1 ? 2 : appData.count_asporto) : 0;
  }

  //type = 0 => prenotazione tavoli non attivo; type = 1 => prenotazione tavoli con un solo ristorante disponibile; type = 2 => prenotazione tavoli con scelta ristorante
  typeTavoli(appData: any) : number{
    return appData ? (appData.count_tavoli > 1 ? 2 : appData.count_tavoli) : 0;
  }

}
