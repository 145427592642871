<div class="icon-nav">
  <ul class="d-flex align-items-center flex-column flex-sm-row gap-2">
    <!--<li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div>
        <img src="assets/images/icon/search.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
      </div>
    </li>
    <li class="onhover-div mobile-setting">
      <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
        <i class="ti-settings"></i></div>
      <div class="show-div setting">
        <h6>language</h6>
        <ul>
          <li *ngFor="let language of languages">
            <a href="javascript:void(0)" (click)="changeLanguage(language.code)">{{ language.name }}</a>
          </li>
        </ul>
        <h6>currency</h6>
        <ul class="list-inline">
          <li *ngFor="let currency of currencies">
            <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
          </li>
        </ul>
      </div>
    </li>-->
    <li class="onhover-div mobile-cart d-inline-block p-0" *ngIf="user">
      <div class="level-image m-1 m-lg-2">
        <!--TODO: recuperare livello account-->
        <p class="text-center text-light mb-0 pt-2">{{level}}</p>
        <p class="text-center text-light">Livello</p>
      </div>
    </li>
    <li class="onhover-div mobile-cart d-inline-block p-0">
      <div>
        <img src="assets/images/icona-account.png" class="img-fluid account-image" alt="">
        <!--i class="ti-settings"></i-->
      </div>
      <div class="show-div setting">
        <h6>Account</h6>
        <ul>
          <li *ngFor="let menuItem of menuItems">
            <a [routerLink]="menuItem.path" [queryParams]="menuItem.query">{{ menuItem.title }}</a>
          </li>
        </ul>
      </div>
    <!-- <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/cart']">
          <img src="assets/images/carrello.png" class="img-fluid" alt="" style="height: 36px;">
          <span class="cart_qty_cls" *ngIf="cartData" >{{ cartData.count }}</span>
          <!--<i class="ti-shopping-cart"></i>->
        </a>
      </div>
      <ul class="show-div shopping-cart" *ngIf='cartData &&!cartData.count'>
        <h5>Il tuo carrello &egrave; vuoto.</h5>
      </ul> -->
      <!--<ul class="show-div shopping-cart" *ngIf='products.length'>
        <li *ngFor="let product of products">
          <div class="media">
            <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
              <img class="mr-3" [src]="product?.images[0].src" [alt]="product?.images[0].alt">
            </a>
            <div class="media-body">
              <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                <h4>{{ product?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x 
                  {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
            <a [routerLink]="['/shop/checkout']" class="checkout">checkout</a>
          </div>
        </li>
      </ul>-->
    </li>
  </ul>
</div>