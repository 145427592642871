import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { EitPlace } from 'src/app/shared/classes/eitplace';
import { LocationService } from 'src/app/shared/services/location.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
// import { AgmMap } from '@agm/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RESPONSE } from '../../..//services/env.service';
import { CartService } from '../../../services/cart-service';
//import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EitCart } from '../../../classes/eitcart';

declare const google: any;

@Component({
  selector: 'app-location-change',
  templateUrl: './location-change.component.html',
  styleUrls: ['./location-change.component.scss']
})
export class LocationChangeComponent implements OnInit, OnDestroy {

  query: string = "";
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  // @ViewChild(AgmMap) agmMap: AgmMap;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  places: any = [];
  onSelected: boolean = false;
  location: any;
  geocoder: any;
  options: any;
  loading: boolean;
  mapInfo: any = { icon: { url: "assets/images/pin-mappa.png", lat: 51.673858, lng: 7.815982, scaledSize: new google.maps.Size(61, 60) } }
  cartSub: Subscription;
  public cartData: any = {};

  constructor(private locService: LocationService, private ref: ChangeDetectorRef, private modalService: NgbModal, private cartService: CartService) {
    this.options = {
      componentRestrictions: { country: 'IT' },
      types: ['geocode']  // 'establishment' / 'address' / 'geocode'
    }
    this.cartData = new EitCart();
    this.cartSub = new Subscription;
    this.location = new EitPlace();
    this.location.initData();
    this.locService.location.subscribe(x => {
      if (x) {
        this.location = x;
        if (x.description) {
          this.query = this.location.description;
        }
        if (x.latitude && x.longitude) {
          this.mapInfo.lat = this.location.latitude
          this.mapInfo.lng = this.location.longitude

          setTimeout(() => {
            this.loading = false;
            this.ref.detectChanges();
            // this.agmMap.triggerResize(true);
          }, 500)
        }
      }
    });

  }

  ngOnInit(): void {
    this.loading = true;
    this.geocoder = new google.maps.Geocoder;
    this.cartSub.add(this.cartService.cartObs.subscribe(x => {

      if (x) {
        this.cartData = x;
      }

    }));
  }

  ngOnDestroy() {
    this.cartSub.unsubscribe();
  }

  dismiss(status?: string) {
    this.onDismiss.emit({ status: (status ? status : RESPONSE.JPS_ERROR) })
    this.modalService.dismissAll()
  }

  public handleAddressChange(address: Address) {
    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }
    console.log(address)
    this.locService.selectPlace(this.geocoder, this.location, address).then(res => {
      this.location = res;
      this.query = res.description;
      
      /* this.locService.nextLocation(this.location);
       if(this.locService.isDeliveryOk(null)) {
         this.locService.loadDelivery_R(true);
       }*/
      
    });
  }

  onMapReady(map: any) {
    console.log("On map ready");
    map.setOptions({ zoomControl: false, mapTypeControl: false, streetViewControl: false, fullscreenControl: false });
  }

  save() {
    /*
    this.cartService.checkAddress(this.location);.subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
        this.locService.nextLocation(this.location);
        this.dismiss(RESPONSE.JPS_OK);
      }
    })*/
    this.locService.nextLocation(this.location);
    this.cartService.postLocation(this.location, this.cartData );
    this.dismiss(RESPONSE.JPS_OK);
  }

}
