<div class="modal-content">
  <div class="modal-body">
    <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="text-center text-uppercase">Informazioni sugli allergeni</h3>
    <div class="container pt-3 px-5" *ngIf="link_allergens != null && link_allergens.length > 0">
      <p>Per maggiori informazioni sugli allergeni <a class="link-info" [href]="link_allergens" target="_blank">clicca qui</a></p>
    </div>
    <div class="container py-3 px-5">
      <div class="row">
        <div class="col-lg-6" *ngFor="let allergen of allergens; index as i">
          <p class="mb-3"><strong>{{i + 1}}</strong>&nbsp; {{allergen.name}}</p>
        </div>
      </div>
    </div>
  </div>
</div>