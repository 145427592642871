<owl-carousel-o [options]="configSlider" class="home-slider">
    <ng-container *ngFor="let slider of items">
        <ng-template carouselSlide>
            <div class="home">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="slider-contain d-flex flex-column">
                                <div class="screen" (click)="onGoToNft(slider.id)">
                                    <img *ngIf="slider.media" src="{{slider.media.realpath}}">
                                    <!-- <div class="nft-name"> -->
                                        <h2 class="text-size-md text-color-accent  ion-text-wrap">
                                            {{slider.name}}</h2>
                                    <!-- </div> -->
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>